@import "https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;0,600;0,700;0,900;1,400;1,500;1,600;1,700;1,900&amp;display=swap";

.popular-services .listing-tabs-group,
.search-box-banner ul,
.css-equal-content,
.css-equal-heights {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap
}

.css-equal-content {
    color: red;
    float: left
}

.product-detail-head .search-btn .search-button:hover,
.product-detail-head .search-btn .search-button,
.review-sec .search-btn .search-button:hover,
.review-sec .search-btn .search-button,
.faq-section .faq-title>a:not(.collapsed):after,
.faq-section .faq-title>a:after,
.listing-item:hover .fav-icon i,
.listing-item .listing-img .fav-item,
.popular-services .listing-tabs-group ul li a img,
.popular-services .listing-tabs-group ul li a span,
.popular-services .listing-tabs-group ul li a:hover img,
.popular-services .listing-tabs-group ul li a:hover,
.popular-services .listing-tabs-group ul li a.active img,
.popular-services .listing-tabs-group ul li a,
.search-box-banner .search-btn .search-button:hover,
.search-box-banner .search-btn .search-button,
.header .header-navbar-rht .nav-item .header-reg:hover,
.header .header-navbar-rht .nav-item .header-reg,
.header .header-navbar-rht .nav-item .header-login:hover,
.header .header-navbar-rht .nav-item .header-login,
.header.fixed,
.header,
.contact-btn:hover,
.contact-btn {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.btn-file,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header .chat-options>a,
.avatar .avatar-title,
.listview-car .blog-img .fav-icon,
.listview-car .card .card-body .listing-details-group ul li span img,
.listview-car .card .card-body .blog-list-head .blog-list-rate h6,
.product-detail-head .details-btn a,
.review-sec .reviewbox-list-rating p,
.contact-section .contact-info-area .single-contact-info i,
.serve-form .input-block .button-notific,
.pagination,
.login-wrapper .loginbox .login-auth,
.login-wrapper .loginbox .login-left,
.popular-slider-group .owl-carousel .owl-next i,
.popular-slider-group .owl-carousel .owl-prev i,
.listing-item .listing-content .listing-button .btn-order,
.listing-item .listing-img .fav-item .fav-icon,
.listing-item .listing-img .fav-item .featured-text,
.popular-services .owl-carousel .owl-next i,
.popular-services .owl-carousel .owl-prev i,
.popular-services .listing-tabs-group ul li a,
.popular-services .listing-tabs-group {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.btn-file,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-header .chat-options>a,
.chat-window .chat-cont-right .chat-header .notify-block,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.avatar .avatar-title,
.table tbody td a,
.header .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-navbar-rht .has-arrow .logged-item .nav-link {
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center
}

.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body,
.chat-window .chat-cont-left .chat-header,
.listview-car .card .card-body .blog-list-head,
.product-detail-head .detail-page-head,
.blog-section .blog-content .meta-item {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.call-box .call-wrapper .call-items,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header .notify-block .media-img-wrap,
.chat-window .chat-cont-right .chat-header,
.chat-window,
.widget-box .widget-header .widget-icon span,
.grid-listview ul li a,
.car-listing .pagination .previtem a,
.car-listing .pagination .nextlink a,
.list-btn .btn-filters,
.modal .payment-success .check,
.payment-method ul li a,
.modal .available-for-ride,
.car-details-slider.owl-carousel .owl-nav .owl-prev,
.car-details-slider.owl-carousel .owl-nav .owl-next,
.listing-feature ul li span,
.specification-card .feature-img,
.share-postsection .sharelink .share-img,
.plan-selected,
.pagination .pagination .previtem a,
.pagination .pagination .nextlink a,
.why-choose .card .card-body .choose-img,
.listing-item .listing-content .listing-details-group ul,
.table .dropdown-item,
.table tbody td a.btn-action-icon,
.fi-icon,
.hi-icon,
.footer-social-links .nav,
.footer .footer-contact .footer-contact-info .footer-address span,
.header .header-navbar-rht .noti-wrapper .bell-icon,
.header .header-navbar-rht .noti-wrapper .nav-link,
.align-center {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center
}

.booking-list li {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.btn-file,
.chat-window .chat-cont-right .chat-header .chat-options>a,
.chat-window .chat-cont-left .chat-header .chat-compose,
.book-card .card-header h4 span,
.order-confirmation .place-order-btn a,
.listing-item .listing-content .listing-features .listing-title a,
.listing-item .listing-content .listing-features .author-img img,
.services .services-group .services-icon .icon-img,
.services .services-group .services-icon,
.header .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-navbar-rht .has-arrow .logged-item .nav-link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.chat-attachment-list,
.chat-msg-attachments,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body,
.chat-window .chat-cont-right .chat-header .chat-options,
.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.avatar .avatar-title,
.booking-lists ul li,
.status-lists li .status-info,
.status-lists li,
.pricing-section .price-card .price-details ul .price-uncheck,
.pricing-section .price-card .price-details ul .price-check,
.table tbody td a,
.footer-social-widget .nav-social,
.footer .footer-contact .footer-contact-info .update-form .btn .feather-send,
.footer .footer-contact .footer-contact-info .footer-address,
.header .header-navbar-rht .has-arrow .user-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.payment-method ul,
.review-sec .review-card .review-header-group,
.review-sec .review-header,
.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .blog-list-date,
.serve-form .input-block,
.error-page,
.content-page-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.listview-car .card .card-body .listing-details-group ul,
.order-confirmation .order-confirm-box .confirmation-title,
.order-confirmation .section-title,
.modal .grand-total,
.modal .booking-info.service-tax ul li,
.listing-item .listing-content .listing-location-details,
.listing-item .listing-img .fav-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center
}

.listview-car .blog-img .fav-icon,
.table-search,
.listview-car .card .card-body .listing-details-group ul li span img,
.listview-car .card .card-body .listing-details-group ul li,
.listview-car .card .card-body .blog-list-head .blog-list-rate h6,
.listview-car .card .card-body .blog-list-head .blog-list-title .title-bottom h6,
.sorting-div .product-filter-group .grid-listview ul,
.sorting-div .product-filter-group .sortbyset,
.sorting-div .product-filter-group,
.list-btn .filter-list li ul li,
.list-btn .filter-list .short-filter,
.list-btn .filter-list,
.order-confirmation .order-confirm-box .order-car,
.payment-method .payment-checkbox label,
.booking-details .booking-form .payment-btn,
.modal .modal-footer a,
.modal .booking-info.pay-amount label,
.modal .booking-info.seat-select label,
.review-list-rating li,
.review-list-rating,
.listing-feature ul li,
.product-detail-head .details-btn a,
.product-detail-head .details-btn,
.product-detail-head .detail-page-head .camaro-location .camaro-locations-inner,
.product-detail-head .detail-page-head .camaro-location,
.product-detail-head .detail-page-head .list-rating,
.product-detail-head .detail-page-head,
.testimonials-section .review-box .review-details .list-rating .list-rating-star,
.testimonials-section .review-box,
.review-sec .sidebar-form,
.review-sec .review-card .review-header-group .review-widget-header,
.review-sec .review-card .review-header-group,
.review-sec .reviewbox-list-rating p,
.blogdetails-pagination ul,
.tag-list .tags,
.tag-list,
.blogdetail-content .card-header,
.bloglistleft-widget .post-author,
.bloggrid-sidebar .post-author,
.blogbanner-content .post-author,
.blogbanner-content ul,
.blog-section .rightsidebar .card h4,
.blog-section .blog-content .blog-list-date .meta-item-list li .post-author,
.blog-section .blog-content .meta-item li .post-author,
.blog-section .blog-content .meta-item,
.contact-section .contact-info-area .single-contact-info i,
.serve-form .input-block .button-notific,
.privacy-section .terms-policy ul li,
.pagination,
.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group,
.login-wrapper .loginbox .login-auth,
.user-app-group .userapp-heading .download-btn,
.popular-slider-group .owl-carousel .owl-next i,
.popular-slider-group .owl-carousel .owl-prev i,
.listing-item .listing-content .listing-button .btn-order,
.listing-item .listing-content .listing-location-details .listing-price h6,
.listing-item .listing-content .listing-details-group ul li,
.listing-item .listing-img .fav-item .fav-icon,
.listing-item .listing-img .fav-item .featured-text,
.popular-services .owl-carousel .owl-next i,
.popular-services .owl-carousel .owl-prev i,
.popular-services .listing-tabs-group ul li a,
.search-box-banner .input-block-wrapp,
.footer .footer-contact .footer-contact-info .footer-address .addr-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center
}

.sorting-div .product-filter-group {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end
}

.invoice-section .invoice-item-bill ul li:first-child,
.table .dropdown-item,
.footer-social-widget .nav-social {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start
}

.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body,
.chat-window .chat-cont-left .chat-header,
.listview-car .card .card-body .blog-list-head,
.product-detail-head .detail-page-head,
.blog-section .blog-content .meta-item {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.chat-window .chat-msg-info {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body {
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body {
    -ms-flex-direction: column;
    flex-direction: column
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0
}

* {
    box-sizing: border-box
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:before,
blockquote:after {
    content: "";
    content: none
}

q:before,
q:after {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

a {
    text-decoration: none;
    transition: ease all .5s;
    -webkit-transition: ease all .5s;
    -ms-transition: ease all .5s
}

h1 {
    font-weight: 900;
    font-size: 62px;
    color: #201f1d
}

@media(max-width:1199.98px) {
    h1 {
        font-size: 36px
    }
}

h2 {
    font-weight: 900;
    font-size: 36px;
    color: #111
}

@media(max-width:991.98px) {
    h2 {
        font-size: 26px
    }
}

h3 {
    font-weight: 700;
    font-size: 24px;
    color: #111
}

@media(max-width:991.98px) {
    h3 {
        font-size: 18px
    }
}

h4 {
    font-size: 28px;
    color: #2f2f2f
}

@media(max-width:991.98px) {
    h4 {
        font-size: 20px
    }
}

h5 {
    font-size: 18px
}

@media(max-width:991.98px) {
    h5 {
        font-size: 16px
    }
}

h6 {
    font-weight: 500;
    font-size: 18px;
    color: #111
}

@media(max-width:991.98px) {
    h6 {
        font-size: 16px
    }
}

body {
    font-family: fira sans, sans-serif;
    font-size: 15px;
    color: #787878;
    background: #fcfbfb;
    overflow-x: hidden
}

@media(max-width:991.98px) {
    body {
        font-size: 15px
    }
}

a {
    cursor: pointer;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

a:hover {
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

a:focus {
    outline: 0
}

p:last-child {
    margin-bottom: 0
}

strong {
    font-weight: 700
}

.form-control:focus {
    border-color: #bbb;
    box-shadow: none;
    outline: 0
}

.popular-services .listing-tabs-group,
.search-box-banner ul,
.css-equal-heights,
.css-equal-content {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap
}

.css-equal-content {
    color: red;
    float: left
}

.product-detail-head .search-btn .search-button:hover,
.product-detail-head .search-btn .search-button,
.review-sec .search-btn .search-button:hover,
.review-sec .search-btn .search-button,
.faq-section .faq-title>a:not(.collapsed):after,
.faq-section .faq-title>a:after,
.listing-item:hover .fav-icon i,
.listing-item .listing-img .fav-item,
.popular-services .listing-tabs-group ul li a img,
.popular-services .listing-tabs-group ul li a span,
.popular-services .listing-tabs-group ul li a:hover img,
.popular-services .listing-tabs-group ul li a:hover,
.popular-services .listing-tabs-group ul li a.active img,
.popular-services .listing-tabs-group ul li a,
.search-box-banner .search-btn .search-button:hover,
.search-box-banner .search-btn .search-button,
.header .header-navbar-rht .nav-item .header-reg:hover,
.header .header-navbar-rht .nav-item .header-reg,
.header .header-navbar-rht .nav-item .header-login:hover,
.header .header-navbar-rht .nav-item .header-login,
.header.fixed,
.header,
.contact-btn:hover,
.contact-btn {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.btn-file,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header .chat-options>a,
.avatar .avatar-title,
.listview-car .blog-img .fav-icon,
.listview-car .card .card-body .listing-details-group ul li span img,
.listview-car .card .card-body .blog-list-head .blog-list-rate h6,
.product-detail-head .details-btn a,
.review-sec .reviewbox-list-rating p,
.contact-section .contact-info-area .single-contact-info i,
.serve-form .input-block .button-notific,
.pagination,
.login-wrapper .loginbox .login-auth,
.login-wrapper .loginbox .login-left,
.popular-slider-group .owl-carousel .owl-next i,
.popular-slider-group .owl-carousel .owl-prev i,
.listing-item .listing-content .listing-button .btn-order,
.listing-item .listing-img .fav-item .fav-icon,
.listing-item .listing-img .fav-item .featured-text,
.popular-services .owl-carousel .owl-next i,
.popular-services .owl-carousel .owl-prev i,
.popular-services .listing-tabs-group ul li a,
.popular-services .listing-tabs-group {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.btn-file,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-header .chat-options>a,
.chat-window .chat-cont-right .chat-header .notify-block,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.avatar .avatar-title,
.table tbody td a,
.header .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-navbar-rht .has-arrow .logged-item .nav-link {
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center
}

.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body,
.chat-window .chat-cont-left .chat-header,
.listview-car .card .card-body .blog-list-head,
.product-detail-head .detail-page-head,
.blog-section .blog-content .meta-item {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.call-box .call-wrapper .call-items,
.chat-attachment-item,
.chat-window .chat-cont-right .chat-header .notify-block .media-img-wrap,
.chat-window .chat-cont-right .chat-header,
.chat-window,
.widget-box .widget-header .widget-icon span,
.grid-listview ul li a,
.car-listing .pagination .previtem a,
.car-listing .pagination .nextlink a,
.list-btn .btn-filters,
.modal .payment-success .check,
.payment-method ul li a,
.modal .available-for-ride,
.car-details-slider.owl-carousel .owl-nav .owl-prev,
.car-details-slider.owl-carousel .owl-nav .owl-next,
.listing-feature ul li span,
.specification-card .feature-img,
.share-postsection .sharelink .share-img,
.plan-selected,
.pagination .pagination .previtem a,
.pagination .pagination .nextlink a,
.why-choose .card .card-body .choose-img,
.listing-item .listing-content .listing-details-group ul,
.table .dropdown-item,
.table tbody td a.btn-action-icon,
.fi-icon,
.hi-icon,
.footer-social-links .nav,
.footer .footer-contact .footer-contact-info .footer-address span,
.header .header-navbar-rht .noti-wrapper .bell-icon,
.header .header-navbar-rht .noti-wrapper .nav-link,
.align-center {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center
}

.booking-list li {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.btn-file,
.chat-window .chat-cont-right .chat-header .chat-options>a,
.chat-window .chat-cont-left .chat-header .chat-compose,
.book-card .card-header h4 span,
.order-confirmation .place-order-btn a,
.listing-item .listing-content .listing-features .listing-title a,
.listing-item .listing-content .listing-features .author-img img,
.services .services-group .services-icon .icon-img,
.services .services-group .services-icon,
.header .header-navbar-rht .has-arrow.logged-item .nav-link,
.header .header-navbar-rht .has-arrow .logged-item .nav-link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

.chat-attachment-list,
.chat-msg-attachments,
.chat-window .chat-msg-info,
.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body,
.chat-window .chat-cont-right .chat-header .chat-options,
.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body,
.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend,
.chat-window .chat-cont-left .chat-header,
.avatar .avatar-title,
.booking-lists ul li,
.status-lists li .status-info,
.status-lists li,
.pricing-section .price-card .price-details ul .price-uncheck,
.pricing-section .price-card .price-details ul .price-check,
.table tbody td a,
.footer-social-widget .nav-social,
.footer .footer-contact .footer-contact-info .update-form .btn .feather-send,
.footer .footer-contact .footer-contact-info .footer-address,
.header .header-navbar-rht .has-arrow .user-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.payment-method ul,
.review-sec .review-card .review-header-group,
.review-sec .review-header,
.blog-section .blog-content .blog-list-date .meta-item-list,
.blog-section .blog-content .blog-list-date,
.serve-form .input-block,
.error-page,
.content-page-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.listview-car .card .card-body .listing-details-group ul,
.order-confirmation .order-confirm-box .confirmation-title,
.order-confirmation .section-title,
.modal .grand-total,
.modal .booking-info.service-tax ul li,
.listing-item .listing-content .listing-location-details,
.listing-item .listing-img .fav-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center
}

.listview-car .blog-img .fav-icon,
.table-search,
.listview-car .card .card-body .listing-details-group ul li span img,
.listview-car .card .card-body .listing-details-group ul li,
.listview-car .card .card-body .blog-list-head .blog-list-rate h6,
.listview-car .card .card-body .blog-list-head .blog-list-title .title-bottom h6,
.sorting-div .product-filter-group .grid-listview ul,
.sorting-div .product-filter-group .sortbyset,
.sorting-div .product-filter-group,
.list-btn .filter-list li ul li,
.list-btn .filter-list .short-filter,
.list-btn .filter-list,
.order-confirmation .order-confirm-box .order-car,
.payment-method .payment-checkbox label,
.booking-details .booking-form .payment-btn,
.modal .modal-footer a,
.modal .booking-info.pay-amount label,
.modal .booking-info.seat-select label,
.review-list-rating li,
.review-list-rating,
.listing-feature ul li,
.product-detail-head .details-btn a,
.product-detail-head .details-btn,
.product-detail-head .detail-page-head .camaro-location .camaro-locations-inner,
.product-detail-head .detail-page-head .camaro-location,
.product-detail-head .detail-page-head .list-rating,
.product-detail-head .detail-page-head,
.testimonials-section .review-box .review-details .list-rating .list-rating-star,
.testimonials-section .review-box,
.review-sec .sidebar-form,
.review-sec .review-card .review-header-group .review-widget-header,
.review-sec .review-card .review-header-group,
.review-sec .reviewbox-list-rating p,
.blogdetails-pagination ul,
.tag-list .tags,
.tag-list,
.blogdetail-content .card-header,
.bloglistleft-widget .post-author,
.bloggrid-sidebar .post-author,
.blogbanner-content .post-author,
.blogbanner-content ul,
.blog-section .rightsidebar .card h4,
.blog-section .blog-content .blog-list-date .meta-item-list li .post-author,
.blog-section .blog-content .meta-item li .post-author,
.blog-section .blog-content .meta-item,
.contact-section .contact-info-area .single-contact-info i,
.serve-form .input-block .button-notific,
.privacy-section .terms-policy ul li,
.pagination,
.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group,
.login-wrapper .loginbox .login-auth,
.user-app-group .userapp-heading .download-btn,
.popular-slider-group .owl-carousel .owl-next i,
.popular-slider-group .owl-carousel .owl-prev i,
.listing-item .listing-content .listing-button .btn-order,
.listing-item .listing-content .listing-location-details .listing-price h6,
.listing-item .listing-content .listing-details-group ul li,
.listing-item .listing-img .fav-item .fav-icon,
.listing-item .listing-img .fav-item .featured-text,
.popular-services .owl-carousel .owl-next i,
.popular-services .owl-carousel .owl-prev i,
.popular-services .listing-tabs-group ul li a,
.search-box-banner .input-block-wrapp,
.footer .footer-contact .footer-contact-info .footer-address .addr-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center
}

.sorting-div .product-filter-group {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end
}

.invoice-section .invoice-item-bill ul li:first-child,
.table .dropdown-item,
.footer-social-widget .nav-social {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start
}

.chat-window .chat-cont-right .chat-header,
.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body,
.chat-window .chat-cont-left .chat-header,
.listview-car .card .card-body .blog-list-head,
.product-detail-head .detail-page-head,
.blog-section .blog-content .meta-item {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.chat-window .chat-msg-info {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body {
    -webkit-box-align: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body {
    -ms-flex-direction: column;
    flex-direction: column
}

.col {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    float: left;
    position: relative;
    min-height: 1px
}

@media(min-width:320px) {
    .col.col-xs-12 {
        width: 100%
    }

    .col.col-xs-11 {
        width: 91.66666667%
    }

    .col.col-xs-10 {
        width: 83.33333333%
    }

    .col.col-xs-9 {
        width: 75%
    }

    .col.col-xs-8 {
        width: 66.66666667%
    }

    .col.col-xs-7 {
        width: 58.33333333%
    }

    .col.col-xs-6 {
        width: 50%
    }

    .col.col-xs-5 {
        width: 41.66666667%
    }

    .col.col-xs-4 {
        width: 33.33333333%
    }

    .col.col-xs-3 {
        width: 25%
    }

    .col.col-xs-2 {
        width: 16.66666667%
    }

    .col.col-xs-1 {
        width: 8.33333333%
    }
}

@media(min-width:568px) {
    .col.col-sm-12 {
        width: 100%
    }

    .col.col-sm-11 {
        width: 91.66666667%
    }

    .col.col-sm-10 {
        width: 83.33333333%
    }

    .col.col-sm-9 {
        width: 75%
    }

    .col.col-sm-8 {
        width: 66.66666667%
    }

    .col.col-sm-7 {
        width: 58.33333333%
    }

    .col.col-sm-6 {
        width: 50%
    }

    .col.col-sm-5 {
        width: 41.66666667%
    }

    .col.col-sm-4 {
        width: 33.33333333%
    }

    .col.col-sm-3 {
        width: 25%
    }

    .col.col-sm-2 {
        width: 16.66666667%
    }

    .col.col-sm-1 {
        width: 8.33333333%
    }
}

@media(min-width:768px) {
    .col.col-md-12 {
        width: 100%
    }

    .col.col-md-11 {
        width: 91.66666667%
    }

    .col.col-md-10 {
        width: 83.33333333%
    }

    .col.col-md-9 {
        width: 75%
    }

    .col.col-md-8 {
        width: 66.66666667%
    }

    .col.col-md-7 {
        width: 58.33333333%
    }

    .col.col-md-6 {
        width: 50%
    }

    .col.col-md-5 {
        width: 41.66666667%
    }

    .col.col-md-4 {
        width: 33.33333333%
    }

    .col.col-md-3 {
        width: 25%
    }

    .col.col-md-2 {
        width: 16.66666667%
    }

    .col.col-md-1 {
        width: 8.33333333%
    }
}

@media(min-width:1024px) {
    .col.col-lg-12 {
        width: 100%
    }

    .col.col-lg-11 {
        width: 91.66666667%
    }

    .col.col-lg-10 {
        width: 83.33333333%
    }

    .col.col-lg-9 {
        width: 75%
    }

    .col.col-lg-8 {
        width: 66.66666667%
    }

    .col.col-lg-7 {
        width: 58.33333333%
    }

    .col.col-lg-6 {
        width: 50%
    }

    .col.col-lg-5 {
        width: 41.66666667%
    }

    .col.col-lg-4 {
        width: 33.33333333%
    }

    .col.col-lg-3 {
        width: 25%
    }

    .col.col-lg-2 {
        width: 16.66666667%
    }

    .col.col-lg-1 {
        width: 8.33333333%
    }
}

.btn {
    position: relative;
    text-transform: initial;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.btn:hover {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.btn-view {
    font-weight: 500;
    font-size: 16px;
    color: #201f1d;
    border: 2px solid #201f1d;
    background-color: #fff;
    box-shadow: inset 0 0 0 0 #fff;
    border-radius: 5px;
    line-height: 0;
    margin: 0;
    padding: 11px 22px
}

.btn-view:hover {
    background-color: #ffa633;
    border: 2px solid #ff9307;
    color: #fff;
    box-shadow: inset 0 50px 0 0 #ff9307
}

@media(max-width:991.98px) {
    .btn-view {
        margin: 0;
        padding: 8px 12px;
        font-size: 14px
    }
}

.btn-white {
    background-color: #fff;
    border-color: #f1f1f1;
    color: #ffa633;
    box-shadow: inset 0 0 0 0 #fff
}

.btn-white:hover {
    background-color: #fff;
    border-color: #fff;
    color: #fff;
    box-shadow: inset 0 50px 0 0 #ffa633
}

.btn-white-outline {
    background-color: #fff;
    border-color: #f1f1f1;
    color: #3f4254
}

.btn-white-outline:hover {
    background-color: #fff;
    border-color: #f1f1f1;
    color: #3f4254
}

.btn-primary {
    background-color: #ffa633;
    border: 1px solid #ffa633;
    box-shadow: inset 0 0 0 0 #fff
}

.btn-primary:hover {
    background-color: #fff;
    border-color: #ffa633;
    color: #ffa633;
    box-shadow: inset 0 50px 0 0 #fff
}

.btn-subscribe {
    background-color: #ffa633;
    border: 1px solid #ffa633;
    box-shadow: inset 0 0 0 0 #fff
}

.btn-subscribe:hover {
    background-color: #fff;
    border-color: #ffa633;
    color: #ffa633;
    box-shadow: inset 0 50px 0 0 #fff
}

.btn-subscribe:hover i {
    color: #ffa633 !important;
    -webkit-transition: .5s;
    -ms-transition: .5s;
    transition: .5s
}

.submit-review {
    padding: 12px 20px
}

.btn-size {
    font-weight: 600;
    font-size: 16px;
    padding: 12px
}

.btn-outline-light {
    color: #fff;
    background-color: #201f1d;
    border: 1px solid #201f1d;
    box-shadow: inset 0 0 0 0 #fff
}

.btn-outline-light:hover {
    background-color: #fff;
    border-color: #f1f1f1;
    color: #3f4254;
    box-shadow: inset 0 50px 0 0 #fff
}

.btn-gray {
    background-color: #efefef;
    border: 1px solid #efefef;
    box-shadow: inset 0 0 0 0 #fff
}

.btn-gray:hover {
    background-color: #fff;
    border-color: #efefef;
    color: #878a99;
    box-shadow: inset 0 50px 0 0 #fff
}

.btn-gray-outline {
    background-color: #fff;
    border: 1px solid #efefef;
    box-shadow: inset 0 0 0 0 #fff
}

.btn-gray-outline:hover {
    background-color: #efefef;
    border-color: #fff;
    color: #878a99;
    box-shadow: inset 0 50px 0 0 #fff
}

.btn-outline-primary {
    color: #ffa633;
    border-color: #ffa633
}

.btn-outline-primary:hover {
    background-color: #ffa633;
    border-color: #ffa633
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    box-shadow: none !important
}

.btn:focus {
    box-shadow: none !important
}

.btn.btn-greys {
    background: #efefef;
    color: #201f1d;
    font-size: 13px;
    padding: 8px 16px;
    font-weight: 600
}

.cancel {
    background-color: #fff;
    border: 1px solid #377dff;
    box-shadow: inset 0 0 0 0 #377dff;
    color: #377dff
}

.cancel:hover {
    background-color: #fff;
    border-color: #377dff;
    color: #377dff;
    box-shadow: inset 0 50px 0 0 #fff
}

.contact-btn {
    background-color: #201f1d;
    border: 1px solid #201f1d;
    box-shadow: inset 0 0 0 #fff;
    color: #fff;
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 15px;
    text-align: center
}

.contact-btn:hover {
    background-color: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff;
    color: #201f1d
}

.btn-secondary {
    background: #2f2f2f;
    color: #fff
}

.fa-facebook {
    background: #3080f8;
    color: #fff
}

.fa-twitter {
    background: #35b6ff;
    color: #fff
}

.fa-instagram {
    background: #ff2d20;
    color: #fff
}

.fa-behance {
    background: #06f;
    color: #fff
}

.fa-linkedin {
    background: #06f;
    color: #fff
}

.btn-primary.btn:first-child:active {
    background: #ffa633;
    border-color: #ffa633
}

.content {
    padding: 40px 0
}

.content .content-header {
    padding-bottom: 40px
}

@media(max-width:767.98px) {
    .content .content-header {
        padding-bottom: 20px
    }
}

.content .content-header h4 {
    font-size: 32px;
    font-weight: 700;
    color: #000;
    margin-bottom: 0
}

@media(max-width:991.98px) {
    .content .content-header h4 {
        font-size: 28px
    }
}

@media(max-width:767.98px) {
    .content .content-header h4 {
        font-size: 26px
    }
}

.content .content-settings-header {
    padding-bottom: 15px
}

.content.dashboard-content {
    padding-bottom: 16px
}

.header {
    background: #fff;
    position: sticky;
    box-shadow: 0 10px 40px rgba(202, 202, 202, .2);
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999
}

.header.fixed {
    background-color: #fff;
    box-shadow: 0 3px 53px rgba(197, 197, 197, .27)
}

.header .logo a {
    float: left
}

.header .logo a img {
    float: left;
    max-height: 32px
}

.header .header-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    position: relative;
    height: 85px;
    padding: 0 15px;
    margin-bottom: 0
}

@media(max-width:991.98px) {
    .header .header-nav {
        height: 65px;
        padding: 0
    }
}

.header .header-nav .main-nav>li>a {
    color: #2f2f2f;
    line-height: 85px;
    font-weight: 500
}

@media(max-width:991.98px) {
    .header .header-nav .main-nav>li>a {
        font-size: 14px
    }
}

@media(max-width:991.98px) {
    .header .header-nav .main-nav>li>a {
        color: #fff
    }
}

.header .header-nav .main-nav>li>a:hover {
    color: #ffa633
}

.header .header-nav .main-nav>li .submenu li {
    position: relative
}

.header .header-nav .main-nav>li .submenu li.active {
    color: #ffa633
}

.header .header-nav .main-nav>li .submenu li.active>a {
    color: #ffa633
}

.header .header-nav .main-nav>li .submenu li a {
    display: block;
    padding: 10px 15px;
    clear: both;
    white-space: nowrap;
    color: #2f2f2f;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
    width: 100%;
    position: relative;
    font-weight: 500
}

@media(max-width:991.98px) {
    .header .header-nav .main-nav>li .submenu li a {
        padding: 10px;
        color: #fff;
        font-size: 12px
    }
}

.header .header-nav .main-nav>li .submenu li a:hover {
    color: #ffa633;
    padding-left: 20px
}

.header .header-nav .main-nav>li .login-link {
    display: none
}

.header .navbar-header .navbar-brand {
    display: inline-block;
    padding: 0;
    line-height: 0
}

@media(max-width:991.98px) {
    .header .navbar-header .navbar-brand {
        margin-right: 0
    }
}

@media(max-width:575.98px) {
    .header .navbar-header .logo {
        display: none
    }
}

.header .navbar-header .logo-small {
    display: none
}

@media(max-width:575.98px) {
    .header .navbar-header .logo-small {
        display: block;
        width: 100%;
        text-align: center
    }
}

.header .navbar-header #mobile_btn {
    display: none;
    margin-right: 30px
}

@media(max-width:991.98px) {
    .header .navbar-header #mobile_btn {
        display: inline-block;
        line-height: 0
    }
}

@media(max-width:575.98px) {
    .header .navbar-header #mobile_btn {
        left: 0;
        margin-right: 0;
        padding: 0 0;
        position: absolute;
        z-index: 99
    }
}

.header .navbar-header #mobile_btn .bar-icon {
    display: inline-block;
    width: 31px
}

.header .navbar-header #mobile_btn .bar-icon span {
    background-color: #ffa633;
    display: block;
    float: left;
    height: 3px;
    margin-bottom: 7px;
    width: 31px;
    border-radius: 2px;
    margin-bottom: 5px
}

.header .navbar-header #mobile_btn .bar-icon span:nth-child(2) {
    width: 20px
}

.header .navbar-header #mobile_btn .bar-icon span:nth-child(3) {
    width: 16px;
    margin-bottom: 0
}

@media(max-width:991.98px) {
    .header .navbar-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: inline-flex;
        align-items: center
    }
}

@media(max-width:575.98px) {
    .header .navbar-header {
        width: 100%
    }
}

.header .main-menu-wrapper {
    margin: 0 15px
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper {
        order: 3;
        width: 260px;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        z-index: 11111;
        transform: translateX(-260px);
        transition: all .4s;
        background: #201f1d;
        margin: 0
    }
}

.header .main-menu-wrapper .menu-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    display: none
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper .menu-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 65px;
        align-items: center;
        background: #fff
    }
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper .menu-header .menu-logo img {
        width: 160px
    }
}

.header .main-menu-wrapper .menu-header .menu-close {
    font-size: 18px;
    color: #ffa633
}

.header .main-menu-wrapper .main-nav>li .submenu>li .submenu {
    left: 100%;
    top: 0;
    margin-top: 10px
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper .main-nav>li .submenu>li .submenu {
        left: 0
    }
}

.header .main-menu-wrapper .main-nav>li .submenu>li.has-submenu>a::after {
    content: "\f054";
    font-family: "font awesome 5 free";
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 13px
}

.header .main-menu-wrapper .main-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    padding: 0
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper .main-nav {
        padding: 0;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        background: #201f1d
    }
}

.header .main-menu-wrapper .main-nav:last-child {
    margin-right: 0
}

@media(max-width:992px) {
    .header .main-menu-wrapper .main-nav>li {
        margin-right: 0 !important
    }

    .header .main-menu-wrapper .main-nav>li:last-child {
        margin-right: 0;
        border: 0
    }
}

.header .main-menu-wrapper .main-nav>li.active>a {
    display: block;
    color: #ffa633
}

.header .main-menu-wrapper .main-nav>li a i {
    font-size: 12px;
    margin-left: 3px
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper .main-nav>li a {
        display: block;
        line-height: 20px;
        padding: 12px 15px;
        font-size: 14px;
        font-weight: 500;
        color: #fff
    }

    .header .main-menu-wrapper .main-nav>li a i {
        font-size: 14px
    }
}

@media(max-width:991.98px) and (max-width:991.98px) {
    .header .main-menu-wrapper .main-nav>li a i {
        float: right;
        margin-top: 5px;
        font-size: 14px
    }
}

.header .main-menu-wrapper .main-nav>li:last-child {
    margin-right: 0
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper .main-nav>li {
        border-bottom: 1px solid #828282;
        padding: 0
    }
}

@media(min-width:992px) {
    .header .main-menu-wrapper .main-nav>li {
        display: block;
        position: relative;
        margin-right: 30px
    }

    .header .main-menu-wrapper .main-nav>li:last-child {
        margin-right: 0
    }
}

@media(max-width:1199.98px) {
    .header .main-menu-wrapper .main-nav>li {
        margin-right: 20px
    }
}

@media(min-width:992px) {
    .header .main-menu-wrapper .main-nav>li ul {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 3px rgba(0, 0, 0, .1);
        display: block;
        font-size: 14px;
        left: 0;
        margin: 0;
        min-width: 200px;
        opacity: 0;
        padding: 0;
        position: absolute;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px);
        transform: translateY(20px);
        top: 100%;
        visibility: hidden;
        z-index: 1000
    }
}

@media(min-width:992px) {
    .header .main-menu-wrapper .main-nav li.has-submenu:hover>.submenu {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        border-radius: 2px;
        border-top: 1px solid #ffa633
    }
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper .main-nav .has-submenu ul {
        display: none;
        background: rgba(255, 255, 255, .2)
    }
}

.header .main-menu-wrapper .main-nav li.login-link {
    display: none
}

@media(max-width:991.98px) {
    .header .main-menu-wrapper .main-nav li.login-link {
        display: block
    }
}

.header .header-navbar-rht {
    margin: 0;
    padding: 0
}

@media(max-width:575.98px) {
    .header .header-navbar-rht {
        display: none
    }
}

.header .header-navbar-rht>li {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-right: 15px;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.header .header-navbar-rht>li:last-child {
    padding-right: 0
}

.header .header-navbar-rht>li .dropdown-menu {
    border: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1)
}

@media(min-width:992px) {
    .header .header-navbar-rht>li .dropdown-menu {
        top: 85px;
        right: 0;
        left: auto;
        min-width: 200px;
        visibility: hidden;
        opacity: 0;
        display: block;
        border-radius: 0 0 5px 5px;
        margin: 0;
        padding: 0;
        -webkit-transition: all .2s ease;
        -ms-transition: all .2s ease;
        transition: all .2s ease;
        transform: translateY(20px);
        -webkit-transform: translateY(20px);
        -ms-transform: translateY(20px)
    }
}

@media(min-width:992px) and (max-width:991.98px) {
    .header .header-navbar-rht>li .dropdown-menu {
        top: 65px
    }
}

.header .header-navbar-rht>li .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #f4f4f4;
    padding: 10px 15px;
    font-size: 14px;
    color: #2f2f2f;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.header .header-navbar-rht>li .dropdown-menu .dropdown-item:hover {
    color: #ffa633;
    padding-left: 25px;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.header .header-navbar-rht>li .dropdown-menu .dropdown-item:first-child {
    border-radius: 0
}

.header .header-navbar-rht>li .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
    border-radius: 0 0 5px 5px
}

.header .header-navbar-rht>li .dropdown-menu .dropdown-item i {
    font-size: 16px;
    margin-right: 6px
}

.header .header-navbar-rht>li .dropdown-menu .dropdown-item.active,
.header .header-navbar-rht>li .dropdown-menu .dropdown-item:active {
    color: #ffa633;
    background-color: #fff
}

.header .header-navbar-rht>li .dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

.header .header-navbar-rht>li .dropdown-toggle::after {
    display: none
}

.header .header-navbar-rht .has-arrow .dropdown-toggle:after {
    border-top: 0;
    border-left: 0;
    border-bottom: 2px solid #2f2f2f;
    border-right: 2px solid #2f2f2f;
    content: "";
    height: 8px;
    display: inline-block;
    pointer-events: none;
    width: 8px;
    vertical-align: 2px;
    margin-left: 10px;
    margin-bottom: 5px;
    transform-origin: 66% 66%;
    -webkit-transform-origin: 66% 66%;
    -ms-transform-origin: 66% 66%;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transition: all .15s ease-in-out;
    -ms-transition: all .15s ease-in-out;
    transition: all .15s ease-in-out
}

.header .header-navbar-rht .has-arrow .dropdown-toggle[aria-expanded=true]:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg)
}

.header .header-navbar-rht .has-arrow .dropdown-toggle .user-img {
    display: inline-block;
    position: relative;
    margin-right: 5px
}

.header .header-navbar-rht .has-arrow .dropdown-toggle .user-img>img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border: 2px solid #dbdbdb
}

.header .header-navbar-rht .has-arrow .dropdown-toggle .user-text {
    color: #2f2f2f;
    margin: 0
}

.header .header-navbar-rht .has-arrow .user-header {
    padding: 10px 15px
}

.header .header-navbar-rht .has-arrow .user-header .user-text {
    margin-left: 10px
}

.header .header-navbar-rht .has-arrow .user-header .user-text h6 {
    font-size: 15px;
    margin-bottom: 2px
}

.header .header-navbar-rht .has-arrow .logged-item .nav-link {
    font-size: 14px;
    line-height: 85px;
    padding: 0 10px
}

.header .header-navbar-rht .has-arrow.logged-item .nav-link {
    font-size: 14px;
    line-height: 85px;
    padding: 0 10px
}

@media(max-width:991.98px) {
    .header .header-navbar-rht .has-arrow.logged-item .nav-link {
        line-height: 65px
    }
}

.header .header-navbar-rht .noti-wrapper {
    padding: 0
}

.header .header-navbar-rht .noti-wrapper .nav-link {
    position: relative;
    padding: 0
}

.header .header-navbar-rht .noti-wrapper .bell-icon {
    width: 36px;
    height: 36px;
    background: #f1f1f1;
    border-radius: 50%;
    margin: 0
}

.header .header-navbar-rht .noti-wrapper .badge-pill {
    width: 4px;
    height: 4px;
    background-color: red;
    position: absolute;
    top: 8px;
    right: 10px;
    margin: 0;
    padding: 0;
    display: block
}

.header .header-navbar-rht .nav-item .add-listing {
    font-weight: 500;
    font-size: 16px;
    color: #201f1d
}

.header .header-navbar-rht .nav-item .add-listing span {
    color: #127384
}

.header .header-navbar-rht .nav-item .header-login {
    background-color: #201f1d;
    border: 1px solid #201f1d;
    box-shadow: inset 0 0 0 #fff;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 20px;
    text-align: center
}

.header .header-navbar-rht .nav-item .header-login span {
    margin: 0 8px 0 0
}

@media(max-width:991.98px) {
    .header .header-navbar-rht .nav-item .header-login {
        padding: 8px 12px;
        font-size: 14px
    }
}

.header .header-navbar-rht .nav-item .header-login:hover {
    background-color: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff;
    color: #201f1d
}

.header .header-navbar-rht .nav-item .header-reg {
    background-color: #ffa633;
    border: 1px solid #ffa633;
    box-shadow: inset 0 0 0 #fff;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 20px
}

.header .header-navbar-rht .nav-item .header-reg span {
    margin: 0 8px 0 0
}

@media(max-width:991.98px) {
    .header .header-navbar-rht .nav-item .header-reg {
        padding: 8px 12px;
        font-size: 14px
    }
}

.header .header-navbar-rht .nav-item .header-reg:hover {
    background-color: #fff;
    border: 1px solid #ffa633;
    box-shadow: inset 0 70px 0 0 #fff;
    color: #ffa633
}

.header .dropdown-toggle::after {
    display: none
}

.header .noti-content {
    height: 290px;
    width: 350px;
    overflow-y: auto;
    position: relative
}

.header .noti-dropdown .dropdown-toggle {
    color: #fff;
    font-size: 14px;
    line-height: 23px;
    padding: 0 15px
}

.header .noti-dropdown .dropdown-toggle::after {
    display: none
}

.header .noti-dropdown .dropdown-toggle span {
    background-color: #f43f3f;
    display: block;
    font-size: 10px;
    font-weight: 700;
    min-height: 15px;
    min-width: 15px;
    position: absolute;
    right: 3px;
    top: 5px
}

.header .noti-dropdown .dropdown-toggle i {
    float: right;
    line-height: 50px;
    margin-left: 15px;
    font-size: 20px;
    float: left;
    line-height: 50px;
    color: #fff;
    margin: 0;
    margin-left: 0;
    cursor: pointer
}

.header .noti-dropdown .dropdown-menu {
    top: 63px !important;
    font-size: 14px
}

.header .user-header {
    background-color: #f4f4f4;
    display: flex;
    padding: 10px 15px
}

.header .notification-message {
    margin-bottom: 0
}

.header .notification-message h6 {
    font-size: 14px;
    font-weight: 400;
    color: #28283c;
    position: relative;
    padding-left: 10px;
    margin-bottom: 8px
}

.header .notification-message h6:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    background: #dddee1;
    border-radius: 2px;
    width: 4px;
    height: 100%
}

.header .notification-message .avatar-online::before {
    top: 0;
    background: #6dcc76
}

.header .notifications {
    padding: 0;
    filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2))
}

.header .notifications .notification-time {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.35;
    color: #7e84a3
}

.header .notifications .notify-btns .btn {
    margin: 0 8px 8px 0
}

.header .notifications .media {
    margin-top: 0;
    border-bottom: 1px solid #f5f5f5
}

.header .notifications .media:last-child {
    border-bottom: none
}

.header .notifications .media a {
    display: block;
    padding: 0;
    border-radius: 2px
}

.header .notifications .media a:hover {
    background-color: #fafafa
}

.header .notifications .media .avatar {
    margin-right: 15px;
    width: 38px;
    height: 38px;
    position: relative;
    display: inline-block
}

.header .notifications .media .avatar img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.header .notifications .media-list .media-left {
    padding-right: 8px
}

.header .notifications .noti-content::-webkit-scrollbar {
    width: 6px;
    background: #eef1fe;
    height: 10px
}

.header .notifications .noti-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #e7e7e7;
    border-radius: 4px
}

.header .notifications .noti-content::-webkit-scrollbar-thumb {
    background: #8a8a95;
    border-radius: 4px
}

.header .notifications .noti-content::-webkit-scrollbar-thumb:hover {
    background: #1b2559
}

.header .notifications ul.notification-list>li {
    margin-top: 0;
    padding: 10px 15px;
    border-bottom: 1px solid #f5f5f5
}

.header .notifications ul.notification-list>li:last-child {
    border-bottom: none
}

.header .notifications ul.notification-list>li a {
    display: block;
    border-radius: 2px
}

.header .notifications ul.notification-list>li>a:hover {
    background-color: #fafafa
}

.header .notifications ul.notification-list>li .list-item {
    border: 0;
    padding: 0;
    position: relative
}

.header .topnav-dropdown-header {
    border-bottom: 1px solid #eee;
    text-align: center;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    height: 45px;
    line-height: 45px;
    padding-left: 15px;
    padding-right: 15px
}

.header .topnav-dropdown-header select {
    border: 0;
    font-size: 12px;
    color: #74788d
}

.header .topnav-dropdown-header select:focus-visible {
    outline: 0
}

.header .topnav-dropdown-header .notification-title {
    display: block;
    float: left;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0
}

.header .topnav-dropdown-header .notification-title span {
    padding: 3px 6px;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    background: #ff6000
}

.header .topnav-dropdown-header .clear-noti {
    color: #28283c;
    float: right;
    font-size: 14px
}

.header .topnav-dropdown-header .clear-noti:hover {
    color: #ff6000
}

.header .topnav-dropdown-footer {
    height: 45px;
    line-height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    border-top: 1px solid #eee
}

.header .topnav-dropdown-footer a {
    display: block;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    color: #ff6000;
    display: flex;
    align-items: center;
    justify-content: center
}

.header .topnav-dropdown-footer a:hover {
    color: #ff6000
}

.header .topnav-dropdown-footer a:hover i {
    color: #ff6000;
    margin-left: 8px;
    transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -webkit-transition: all .4s ease
}

.header .topnav-dropdown-footer a img {
    margin-left: 5px;
    filter: brightness(0) saturate(100%) invert(24%) sepia(84%) saturate(2990%) hue-rotate(239deg) brightness(91%) contrast(105%)
}

.header .user-menu.nav>li>a .badge {
    background-color: #f43f3f;
    display: block;
    font-size: 10px;
    font-weight: 700;
    min-height: 15px;
    min-width: 15px;
    position: absolute;
    right: 3px;
    top: 6px
}

.header .user-menu.nav>li>a>i {
    font-size: 1.5rem;
    line-height: 60px
}

.header .noti-details {
    font-size: 14px;
    color: #28283c;
    margin-bottom: 0;
    font-weight: 500
}

.header .noti-details span {
    color: #74788d;
    font-weight: 400;
    margin: 0
}

.header .notification-list {
    list-style: none;
    padding: 0;
    margin: 0
}

.header .noti-pop-detail li.noti-wrapper .notify-blk {
    padding: 24px;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    background: #fff;
    box-shadow: 0 4px 114px 0 rgba(214, 214, 214, .25);
    width: 410px;
    height: 580px
}

.header .noti-pop-detail li.noti-wrapper .notify-blk .topnav-dropdown-header {
    padding: 15px;
    border-radius: 10px;
    background: #fafafa;
    border: none;
    margin-bottom: 15px
}

.header .noti-pop-detail li.noti-wrapper .notify-blk .noti-content ul.notification-list li.notification-message {
    padding: 10px
}

.header .noti-pop-detail li.noti-wrapper .notify-blk .noti-content ul.notification-list li .media-body p.noti-details {
    margin-bottom: 5px;
    position: relative
}

.header .noti-pop-detail li.noti-wrapper .notify-blk .topnav-dropdown-footer {
    border: none
}

.header .noti-pop-detail .noti-img span img {
    border-radius: 45px;
    border: 2px solid #e1e1e1
}

.header .noti-time {
    font-size: 14px;
    margin: 0
}

.menu-opened .main-menu-wrapper {
    transform: translateX(0)
}

.menu-opened .main-menu-wrapper body {
    overflow: hidden
}

.footer {
    background: #201f1d;
    position: relative
}

.footer::before {
    content: "";
    background: url(../img/footer-left.png) no-repeat;
    position: absolute;
    top: 50px;
    left: 0;
    width: 97px;
    height: 102px;
    background-size: cover
}

@media(max-width:1199.98px) {
    .footer::before {
        display: none
    }
}

.footer::after {
    content: "";
    background: url(../img/footer-right.png) no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 393px;
    height: 334px;
    background-size: cover
}

@media(max-width:991.98px) {
    .footer::after {
        display: none
    }
}

.footer .footer-top {
    position: relative;
    z-index: 99;
    margin: 0;
    padding: 60px 0
}

@media(max-width:991.98px) {
    .footer .footer-top {
        margin: 0;
        padding: 40px 0 10px
    }
}

@media(max-width:767.98px) {
    .footer .footer-top {
        margin: 0;
        padding: 40px 0 0
    }
}

.footer .footer-about {
    border-right: 1px solid #000
}

@media(max-width:767.98px) {
    .footer .footer-about {
        border: 0
    }
}

.footer .footer-widget .footer-logo {
    margin-bottom: 35px
}

.footer .footer-widget .footer-about-content {
    margin-bottom: 50px
}

@media(max-width:991.98px) {
    .footer .footer-widget .footer-about-content {
        margin-bottom: 30px
    }
}

@media(max-width:767.98px) {
    .footer .footer-widget .footer-about-content {
        margin-bottom: 20px
    }
}

.footer .footer-widget .footer-about-content p {
    font-weight: 700;
    color: #1f2937;
    margin-bottom: 0
}

.footer .footer-widget.footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.footer .footer-widget .footer-title {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0 0 40px
}

.footer .footer-widget .footer-title:before {
    content: "";
    background: #fcb215;
    width: 27px;
    height: 4px;
    left: 0;
    position: absolute;
    bottom: -80%
}

@media(max-width:991.98px) {
    .footer .footer-widget {
        margin-bottom: 30px
    }
}

@media(max-width:767.98px) {
    .footer .footer-widget {
        margin-bottom: 25px
    }
}

.footer .footer-menu ul li {
    margin: 0 0 20px
}

.footer .footer-menu ul li:last-child {
    margin-bottom: 0
}

.footer .footer-menu ul li a {
    color: #828282;
    -webkit-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}

.footer .footer-menu ul li a:hover {
    color: #ffa633;
    padding-left: 10px;
    -webkit-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out
}

.footer .footer-contact h5 {
    font-weight: 700;
    font-size: 24px;
    color: #fff;
    max-width: 336px;
    margin-bottom: 20px
}

.footer .footer-contact h6 {
    font-size: 16px;
    color: #dbdbdb;
    margin-bottom: 15px;
    line-height: 20px
}

.footer .footer-contact .footer-contact-info .footer-address {
    margin-bottom: 20px
}

.footer .footer-contact .footer-contact-info .footer-address span {
    margin-right: 20px;
    width: 50px;
    height: 50px;
    background: #ff9307;
    color: #fff;
    font-size: 20px;
    border-radius: 4px;
    flex-shrink: 0
}

.footer .footer-contact .footer-contact-info .footer-address .addr-info a {
    font-weight: 500;
    font-size: 18px;
    color: #fff
}

.footer .footer-contact .footer-contact-info .update-form {
    position: relative
}

.footer .footer-contact .footer-contact-info .update-form span {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #828282;
    font-size: 20px;
    line-height: 1
}

.footer .footer-contact .footer-contact-info .update-form .form-control {
    min-height: 55px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
    font-size: 14px;
    margin: 0;
    padding: 10px 100px 12px 40px;
    color: #828282
}

.footer .footer-contact .footer-contact-info .update-form .btn {
    padding: 20px;
    font-size: 15px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%)
}

.footer .footer-contact .footer-contact-info .update-form .btn .feather-send {
    color: #fff
}

.footer .footer-contact .footer-contact-info .update-form .btn:active {
    border-color: #fcb215
}

.footer .footer-contact .footer-contact-info .update-form .btn:active .feather-send {
    color: #fcb215
}

.footer .footer-bottom {
    background: #201f1d;
    position: relative;
    z-index: 99;
    margin: 0;
    padding: 20px 0
}

@media(max-width:767.98px) {
    .footer .footer-bottom {
        margin: 0;
        padding: 15px 0
    }
}

@media(max-width:767.98px) {
    .footer .footer-bottom .copyright {
        text-align: center
    }
}

@media(max-width:767.98px) {
    .footer .footer-bottom .copyright .copyright-text {
        margin: 0 0 20px
    }
}

.footer .footer-bottom .copyright .copyright-text p {
    color: #fff
}

.footer .footer-bottom .copyright .vistors-details ul {
    justify-content: end;
    -webkit-justify-content: end
}

@media(max-width:767.98px) {
    .footer .footer-bottom .copyright .vistors-details ul {
        justify-content: center
    }
}

.footer .footer-bottom .copyright .vistors-details ul li {
    margin: 0;
    padding: 0 8px
}

.footer .footer-bottom .copyright .vistors-details ul li a {
    background: #fff;
    width: 46px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px
}

.footer .footer-bottom .policy-menu {
    text-align: right;
    margin: 0;
    padding: 0
}

@media(max-width:767.98px) {
    .footer .footer-bottom .policy-menu {
        text-align: center
    }
}

.footer .footer-bottom .policy-menu li {
    display: inline-block;
    margin-right: 30px;
    position: relative
}

@media(max-width:767.98px) {
    .footer .footer-bottom .policy-menu li {
        margin-right: 10px;
        margin-top: 10px
    }
}

.footer .footer-bottom .policy-menu li a {
    color: #000
}

.footer .footer-bottom .policy-menu li a:hover {
    color: #201f1d
}

.footer .footer-bottom .policy-menu li::after {
    content: "";
    position: absolute;
    background: #201f1d;
    width: 5px;
    height: 5px;
    left: -10px;
    top: 50%;
    transform: translateY(-50%)
}

.footer .footer-bottom .policy-menu li:last-child {
    margin-right: 0
}

@-webkit-keyframes toTopFromBottom {
    49% {
        -webkit-transform: translateY(-100%)
    }

    50% {
        opacity: 0;
        -webkit-transform: translateY(100%)
    }

    51% {
        opacity: 1
    }
}

@-moz-keyframes toTopFromBottom {
    49% {
        -moz-transform: translateY(-100%)
    }

    50% {
        opacity: 0;
        -moz-transform: translateY(100%)
    }

    51% {
        opacity: 1
    }
}

@keyframes toTopFromBottom {
    49% {
        transform: translateY(-100%)
    }

    50% {
        opacity: 0;
        transform: translateY(100%)
    }

    51% {
        opacity: 1
    }
}

.footer-social-links {
    margin: 10px 0 30px
}

.footer-social-links h6 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px
}

@media(max-width:767.98px) {
    .footer-social-links h6 {
        margin-bottom: 10px
    }
}

.footer-social-links .nav li {
    margin-right: 10px
}

.footer-social-links .nav li:last-child {
    margin-right: 0
}

.footer-social-links .nav li a:hover {
    color: #000
}

.footer-social-widget {
    margin: 20px 0 0
}

.footer-social-widget .nav-social li {
    margin-right: 10px
}

.footer-social-widget .nav-social li:last-child {
    margin-right: 0
}

.footer-social-widget .nav-social li a:hover {
    color: #000
}

.hi-icon {
    font-size: 0;
    cursor: pointer;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    color: #201f1d;
    background: #dbdbdb;
    border-radius: 50px;
    min-width: 36px;
    height: 36px;
    -webkit-transition: background .3s, color .3s, box-shadow .3s;
    -moz-transition: background .3s, color .3s, box-shadow .3s;
    transition: background .3s, color .3s, box-shadow .3s
}

@media(max-width:767.98px) {
    .hi-icon {
        min-width: 36px;
        height: 36px
    }
}

.hi-icon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0;
    content: "";
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: none
}

.hi-icon:before {
    speak: none;
    font-size: 18px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased
}

@media(max-width:767.98px) {
    .hi-icon:before {
        font-size: 15px
    }
}

.hi-icon:hover {
    color: #201f1d
}

.hi-icon:hover:before {
    -webkit-animation: toTopFromBottom .5s forwards;
    -moz-animation: toTopFromBottom .5s forwards;
    animation: toTopFromBottom .5s forwards
}

.fi-icon {
    font-size: 0;
    cursor: pointer;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: 50px;
    min-width: 36px;
    height: 36px;
    -webkit-transition: background .3s, color .3s, box-shadow .3s;
    -moz-transition: background .3s, color .3s, box-shadow .3s;
    transition: background .3s, color .3s, box-shadow .3s
}

@media(max-width:767.98px) {
    .fi-icon {
        min-width: 36px;
        height: 36px
    }
}

.fi-icon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0;
    content: "";
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    display: none
}

.fi-icon:before {
    speak: none;
    font-size: 18px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;
    color: #fff
}

@media(max-width:767.98px) {
    .fi-icon:before {
        font-size: 15px
    }
}

.fi-icon:hover {
    color: #201f1d
}

.fi-icon:hover:before {
    -webkit-animation: toTopFromBottom .5s forwards;
    -moz-animation: toTopFromBottom .5s forwards;
    animation: toTopFromBottom .5s forwards
}

.breadcrumb-bar {
    background: #201f1d;
    padding: 60px 0;
    position: relative;
    z-index: 1;
    width: 100%
}

@media(max-width:991.98px) {
    .breadcrumb-bar {
        padding: 50px 0
    }
}

.breadcrumb-bar:before {
    content: "";
    background: url(../img/breadcrumbleft.png) no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 393px;
    height: 334px;
    background-size: cover
}

@media(max-width:991.98px) {
    .breadcrumb-bar:before {
        display: none
    }
}

.breadcrumb-bar:after {
    content: "";
    background: url(../img/breadcrumbright.png) no-repeat;
    position: absolute;
    bottom: 50px;
    right: 130px;
    width: 97px;
    height: 102px;
    background-size: cover
}

@media(max-width:991.98px) {
    .breadcrumb-bar:after {
        display: none
    }
}

.breadcrumb-bar .breadcrumb-title {
    font-weight: 700;
    color: #fff;
    font-size: 36px;
    margin: 0 0 15px
}

@media(max-width:991.98px) {
    .breadcrumb-bar .breadcrumb-title {
        font-size: 32px
    }
}

.breadcrumb-bar .breadcrumb {
    justify-content: center;
    margin: 0
}

.breadcrumb-bar .breadcrumb li a {
    color: #fff
}

.breadcrumb-bar .breadcrumb.active {
    color: #fff
}

.breadcrumb-bar .breadcrumb-item+.breadcrumb-item::before {
    color: #fff
}

.breadcrumb-bar .breadcrumb-item.active {
    color: #ffa633
}

.select2-container {
    min-width: 100% !important;
    width: auto !important;
    z-index: 99;
    padding: 0 !important;
    color: #141432
}

.select2-container .select2-selection--single {
    height: 43px
}

.select2-container--default .select2-selection--single {
    background: #fcfbfb;
    border: 1px solid #f4f4f4;
    border-radius: 5px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #878a99;
    line-height: 40px;
    border-radius: 5px;
    padding-right: 30px;
    padding-left: 15px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 40px;
    right: 6px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    position: absolute;
    top: 50%;
    left: 50%;
    border-color: #878a99;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    margin-left: -10px;
    margin-top: -3px;
    width: 0;
    transform: rotate(45deg) translateY(-50%);
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%)
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #878a99;
    border-width: 2px 0 0 2px;
    margin-top: 3px;
    padding: 3px
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: #127384;
    color: #fff
}

.select2-container--focus .select2-selection--single {
    background: #fff !important;
    border: 1px solid #127384
}

span.select2-container.select2-container--default.select2-container--open {
    z-index: 9999
}

.table {
    color: #2f2f2f;
    max-width: 100%;
    margin-bottom: 0;
    width: 100%
}

.table .dataTable {
    border-collapse: collapse !important
}

.table .table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 500;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
    min-width: 150px;
    font-size: 14px
}

.table .thead-light th {
    font-size: 15px;
    color: #201f1d;
    font-weight: 500;
    background-color: #f4f4f4;
    padding: 15px
}

.table thead {
    background-color: #f4f4f4;
    border-bottom: 0;
    vertical-align: middle;
    white-space: nowrap
}

.table tbody {
    vertical-align: middle;
    white-space: nowrap
}

.table tbody tr {
    border-bottom: 1pt solid #f1f1f1
}

.table tbody tr td {
    border-color: #dee2e6;
    padding: 15px
}

.table tbody td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle
}

.table tbody td h2 span {
    color: #888;
    display: block;
    font-size: 13px;
    margin-top: 2px
}

.table tbody td a {
    color: #2f2f2f
}

.table tbody td a.btn-action-icon {
    background: #f3f3f3;
    color: #201f1d;
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 50px
}

.table tbody td a.btn-action-icon:active {
    color: #ffa633
}

.table tbody td a+a {
    display: inline-block
}

.table tbody td a.add-btn {
    display: inline-block !important
}

.table tbody td a:hover {
    color: #ffa633
}

.table tbody td a:active {
    color: #fff
}

.table tbody td a.btn {
    display: inline-block;
    padding: 5px 10px
}

.table tbody td .items-links {
    color: #28283c;
    display: inline-block
}

.table tbody td .items-links:hover {
    color: #ffa633
}

.table .invoice-link {
    color: #201f1d;
    display: inline-block
}

.table .invoice-link:hover {
    color: #201f1d
}

.table .invoice-link .table-center th,
.table .invoice-link .table-center td {
    vertical-align: middle
}

.table .invoice-link .dropdown-menu {
    width: 200px;
    background: #fff;
    border: 1px solid #888;
    box-shadow: 0 4px 4px #888;
    margin-top: 10px !important;
    border-radius: 6px
}

.table .invoice-link .dropdown-item {
    font-weight: 500;
    font-size: 13px;
    color: #28283c;
    display: flex;
    align-items: center;
    margin-bottom: 14px
}

.table .invoice-link .dropdown-item:last-child {
    margin-bottom: 0
}

.table .invoice-link .dropdown-item:focus,
.table .invoice-link .dropdown-item:hover {
    color: #ffa633;
    background: 0 0
}

.table .invoice-link .dropdown-item.active {
    color: #fff;
    text-decoration: none;
    background-color: #ffa633
}

.table .invoice-link .dropdown-item i {
    font-size: 16px
}

.table .dropdown-menu {
    width: 160px;
    background: #fff;
    border: 1px solid #edecf8;
    box-shadow: 0 16px 32px #888;
    border-radius: 12px;
    padding: 0;
    margin-top: 10px !important
}

.table .dropdown-item {
    font-weight: 500;
    font-size: 13px;
    color: #201f1d;
    padding: 10px 20px
}

.table-inbox {
    font-size: 15px;
    margin-bottom: 0
}

.table-nowrap th,
.table-nowrap td {
    white-space: nowrap
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
    background-color: #f8f9fa
}

.table-striped .dropdown-action {
    margin-bottom: 0
}

.table-striped .table-bordered {
    border: 1px solid rgba(0, 0, 0, .05) !important
}

.table.no-border>tbody>tr>td,
.table>tbody>tr>th,
.table.no-border>tfoot>tr>td,
.table.no-border>tfoot>tr>th,
.table.no-border>thead>tr>td,
.table.no-border>thead>tr>th {
    padding: 10px 8px
}

.table-hover tbody tr:hover {
    background-color: #f6f6f7
}

.table-bordered th {
    border-color: rgba(0, 0, 0, .05)
}

.table-bordered td {
    border-color: rgba(0, 0, 0, .05)
}

.table-bordered td:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.table-bordered td:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.card-table .card-body {
    padding: 0 !important
}

.card-table .card-body .table>thead>tr>th {
    border-top: 0
}

.card-table .card-body .table td {
    color: #201f1d;
    font-weight: 400;
    border-top: 0;
    font-size: 13px;
    padding: 15px 25px;
    white-space: nowrap;
    box-shadow: none
}

.card-table .card-body .table td:first-child {
    padding-left: 25px
}

.card-table .card-body .table th {
    white-space: nowrap;
    padding: 12px 25px;
    border-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    color: #201f1d
}

.card-table .card-body .table th:first-child {
    padding-left: 25px
}

.table>:not(:first-child) {
    border: 0
}

.dataTables_length select {
    appearance: auto
}

.table-nosearch .dataTables_length,
.table-nosearch .dataTables_filter {
    display: none
}

.card-table div.table-responsive>div.dataTables_wrapper>div.row:first-child {
    padding: 1.5rem 1.5rem 0 !important;
    align-items: center
}

.card-table div.table-responsive>div.dataTables_wrapper>div.row:last-child {
    padding: 0 1.5rem 1.5rem !important;
    align-items: center
}

.dataTables_length select {
    appearance: auto
}

table .badge {
    border-radius: 4px;
    display: inline-block;
    font-size: 13px;
    min-width: auto;
    padding: 8px 10px;
    font-weight: 400;
    text-align: center
}

.table tbody td a {
    color: #0052ea
}

.table tbody td a.action-icon {
    color: #333
}

.table tbody td h2.table-avatar a {
    color: #3f4254
}

.table tbody td h2.table-avatar a:hover {
    color: #ffa633
}

.mini-sidebar .header .header-left.header-left-two .logo.logo-small {
    padding: 10px 0 0;
    margin: 0
}

.categories-table .table tr:last-child td {
    padding-bottom: 0;
    border: none
}

.categories-table .table td {
    padding: 8px;
    font-size: 14px
}

.card-table .table {
    background: #fff;
    margin-bottom: 24px !important;
    border-radius: 8px
}

.dataTables_length label {
    font-weight: 500 !important;
    font-size: 14px;
    color: #3f4254
}

.dataTables_length label:before {
    content: "Show";
    margin-right: 5px;
    font-size: 14px
}

.dataTables_length label:after {
    content: "Entries";
    margin-left: 5px;
    font-size: 14px
}

.section {
    position: relative;
    padding: 80px 0
}

@media(max-width:991.98px) {
    .section {
        padding: 50px 0
    }
}

.banner-slider {
    position: relative;
    transition: all 3s;
    /* transform: translate(0, -10%); */
    background-image: url(../img/path-makers/IMG-20240502-WA0020-removebg-preview.png);
    /* background-attachment: ; */
    background-size: 80%;
    background-repeat: no-repeat;
    background-color: #f2ec48;
    margin: 0%;
    background-position: right;
    background-position-x: 140%;
    background-position-y: 87%;
    /* background-size: cover; */
    padding: 100px 0
}

@media(max-width:991.98px) {
    .banner-slider {
        background: #f2f7f6;
        padding: 70px 0
    }
}

@media(max-width:479px) {
    .banner-slider {
        padding: 50px 0
    }
}

.banner-slider .home-banner h1 {
    letter-spacing: -.02em;
    margin: 0 0 10px;
}

.banner-slider .home-banner h1 span {
    color: #44ff33
}

.banner-slider .home-banner .banner-imgs img {
    position: relative;
    z-index: 99
}

@media(max-width:991.98px) {
    .banner-slider .home-banner .banner-imgs img {
        display: none
    }
    .input-block-1 img{
        display: none
    }
}

@media(max-width:991.98px) {
    .carImg {
        display: none
    }
    .carImg1 {
        display: block
    }
}
@media(min-width:985.98px) {
    .carImg1 img{
        display: none
    }
    .carImg1 .nav-link{
        display: none
    }
}
.banner-slider .home-banner .explore-text {
    color: #2f2f2f;
    padding: 10px 20px;
    background: #fff;
    border-radius: 70px;
    display: inline-block;
    margin: 0 0 15px
}

@media(max-width:991.98px) {
    .banner-slider .home-banner .explore-text {
        padding: 10px 15px
    }
}

.banner-slider .home-banner .explore-text span {
    color: #00ed27
}

.banner-slider .home-banner .explore-text span i {
    font-weight: 600
}

.banner-slider p {
    margin: 0 0 30px;
    font-size: 18px
}

@media(max-width:991.98px) {
    .banner-slider p {
        font-size: 14px
    }
}

.search-box-banner {
    background: #fff;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 10px;
    padding: 25px;
    height: 400px;
    z-index: 1;
    position: relative;
    margin: -35px auto auto;
}
.car-options22 {
    background: #fff;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 10px;
    /* padding: 25px; */
    height: 20rem;
    /* z-index: 1; */
    position: relative;
    margin:  auto auto;
}

@media(max-width:991.98px) {
    .search-box-banner {
        padding: 15px 15px 0;
        max-width: 100%
    }
}

@media(max-width:767.98px) {
    .search-box-banner {
        padding: 20px;
        max-width: 100%
    }
}

.search-box-banner ul {
    list-style: none;
    margin: 0 -10px;
    padding: 0;
    outline: none
}

.search-box-banner .group-img {
    position: relative
}

.search-box-banner .group-img i {
    position: absolute;
    top: 15px;
    left: 15px
}

@media(max-width:991.98px) {
    .search-box-banner .group-img i {
        top: 13px
    }
}

.search-box-banner .group-img input {
    padding: 13px 13px 13px 36px
}

@media(max-width:991.98px) {
    .search-box-banner .group-img input {
        padding: 10px 10px 10px 35px
    }
}

.search-box-banner .input-block {
    margin: 0;
    padding: 0 10px
}

.search-box-banner .input-block label {
    margin: 0 0 10px;
    font-weight: 700;
    font-size: 15px;
    color: #2f2f2f
}

@media(max-width:991.98px) {
    .search-box-banner .input-block label {
        font-size: 14px
    }
}

.search-box-banner .search-btn .search-button {
    background-color: #5fff33;
    border: 1px solid #33ff4b;
    box-shadow: inset 0 0 0 #fff;
    color: #fff;
    width: 100%;
    padding: 10px 12px
}

.search-box-banner .search-btn .search-button i {
    margin-right: 8px
}

@media(max-width:767.98px) {
    .search-box-banner .search-btn .search-button {
        width: 100%
    }
}

@media(max-width:991.98px) {
    .search-box-banner .search-btn .search-button {
        padding: 8px 12px
    }
}

.search-box-banner .search-btn .search-button:hover {
    background-color: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff;
    color: #201f1d
}

.search-box-banner .column-group-last {
    -ms-flex: 13%;
    flex: 13%;
    max-width: 13%
}

@media(max-width:991.98px) {
    .search-box-banner .column-group-last {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%
    }
}

@media(max-width:767.98px) {
    .search-box-banner .column-group-last {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%
    }
}

.search-box-banner .column-group-last:last-child {
    padding-right: 0
}

.search-box-banner .column-group-last .search-btn {
    margin-top: 30px
}

@media(max-width:991.98px) {
    .search-box-banner .column-group-last .search-btn {
        margin: 15px 0 0
    }
}

@media(max-width:767.98px) {
    .search-box-banner .column-group-last .search-btn {
        margin: 0
    }
}

.search-box-banner .column-group-main {
    -ms-flex: 29%;
    flex: 29%;
    max-width: 29%
}

@media(max-width:991.98px) {
    .search-box-banner .column-group-main {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
        margin-bottom: 15px
    }
}

@media(max-width:767.98px) {
    .search-box-banner .column-group-main {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
        margin-bottom: 0
    }
}

.search-box-banner .column-group-main:last-child {
    padding-right: 0
}

@media(max-width:767.98px) {
    .search-box-banner .input-block-wrapp {
        display: block
    }
}

.section-heading {
    text-align: center;
    margin: 0 0 50px
}

@media(max-width:991.98px) {
    .section-heading {
        margin: 0 0 30px
    }
}

.section-heading h2 {
    position: relative;
    margin: 0 0 30px
}

@media(max-width:991.98px) {
    .section-heading h2 {
        margin: 0 0 20px
    }
}

.section-heading h2:after {
    content: "";
    position: absolute;
    background-image: url(../img/bg/title-head.png);
    background-position: center;
    background-repeat: no-repeat;
    left: 0;
    height: 11px;
    right: 0;
    bottom: -22px;
    z-index: 9
}

@media(max-width:991.98px) {
    .section-heading h2:after {
        bottom: -17px
    }
}

.section-heading p {
    max-width: 439px;
    margin: auto;
    font-size: 16px
}

@media(max-width:991.98px) {
    .section-heading p {
        font-size: 16px
    }
}

.services {
    padding: 80px 0 56px
}

@media(max-width:991.98px) {
    .services {
        padding: 50px 0 36px
    }
}

.services .service-right {
    position: absolute;
    top: 0;
    right: 0
}

@media(max-width:991.98px) {
    .services .service-right {
        display: none
    }
}

.services .services-group {
    margin: 0 0 24px;
    text-align: center;
    position: relative
}

.services .services-group .services-icon {
    border-radius: 50px;
    margin: 0 0 35px;
    padding: 10px;
    background: #fff;
    position: relative;
    z-index: 9
}

@media(max-width:991.98px) {
    .services .services-group .services-icon {
        padding: 8px
    }
}

.services .services-group .services-icon .icon-img {
    border-radius: 50px;
    width: 72px;
    height: 72px;
    padding: 10px
}

@media(max-width:991.98px) {
    .services .services-group .services-icon .icon-img {
        padding: 8px
    }
}

.services .services-group .services-content h3 {
    margin: 0 0 10px
}

.services .services-one {
    border-bottom: 2px solid #127384
}

@media(max-width:767.98px) {
    .services .services-one {
        border-bottom: 0
    }
}

.services .services-two {
    border-bottom: 2px solid #ff9307
}

@media(max-width:767.98px) {
    .services .services-two {
        border-bottom: 0
    }
}

.services .services-three {
    border-bottom: 2px solid #201f1d
}

@media(max-width:767.98px) {
    .services .services-three {
        border-bottom: 0
    }
}

.services .services-three:before {
    border: 0;
    width: 0
}

.popular-services {
    background: #f2f7f6
}

.popular-services .listing-tabs-group {
    margin: 0 0 50px
}

@media(max-width:991.98px) {
    .popular-services .listing-tabs-group {
        margin: 0 0 30px
    }
}

.popular-services .listing-tabs-group ul {
    display: flex;
    align-items: center;
    justify-content: center
}

@media(max-width:767.98px) {
    .popular-services .listing-tabs-group ul {
        display: flex;
        align-items: center;
        justify-content: center
    }

    .popular-services .listing-tabs-group ul li a {
        min-width: 120px
    }
}

.popular-services .listing-tabs-group ul li a {
    background: #fff;
    border: 1px solid #f4f4f4;
    padding: 10px 23px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    color: #2f2f2f;
    border-radius: 6px
}

@media(max-width:991.98px) {
    .popular-services .listing-tabs-group ul li a {
        padding: 8px 18px;
        font-size: 14px;
        min-width: 120px
    }
}

.popular-services .listing-tabs-group ul li a.active {
    background: #127384;
    color: #fff
}

.popular-services .listing-tabs-group ul li a:hover {
    background: #127384;
    color: #fff
}

.popular-services .listing-tabs-group ul li a span {
    margin: 0 10px 0 0;
    padding: 10px;
    line-height: 0;
    background: #fcfcfc;
    border: 1px solid #f2f7f6;
    border-radius: 6px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center
}

@media(max-width:991.98px) {
    .popular-services .listing-tabs-group ul li a span {
        padding: 8px;
        width: 30px;
        height: 30px
    }
}

.popular-services .owl-carousel .owl-dots.disabled {
    display: block
}

.popular-services .owl-carousel .owl-nav.disabled {
    display: block
}

.popular-services .owl-carousel .owl-prev {
    position: absolute;
    top: 50%;
    left: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-services .owl-carousel .owl-prev:hover i {
    background: #ffa633 !important;
    color: #fff;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-services .owl-carousel .owl-prev i {
    height: 55px;
    width: 55px;
    background: #fff !important;
    color: #2f2f2f;
    margin: auto 0;
    border-radius: 50%;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-services .owl-carousel .owl-next {
    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(50%, -50%);
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-services .owl-carousel .owl-next:hover i {
    background: #ffa633 !important;
    color: #fff;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-services .owl-carousel .owl-next i {
    height: 55px;
    width: 55px;
    background: #fff !important;
    color: #2f2f2f;
    margin: auto 0;
    border-radius: 50%;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-explore {
    padding: 80px 0 56px
}

@media(max-width:991.98px) {
    .popular-explore {
        padding: 50px 0 26px
    }
}

.listing-item {
    position: relative;
    background: #fff;
    border-radius: 10px;
    margin: 0 0 24px;
    padding: 15px
}

@media(max-width:991.98px) {
    .listing-item {
        padding: 15px
    }
}

.listing-item .listing-img {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 0 10px
}

.listing-item .listing-img img {
    border-radius: 10px;
    width: 100%;
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms
}

.listing-item .listing-img .fav-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 15px;
    z-index: 1;
    font-size: 14px
}

@media(max-width:991.98px) {
    .listing-item .listing-img .fav-item {
        font-size: 13px
    }
}

.listing-item .listing-img .fav-item .featured-text {
    background: #fff;
    border-radius: 5px;
    min-width: 75px;
    padding: 5px 15px;
    font-size: 14px;
    color: #737373
}

@media(max-width:991.98px) {
    .listing-item .listing-img .fav-item .featured-text {
        font-size: 12px
    }
}

.listing-item .listing-img .fav-item .fav-icon {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    color: #fff;
    border: 1px solid #fff
}

.listing-item .listing-img .fav-icon.selected {
    background-color: #ffa633;
    border: 1px solid #ffa633 !important;
    color: #fff
}

.listing-item:hover .listing-img img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15)
}

.listing-item:hover .listing-title a {
    color: #127384 !important
}

.listing-item .listing-content {
    margin-top: 15px
}

.listing-item .listing-content .listing-features {
    position: relative;
    border-bottom: 1px solid #f4f4f4;
    margin: 0 0 15px;
    padding: 0 0 15px
}

.listing-item .listing-content .listing-features .author-img {
    position: absolute;
    top: -35px;
    z-index: 2;
    right: 15px;
    border-radius: 50px
}

.listing-item .listing-content .listing-features .author-img img {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    border: 2px solid #fff
}

.listing-item .listing-content .listing-features .listing-title {
    margin-bottom: 5px;
    font-size: 20px
}

.listing-item .listing-content .listing-features .listing-title a {
    font-weight: 600;
    color: #201f1d
}

@media(max-width:991.98px) {
    .listing-item .listing-content .listing-features .listing-title a {
        font-size: 18px
    }
}

.listing-item .listing-content .listing-features h6 {
    font-size: 15px;
    color: #787878;
    font-weight: 400
}

.listing-item .listing-content .listing-features .list-rating i {
    color: #ff9307;
    font-size: 14px
}

@media(max-width:991.98px) {
    .listing-item .listing-content .listing-features .list-rating i {
        font-size: 12px
    }
}

.listing-item .listing-content .listing-features .list-rating span {
    font-weight: 500;
    color: #737373;
    margin: 0 0 0 8px
}

.listing-item .listing-content .listing-details-group {
    margin: 0 0 15px
}

.listing-item .listing-content .listing-details-group ul {
    margin: 0 0 10px
}

.listing-item .listing-content .listing-details-group ul:last-child {
    margin: 0
}

.listing-item .listing-content .listing-details-group ul li {
    -ms-flex: 33.33%;
    flex: 33.33%;
    max-width: 33.33%
}

.listing-item .listing-content .listing-details-group ul li span img {
    line-height: 0;
    width: 16px;
    display: flex;
    justify-content: center
}

.listing-item .listing-content .listing-details-group ul li p {
    color: #787878;
    margin: 0 0 0 8px
}

@media(max-width:991.98px) {
    .listing-item .listing-content .listing-details-group ul li p {
        font-size: 13px
    }
}

.listing-item .listing-content .listing-location-details {
    background: #f2f7f6;
    padding: 8px;
    margin: 0 0 15px
}

@media(max-width:991.98px) {
    .listing-item .listing-content .listing-location-details .listing-price {
        font-size: 13px
    }
}

.listing-item .listing-content .listing-location-details .listing-price span {
    margin-right: 7px;
    line-height: 0
}

.listing-item .listing-content .listing-location-details .listing-price h6 {
    font-weight: 700;
    font-size: 24px;
    color: red
}

@media(max-width:991.98px) {
    .listing-item .listing-content .listing-location-details .listing-price h6 {
        font-size: 20px
    }
}

.listing-item .listing-content .listing-location-details .listing-price h6 span {
    color: #828282;
    font-size: 14px;
    font-weight: 400;
    margin-left: 7px
}

@media(max-width:991.98px) {
    .listing-item .listing-content .listing-location-details .listing-price h6 span {
        font-size: 12px
    }
}

.listing-item .listing-content .listing-button .btn-order {
    background: #201f1d;
    position: relative;
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    text-align: center;
    padding: 10px;
    width: 100%
}

@media(max-width:991.98px) {
    .listing-item .listing-content .listing-button .btn-order {
        font-size: 14px
    }
}

.listing-item .listing-content .listing-button .btn-order span {
    line-height: 0
}

.listing-item .listing-content .listing-button .btn-order span i {
    line-height: 0
}

.listing-item:hover {
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.listing-item:hover .btn-order {
    background-color: #127384 !important;
    color: #fff;
    border-radius: 5px
}

.listing-item:hover .fav-icon {
    background: #127384;
    border: 1px solid #127384 !important;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.listing-item:hover .fav-icon i {
    color: #fff
}

.popular-slider-group {
    margin: 0 0 50px
}

.popular-slider-group .listing-owl-item .listing-owl-group {
    padding: 30px;
    background: #fff;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    text-align: center
}

.popular-slider-group .listing-owl-item .listing-owl-group .listing-owl-img {
    margin: 0 0 20px
}

.popular-slider-group .listing-owl-item .listing-owl-group .listing-owl-img img {
    width: 103px;
    height: 48px;
    text-align: center;
    margin: auto;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-slider-group .listing-owl-item .listing-owl-group h6 {
    margin: 0 0 5px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-slider-group .listing-owl-item .listing-owl-group p {
    font-size: 14px;
    color: #737373;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-slider-group .listing-owl-item .listing-owl-group:hover {
    background-color: #127384;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-slider-group .listing-owl-item .listing-owl-group:hover .listing-owl-img img {
    filter: invert(1)
}

.popular-slider-group .listing-owl-item .listing-owl-group:hover h6 {
    color: #fff
}

.popular-slider-group .listing-owl-item .listing-owl-group:hover p {
    color: #fff
}

.popular-slider-group .owl-carousel .owl-dots.disabled {
    display: block
}

.popular-slider-group .owl-carousel .owl-nav.disabled {
    display: block
}

.popular-slider-group .owl-carousel .owl-prev {
    position: absolute;
    top: 50%;
    left: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all .7s;
    -moz-transition: all .7s;
    -o-transition: all .7s;
    transition: all .7s
}

@media(max-width:1199.98px) {
    .popular-slider-group .owl-carousel .owl-prev {
        left: 7px
    }
}

.popular-slider-group .owl-carousel .owl-prev:hover i {
    background: #ffa633 !important;
    color: #fff;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-slider-group .owl-carousel .owl-prev i {
    height: 55px;
    width: 55px;
    background: #fff !important;
    margin: auto 0;
    border-radius: 50%;
    -webkit-transition: all .7s;
    -moz-transition: all .7s;
    -o-transition: all .7s;
    transition: all .7s
}

@media(max-width:1199.98px) {
    .popular-slider-group .owl-carousel .owl-prev i {
        height: 35px;
        width: 35px;
        font-size: 12px
    }
}

.popular-slider-group .owl-carousel .owl-next {
    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(50%, -50%);
    -webkit-transition: all .7s;
    -moz-transition: all .7s;
    -o-transition: all .7s;
    transition: all .7s
}

@media(max-width:1199.98px) {
    .popular-slider-group .owl-carousel .owl-next {
        right: 7px
    }
}

.popular-slider-group .owl-carousel .owl-next:hover i {
    background: #ffa633 !important;
    color: #fff;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.popular-slider-group .owl-carousel .owl-next i {
    height: 55px;
    width: 55px;
    background: #fff !important;
    margin: auto 0;
    border-radius: 50%;
    -webkit-transition: all .7s;
    -moz-transition: all .7s;
    -o-transition: all .7s;
    transition: all .7s
}

@media(max-width:1199.98px) {
    .popular-slider-group .owl-carousel .owl-next i {
        height: 35px;
        width: 35px;
        font-size: 12px
    }
}

.facts-number {
    background: #201f1d
}

@media(max-width:991.98px) {
    .facts-number {
        padding-bottom: 35px
    }
}

.facts-number .facts-left img {
    position: absolute;
    left: -80px;
    top: 10px
}

@media(max-width:991.98px) {
    .facts-number .facts-left img {
        display: none
    }
}

.facts-number .facts-right img {
    position: absolute;
    right: 60px;
    top: 30px
}

@media(max-width:991.98px) {
    .facts-number .facts-right img {
        display: none
    }
}

.facts-number .count-group {
    position: relative;
    width: 100%;
    display: flex;
    top: 0;
    background-image: url(../img/bg/count-bg.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    border-radius: 10px;
    padding: 20px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:991.98px) {
    .facts-number .count-group {
        margin: 0 0 15px;
        padding: 15px
    }
}

.facts-number .count-group:hover {
    top: -10px
}

.facts-number .count-group:hover .count-img {
    background: #127384
}

.facts-number .count-group .count-img {
    padding: 24px;
    background: #201f1d;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin: 0 20px 0 0;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:991.98px) {
    .facts-number .count-group .count-img {
        padding: 15px
    }
}

.facts-number .count-group .count-content h4 {
    font-weight: 900;
    margin: 0 0 7px
}

.rental-car-item .fav-item-rental {
    position: absolute;
    top: -25px;
    z-index: 2;
    right: 15px;
    border-radius: 50px
}

.rental-car-item .fav-item-rental .featured-text {
    background: #ffa633;
    border-radius: 3px;
    padding: 6px 10px;
    color: #fff;
    font-weight: 500;
    font-size: 15px
}

@media(max-width:991.98px) {
    .rental-car-item .fav-item-rental .featured-text {
        font-size: 14px
    }
}

.rental-car-item .listing-title {
    margin: 5px 0 0
}

.rental-car-item .listing-title a {
    font-weight: 700;
    color: #201f1d
}

.rental-car-item .listing-title h6 {
    font-size: 16px;
    color: #2f2f2f;
    margin: 5px 0 0
}

@media(max-width:991.98px) {
    .rental-car-item .listing-title h6 {
        font-size: 15px
    }
}

.rental-car-item .listing-title h6 span {
    color: #2f2f2f;
    font-weight: 400
}

.why-choose .choose-left {
    position: absolute;
    left: 0;
    top: 10px
}

@media(max-width:991.98px) {
    .why-choose .choose-left {
        display: none
    }
}

.why-choose .card {
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.why-choose .card .card-body .choose-img {
    margin: 0 0 20px;
    padding: 20px;
    border-radius: 10px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:991.98px) {
    .why-choose .card .card-body .choose-img {
        padding: 15px
    }
}

.why-choose .card .card-body .choose-img img {
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.why-choose .card .card-body .choose-black {
    background: #201f1d
}

.why-choose .card .card-body .choose-secondary {
    background: #127384
}

.why-choose .card .card-body .choose-primary {
    background: #ffa633
}

.why-choose .card .card-body .choose-content {
    text-align: center
}

.why-choose .card .card-body .choose-content h4 {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 20px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:991.98px) {
    .why-choose .card .card-body .choose-content h4 {
        font-size: 22px;
        margin: 0 0 15px
    }
}

.why-choose .card .card-body .choose-content p {
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease;
    max-width: 265px;
    margin: auto
}

.why-choose .card:hover {
    background: #000
}

.why-choose .card:hover .choose-black {
    background: #fff
}

.why-choose .card:hover .choose-secondary {
    background: #fff
}

.why-choose .card:hover .choose-primary {
    background: #fff
}

.why-choose .card:hover .choose-content h4 {
    color: #fff
}

.why-choose .card:hover .choose-content p {
    color: #fff
}

.why-choose .card:hover .choose-img {
    position: relative;
    background-image: url(../img/bg/count-bg.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover
}

.why-choose .card:hover .choose-img img {
    filter: invert(1)
}

.about-testimonial {
    /* background-image: url(../img/bg/about-testimonial.jpg); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover
}

@media(max-width:991.98px) {
    .about-testimonial {
        background: #201f1d
    }
}

.about-testimonial .owl-dots {
    text-align: center
}

.about-testimonial .owl-dots .owl-dot {
    margin: 0 8px 0 0
}

.about-testimonial .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    margin: 0;
    background: #dbdbdb;
    border-radius: 50%;
    display: block;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease
}

.about-testimonial .owl-dots .owl-dot.active span {
    background: #ffa633;
    width: 46px;
    height: 8px;
    border-radius: 40px
}

.user-app-group {
    background: #201f1d;
    position: relative;
    border-radius: 10px;
    margin: 56px 0 0;
    padding: 80px
}

@media(max-width:991.98px) {
    .user-app-group {
        margin: 26px 0 0;
        padding: 40px 40px 25px
    }
}

.user-app-group .userapp-heading h2 {
    font-weight: 900;
    font-size: 52px;
    color: #fff;
    margin: 0 0 15px
}

@media(max-width:991.98px) {
    .user-app-group .userapp-heading h2 {
        font-size: 32px
    }
}

.user-app-group .userapp-heading p {
    font-size: 18px;
    color: #fff;
    margin: 0 0 37px
}

@media(max-width:991.98px) {
    .user-app-group .userapp-heading p {
        font-size: 16px;
        margin: 0 0 27px
    }
}

@media(max-width:767.98px) {
    .user-app-group .userapp-heading .download-btn {
        display: block
    }
}

.user-app-group .userapp-heading .download-btn .app-avilable {
    margin: 0 7px 0 0
}

@media(max-width:991.98px) {
    .user-app-group .userapp-heading .download-btn .app-avilable {
        margin: 0 7px 15px 0
    }
}

.user-app-group .app-left {
    position: absolute;
    left: 5%;
    bottom: 5%
}

@media(max-width:991.98px) {
    .user-app-group .app-left {
        display: none
    }
}

.user-app-group .app-right {
    position: absolute;
    right: 30%;
    top: 10%
}

@media(max-width:991.98px) {
    .user-app-group .app-right {
        display: none
    }
}

.bg-secondary {
    background: #127384 !important
}

.border-secondary {
    border: 2px dashed #127384
}

.bg-warning {
    background: #ff9307 !important
}

.border-warning {
    border: 2px dashed #ff9307
}

.bg-dark {
    background: #201f1d
}

.border-dark {
    border: 2px dashed #201f1d
}

.bootstrap-datetimepicker-widget table {
    width: 100%
}

.bootstrap-datetimepicker-widget table .date-widge td {
    padding: 20px;
    width: 100%
}

.bootstrap-datetimepicker-widget table td.active {
    background-color: #ffa633;
    text-shadow: unset
}

.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #ffa633;
    text-shadow: unset
}

.bootstrap-datetimepicker-widget table th {
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
    width: 100%
}

.bootstrap-datetimepicker-widget table td.day {
    font-size: 14px;
    font-weight: 400;
    padding: 10px
}

.bootstrap-datetimepicker-widget .timepicker-hour table td {
    padding: 20px;
    width: 100%
}

.bootstrap-datetimepicker-widget .timepicker-hour table th {
    font-size: 14px;
    font-weight: 400;
    padding: 12px;
    width: 100%
}

.bootstrap-datetimepicker-widget .timepicker-minute table td {
    padding: 20px;
    width: 100%
}

.bootstrap-datetimepicker-widget .timepicker-minute table th {
    font-size: 14px;
    font-weight: 400;
    padding: 15px;
    width: 100%
}

.login-body {
    margin: auto
}

.login-body .log-header {
    background: #fff;
    padding: 22px;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25)
}

.logo-dark {
    margin: auto;
    display: block
}

.login-wrapper {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    margin: 0;
    padding: 100px 0;
    background: #fcfbfb
}

@media(max-width:991.98px) {
    .login-wrapper {
        margin: 0;
        padding: 50px 0
    }
}

@media(max-width:575.98px) {
    .login-wrapper {
        margin: 0;
        padding: 0
    }
}

.login-wrapper .loginbox {
    background-color: #fff;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    display: flex;
    margin: auto;
    max-width: 635px;
    width: 100%;
    border-radius: 5px
}

.login-wrapper .loginbox .login-left {
    align-items: center;
    background: linear-gradient(180deg, #8BC34A, #00bcd4);
    flex-direction: column;
    padding: 80px;
    width: 635px;
    display: flex;
    border-radius: 6px 0 0 6px
}

.login-wrapper .loginbox .login-auth {
    padding: 20px;
    width: 635px
}

@media(max-width:320px) {
    .login-wrapper .loginbox .login-auth {
        width: 100%
    }
}

.login-wrapper .loginbox .login-auth .login-auth-wrap {
    max-width: 100%;
    flex: 0 0 100%
}

.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group span i {
    line-height: 0;
    margin-right: 6px
}

.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group a {
    font-size: 14px;
    margin: 0 0 40px;
    padding: 0
}

.login-wrapper .loginbox .login-auth .login-auth-wrap .sign-group a:hover {
    color: #ffa633
}

.login-wrapper .loginbox .login-auth .login-auth-wrap .input-block label {
    margin: 0 0 10px
}

.login-wrapper .loginbox .login-auth .login-auth-wrap h1 {
    margin: 0 0 10px;
    font-size: 28px;
    font-weight: 700
}

.login-wrapper .loginbox .login-auth .forgotpass a {
    color: #828282
}

.login-wrapper .loginbox .login-auth .forgotpass a:hover {
    color: #127384;
    text-decoration: underline
}

.login-wrapper .loginbox .login-auth .dont-have {
    color: #828282;
    margin-top: 30px
}

.login-wrapper .loginbox .login-auth .dont-have a {
    color: #127384
}

.login-wrapper .loginbox .login-auth .dont-have a:hover {
    text-decoration: underline
}

.login-wrapper .loginbox .login-auth .social-login {
    text-align: center
}

.login-wrapper .loginbox .login-auth .social-login span {
    color: #828282;
    margin-right: 8px;
    display: inline-flex
}

.login-wrapper .loginbox .login-auth .social-login a {
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    box-shadow: inset 0 0 0 0 #fff;
    text-align: center;
    margin: 0 0 15px;
    padding: 10px 0;
    font-size: 16px;
    line-height: 20px;
    color: #28283c;
    border-radius: 4px
}

.login-wrapper .loginbox .login-auth .social-login a:hover {
    background-color: #fff;
    border-color: #f1f1f1;
    color: #3f4254;
    box-shadow: inset 0 50px 0 0 #fff
}

.login-wrapper .loginbox .login-auth .social-login a.facebook {
    background-color: #4b75bd
}

.login-wrapper .loginbox .login-auth .social-login a.google {
    background-color: #fe5240
}

.login-wrapper .loginbox .account-subtitle {
    color: #828282;
    margin: 0 0 30px
}

.login-wrapper .loginbox .login-or {
    color: #828282;
    margin: 20px auto;
    padding: 20px 0;
    position: relative;
    max-width: 385px
}

.login-wrapper .loginbox .or-line {
    background-color: #edecf8;
    height: 1px;
    margin-bottom: 0;
    margin-top: 0;
    display: block
}

@media(max-width:575.98px) {
    .login-wrapper .loginbox .or-line {
        display: none
    }
}

.login-wrapper .loginbox .span-or {
    background-color: #fff;
    display: block;
    left: 15%;
    position: absolute;
    text-align: center;
    top: 8px;
    width: 280px
}

@media(max-width:575.98px) {
    .login-wrapper .loginbox .span-or {
        left: 0
    }
}

.login-wrapper .loginbox .span-or-log {
    background-color: #fff;
    color: #828282;
    display: block;
    left: 25%;
    position: absolute;
    text-align: center;
    top: 6px;
    width: 200px
}

@media(max-width:575.98px) {
    .login-wrapper .loginbox .span-or-log {
        left: 0
    }
}

.login-wrapper .loginbox .lock-user {
    margin-bottom: 20px;
    text-align: center
}

.login-wrapper .loginbox .lock-user img {
    margin-bottom: 15px;
    width: 100px
}

.login-wrapper .loginbox .toggle-password,
.login-wrapper .loginbox .toggle-password-two {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
    color: #828282;
    cursor: pointer
}

.login-wrapper .loginbox .toggle-password.fa-eye,
.login-wrapper .loginbox .toggle-password-two.fa-eye {
    margin-right: 1px;
    font-weight: 500
}

.log-footer {
    background: #fcfbfb
}

.log-footer .copyright {
    border-top: 0;
    padding: 28px 0;
    text-align: center
}

.log-footer .copyright .copyright-text p {
    font-size: 14px;
    color: #2f2f2f
}

@media(max-width:1199.98px) {

    .container,
    .container-sm {
        max-width: 100%
    }
}

.page-wrapper {
    margin-left: 247px;
    padding-top: 77px;
    position: relative;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

@media(max-width:1199.98px) {
    .page-wrapper {
        margin-left: 200px
    }
}

.page-wrapper .content {
    padding: 25px
}

@media(max-width:1199.98px) {
    .page-wrapper .content {
        padding: 20px
    }
}

@media(max-width:991.98px) {
    .page-wrapper .content {
        padding: 15px
    }
}

@media(max-width:991.98px) {
    .page-wrapper {
        margin: 0;
        padding-top: 60px
    }
}

.content-page-header {
    align-items: center;
    -webkit-align-items: center;
    margin: 0 0 30px
}

@media(max-width:575.98px) {
    .content-page-header {
        display: block
    }
}

@media(max-width:768px) {
    .content-page-header {
        margin: 0 0 30px
    }
}

.content-page-header h5 {
    font-size: 24px;
    color: #201f1d;
    font-weight: 600;
    margin: 0
}

@media(max-width:768px) {
    .content-page-header h5 {
        font-size: 18px
    }
}

.content-page-header h6 {
    font-size: 20px;
    color: #201f1d;
    font-weight: 600;
    margin: 0
}

.card {
    background: #fff;
    -webkit-box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
    margin: 0 0 24px;
    display: inline-block;
    width: 100%;
    border: 0;
    border-radius: 8px
}

.card .card-header {
    border-color: #f0f1f5;
    background-color: #fff;
    padding: 1.5rem
}

.card .card-header:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px
}

@media(max-width:1199.98px) {
    .card .card-header {
        padding: 15px
    }
}

.card .card-body {
    position: relative;
    padding: 1.5rem
}

@media(max-width:1199.98px) {
    .card .card-body {
        padding: 15px
    }
}

.card .card {
    position: relative;
    padding: 1.5rem
}

@media(max-width:1199.98px) {
    .card .card {
        padding: 15px
    }
}

.text-success-light {
    color: #1fbc2f !important
}

.text-warning-light {
    color: #ffa633
}

.warning-border {
    border: 1px solid #ffa633;
    padding: 0 3px;
    border-radius: 50px
}

.danger-border {
    border: 1px solid red;
    padding: 0 3px;
    border-radius: 50px
}

.info-border {
    border: 1px solid #377dff;
    padding: 0 3px;
    border-radius: 50px
}

.text-primary {
    color: #ffa633 !important
}

.text-danger {
    color: red !important
}

.text-gray-light {
    color: #201f1d
}

.text-gray-dark {
    color: #3f4254
}

.text-muted {
    color: #f2f7f6 !important
}

.bg-light-gray {
    background: #f3f3f3
}

.bg-primary-light {
    background: #eaddff
}

.bg-green-light {
    background: #e1ffed
}

.bg-danger-light {
    background: #ffecec
}

.bg-white-smoke {
    background: #efefef
}

.bg-light-primary {
    background: #f2f7f6 !important
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: 0;
    border-bottom: 0;
    border-left: 0
}

.dropdown-item {
    font-size: 12px;
    padding: 8px 10px
}

.add-cate-status {
    margin: 50px 0 0 !important
}

@media(max-width:575.98px) {
    .add-cate-status {
        margin: 0 !important
    }
}

.input-block {
    margin: 0 0 15px
}

.input-block .forgot-link {
    color: red;
    font-size: 14px
}

.input-block label {
    margin: 0 0 10px;
    font-weight: 600;
    color: #2f2f2f
}

@media(max-width:1199.98px) {
    .input-block label {
        font-size: 14px
    }
}

.input-block .optional {
    font-weight: 400;
    font-size: 12px;
    color: #201f1d
}

.input-block .form-control {
    background: #fcfbfb;
    border: 1px solid #f4f4f4;
    box-shadow: none;
    border-radius: 5px;
    margin: 0;
    padding: 13px;
    font-size: 14px;
    font-weight: 400;
    color: #201f1d
}

@media(max-width:767.98px) {
    .input-block .form-control {
        margin: 0 0 15px;
        padding: 10px
    }
}

.input-block .form-control ::placeholder,
.input-block .form-control ::-webkit-input-placeholder,
.input-block .form-control :-ms-input-placeholder {
    color: #201f1d;
    font-size: 14px;
    font-weight: 500
}

.input-block .form-control:focus {
    background: #fff
}

.input-block .form-control .form-title {
    font-weight: 600;
    font-size: 16px;
    color: #201f1d;
    margin: 0 0 25px
}

.input-block .search-btn .check-available {
    padding: 12px 20px
}

.input-block textarea.form-control {
    height: auto
}

.input-block .intl-tel-input,
.input-block .iti {
    width: 100%
}

.input-block .note-toolbar {
    background: #fff
}

.input-block .note-editable {
    background: #fff
}

.input-block .note-resizebar {
    height: 0 !important
}

.input-block .toggle-password {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, 50%)
}

.input-block .feather-eye-off {
    color: #201f1d
}

.input-block .feather-eye {
    margin-right: 1px;
    color: #201f1d
}

.custom_check {
    position: relative;
    padding-left: 30px
}

.custom_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    border: 1px solid #828282;
    background-color: #fff;
    border-radius: 2px;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "font awesome 5 free";
    font-weight: 700;
    position: absolute;
    display: none;
    left: 2px;
    top: 2px;
    color: #828282;
    font-size: 12px
}

.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.custom_check input:checked~.checkmark {
    background-color: #fff
}

.custom_check input:checked~.checkmark:after {
    display: block
}

.custom_check span {
    color: #828282;
    font-weight: 400
}

.pass-group {
    position: relative
}

.close {
    background: 0 0;
    border: 0;
    color: #28283c;
    font-size: 28px;
    line-height: normal;
    top: 20px;
    width: auto;
    height: auto;
    right: 20px
}

.close span {
    background: #888;
    border-radius: 50px;
    font-size: 20px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -webkit-transition: all .4s ease
}

.close span:hover {
    background: #ffa633;
    color: #fff;
    transition: all .4s ease;
    -moz-transition: all .4s ease;
    -o-transition: all .4s ease;
    -ms-transition: all .4s ease;
    -webkit-transition: all .4s ease
}

.card-title {
    margin-bottom: 0;
    border-radius: 10px
}

.cal-icon {
    position: relative;
    width: 100%
}

.cal-icon:after {
    color: #979797;
    content: "\f073";
    display: block;
    font-family: "font awesome 5 free";
    font-weight: 700;
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px
}

.cal-icon-info:after {
    font-family: feather;
    content: "\e926";
    color: #201f1d;
    font-weight: 400;
    font-size: 18px;
    top: 8px
}

.progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0)
}

.progress-wrap {
    position: fixed;
    right: 12px;
    bottom: 25px;
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: inset 0 0 0 2px rgba(75, 64, 237, .2);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    transform: translateY(15px);
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px)
}

.progress-wrap::after {
    position: absolute;
    content: "\f062";
    font-family: fontawesome;
    text-align: center;
    line-height: 35px;
    font-size: 17px;
    color: #fff;
    left: 0;
    top: 0;
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear
}

.pagination .pagination {
    flex-wrap: wrap
}

@media(max-width:479px) {

    .pagination .pagination .previtem,
    .pagination .pagination .nextlink {
        margin-bottom: 10px
    }
}

.pagination .pagination .previtem a,
.pagination .pagination .nextlink a {
    height: 38px;
    border-radius: 10px;
    padding: 0 20px;
    color: #2f2f2f
}

.pagination .pagination .previtem a:focus,
.pagination .pagination .nextlink a:focus {
    outline: 0;
    box-shadow: none
}

@media(max-width:479px) {
    .pagination .pagination li .page-group ul {
        flex-wrap: wrap
    }
}

@media(max-width:479px) {
    .pagination .pagination li .page-group ul li {
        margin-bottom: 10px
    }
}

.pagination .pagination-center {
    display: flex;
    justify-content: center;
    align-items: center
}

.pagination .pagination-center .page-group .page-item a {
    border: 1px solid #dee2e7;
    background: #fff;
    border-radius: 10px;
    padding: 10px 19px;
    margin: 0 12px 0 0;
    color: #2f2f2f;
    width: 47px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center
}

.pagination .pagination-center .page-group .page-item a:focus {
    outline: 0;
    box-shadow: none
}

.pagination .pagination-center .page-group .page-item a:hover {
    background-color: #127384;
    color: #fff;
    border: 1px solid #127384
}

.pagination .pagination-center .page-group .page-item .active {
    background-color: #127384;
    color: #fff;
    border: 1px solid #127384
}

.pagination .pagination-center .page-group ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 10px
}

.privacy-section {
    position: relative;
    padding: 80px 0
}

@media(max-width:991.98px) {
    .privacy-section {
        padding: 40px 0
    }
}

.privacy-section .terms-policy p {
    margin: 0 0 38px
}

@media(max-width:991.98px) {
    .privacy-section .terms-policy p {
        margin: 0 0 28px
    }
}

.privacy-section .terms-policy ul {
    margin: 0 0 38px
}

.privacy-section .terms-policy ul li {
    margin: 0 0 11px
}

.privacy-section .terms-policy ul li span {
    margin-right: 10px;
    color: #ffa633
}

.error-page {
    padding: 50px;
    align-items: center;
    color: #28283c;
    font-weight: 900
}

@media(max-width:575.98px) {
    .error-page {
        padding: 50px
    }
}

.error-page .main-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
    min-height: unset
}

.error-box {
    margin: 40px auto;
    max-width: 600px;
    text-align: center;
    width: 100%
}

.error-box img {
    margin: 0 0 50px
}

.error-box h3 {
    font-size: 28px;
    margin-bottom: 10px
}

.error-box p {
    margin: auto auto 30px;
    color: #787878;
    font-weight: 500;
    max-width: 560px
}

.error-box .btn-maintance {
    border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 20px
}

@media(max-width:768px) {
    .error-box .btn-maintance {
        padding: 8px 12px;
        font-size: 14px
    }
}

.error-box .error-500 {
    margin: 0 0 60px
}

.error-box .coming-soon {
    padding: 50px 0 8px;
    color: #28283c
}

@media(max-width:767.98px) {
    .error-box .coming-soon {
        font-size: 20px
    }
}

.error-box .back-button .btn {
    padding: 15px 20px;
    font-size: 16px
}

@media(max-width:575.98px) {
    .error-box .back-button .btn {
        padding: 10px;
        font-size: 14px
    }
}

.serve-form {
    margin: 0 0 39px
}

@media(max-width:767.98px) {
    .serve-form {
        padding-bottom: 30px
    }
}

.serve-form .input-block {
    margin-bottom: 0
}

.serve-form .input-block .form-control {
    margin-right: 10px;
    font-size: 14px;
    background: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.serve-form .input-block .form-control::placeholder {
    color: #2f2f2f;
    font-weight: 500
}

@media(max-width:767.98px) {
    .serve-form .input-block .form-control {
        min-height: 46px;
        font-size: 14px
    }
}

.serve-form .input-block .button-notific {
    min-width: 115px;
    height: 47px;
    color: #fff;
    border-radius: 5px;
    background: #201f1d;
    font-weight: 600;
    font-size: 16px
}

@media(max-width:767.98px) {
    .serve-form .input-block .button-notific {
        min-width: 100px;
        font-size: 14px
    }
}

.serve-form .input-block .button-notific:hover {
    color: #fff;
    background: #3f4254;
    border: 1px solid #3f4254
}

.progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0)
}

.progress-wrap {
    position: fixed;
    right: 12px;
    bottom: 25px;
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: inset 0 0 0 2px rgba(75, 64, 237, .2);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    transform: translateY(15px);
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px)
}

.progress-wrap svg.progress-circle path {
    stroke: #ffa633;
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear
}

.progress-wrap svg path {
    fill: none
}

.progress-wrap::after {
    position: absolute;
    content: "\f062";
    font-family: fontawesome;
    text-align: center;
    line-height: 35px;
    font-size: 17px;
    color: #ffa633;
    left: 0;
    top: 0;
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear
}

iframe {
    width: 100%
}

.custom_radio {
    color: #000;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.custom_radio.active {
    color: #334
}

.radio_input .custom_radio+.custom_radio {
    margin-left: 15px
}

.custom_radio input {
    position: absolute;
    opacity: 0
}

.custom_radio input:checked~.checkmark:after {
    opacity: 1
}

.custom_radio .checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ffa633;
    border-radius: 50%
}

.custom_radio .checkmark:after {
    display: block;
    content: "";
    position: absolute;
    opacity: 0;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffa633;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out
}

.new-modal .modal-dialog {
    margin-top: 60px
}

.new-modal .modal-content {
    padding: 24px
}

@media(max-width:767.98px) {
    .new-modal .modal-content {
        padding: 15px
    }
}

.new-modal .modal-content .modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin: 0 0 15px;
    padding: 0 0 15px;
    border-bottom: 1px solid #f4f4f4
}

.new-modal .modal-content .modal-header .modal-title {
    font-size: 20px;
    font-weight: 500;
    color: #111;
    margin-bottom: 0
}

@media(max-width:767.98px) {
    .new-modal .modal-content .modal-header .modal-title {
        font-size: 18px
    }
}

.new-modal .modal-content .modal-header .close-btn {
    position: absolute;
    top: -40px;
    right: 0;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #737373;
    font-size: 22px;
    height: 34px;
    width: 34px;
    margin: 0;
    opacity: 1;
    padding: 0;
    z-index: 99;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.new-modal .modal-content .modal-header .close-btn:hover {
    color: #fff;
    background-color: red;
    border: 1px solid red;
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.new-modal .modal-content .modal-body {
    padding: 0
}

.faq-section {
    background: #fcfcfc
}

.faq-section .faq-card {
    margin: 0 0 20px;
    padding: 20px 40px 20px 20px;
    border: 1px solid #dbdbdb;
    border-radius: 5px
}

@media(max-width:991.98px) {
    .faq-section .faq-card {
        margin: 0 0 15px
    }
}

.faq-section .faq-card:last-child {
    margin-bottom: 0
}

.faq-section .faq-card p {
    margin: 15px 0 0
}

.faq-section .faq-title {
    font-weight: 500;
    font-size: 18px
}

.faq-section .faq-title>a {
    display: block;
    position: relative;
    color: #201f1d;
    font-size: 18px
}

@media(max-width:991.98px) {
    .faq-section .faq-title>a {
        font-size: 15px
    }
}

.faq-section .faq-title>a:after {
    font-family: fontawesome;
    content: "\f13a";
    position: absolute;
    top: 4px;
    right: -15px;
    font-size: 16px;
    color: #828282
}

@media(max-width:767.98px) {
    .faq-section .faq-title>a:after {
        top: 0;
        right: -20px
    }
}

.faq-section .faq-title>a:not(.collapsed):after {
    content: "\f139";
    color: #ffa633
}

.pricing-section {
    position: relative;
    padding: 80px 0
}

@media(max-width:991.98px) {
    .pricing-section {
        padding: 50px 0 25px
    }
}

.pricing-section .price-card {
    background: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 15px;
    margin: 0 0 24px;
    padding: 20px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-card.active {
    border-color: #ffa633
}

@media(max-width:991.98px) {
    .pricing-section .price-card {
        padding: 15px
    }
}

.pricing-section .price-card .price-head {
    padding: 0 0 28px
}

.pricing-section .price-card .price-head .price-level {
    margin: 0 0 15px;
    padding: 10px;
    background: #fcfcfc;
    border-radius: 10px;
    text-align: center
}

@media(max-width:768px) {
    .pricing-section .price-card .price-head .price-level {
        margin: 0 0 10px;
        padding: 10px
    }
}

.pricing-section .price-card .price-head .price-level h6 {
    margin: 0 0 5px;
    font-weight: 700;
    font-size: 24px;
    color: #201f1d
}

@media(max-width:768px) {
    .pricing-section .price-card .price-head .price-level h6 {
        font-size: 20px
    }
}

.pricing-section .price-card .price-head .price-level-popular {
    background: #ff9307;
    border-radius: 10px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-card .price-head .price-level-popular h6 {
    font-weight: 700;
    margin: 0 0 5px;
    font-size: 20px;
    color: #fff
}

.pricing-section .price-card .price-head .price-level-popular p {
    color: #fff
}

.pricing-section .price-card .price-head h4 {
    font-weight: 900;
    font-size: 36px;
    color: #201f1d;
    margin: 0 0 15px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease;
    text-align: center
}

@media(max-width:767.98px) {
    .pricing-section .price-card .price-head h4 {
        font-size: 26px
    }
}

.pricing-section .price-card .price-head span {
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease;
    display: block;
    text-align: center
}

.pricing-section .price-card .price-details p {
    line-height: 27px;
    color: #737373;
    margin: 0 0 20px
}

.pricing-section .price-card .price-details ul {
    margin: 0 0 20px
}

.pricing-section .price-card .price-details ul li {
    line-height: 18px;
    position: relative;
    margin: 0 0 15px;
    padding: 5px 0 0
}

.pricing-section .price-card .price-details ul .price-check {
    color: #787878;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-card .price-details ul .price-check span {
    margin: 0 10px 0 0;
    color: #1fbc2f;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-card .price-details ul .price-uncheck {
    color: #787878;
    text-decoration: line-through;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-card .price-details ul .price-uncheck span {
    margin: 0 10px 0 0;
    color: red;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-card .price-details .viewdetails-btn {
    display: block;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 5px;
    background: #fff;
    border: 2px solid #201f1d;
    color: #201f1d;
    padding: 7px 12px;
    font-weight: 500;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-card .price-details .btn-popular {
    background: #ffa633 !important;
    color: #fff !important;
    border: 2px solid #ffa633 !important
}

.pricing-section .price-card .price-details .viewdetails-btn-popular {
    background: #ffa633;
    border-radius: 5px;
    display: block;
    color: #fff;
    padding: 18px;
    font-weight: 700;
    font-size: 16px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:991.98px) {
    .pricing-section .price-card .price-details .viewdetails-btn-popular {
        padding: 7px 12px
    }
}

.pricing-section .price-card:hover {
    background: #127384;
    border-color: #127384
}

.pricing-section .price-card:hover .price-head h4 {
    color: #fff
}

.pricing-section .price-card:hover .price-head span {
    color: #fff
}

.pricing-section .price-card:hover .price-details .price-check {
    color: #fff
}

.pricing-section .price-card:hover .price-details .price-uncheck {
    color: #fff
}

.pricing-section .price-card:hover .price-level-popular {
    background: #201f1d
}

.pricing-section .price-card:hover .viewdetails-btn-popular {
    background: #201f1d
}

.pricing-section .price-card:hover .viewdetails-btn {
    border: 2px solid #fff
}

.pricing-section .price-card:hover .btn-popular {
    background: #201f1d !important;
    border: 2px solid #201f1d !important
}

.pricing-section .price-plan-card {
    background: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 15px;
    padding: 20px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:768px) {
    .pricing-section .price-plan-card {
        padding: 10px;
        height: 524px
    }
}

.pricing-section .price-plan-card .price-head {
    border-bottom: 1px solid #f4f4f4;
    padding: 0 0 28px
}

.pricing-section .price-plan-card .price-head .price-level {
    margin: 0 0 15px;
    padding: 16px;
    background: #fcfcfc;
    border-radius: 10px
}

@media(max-width:768px) {
    .pricing-section .price-plan-card .price-head .price-level {
        margin: 0 0 10px;
        padding: 10px
    }
}

.pricing-section .price-plan-card .price-head .price-level h6 {
    margin: 0 0 5px;
    font-weight: 700;
    font-size: 24px;
    color: #201f1d
}

@media(max-width:768px) {
    .pricing-section .price-plan-card .price-head .price-level h6 {
        font-size: 20px
    }
}

.pricing-section .price-plan-card .price-head .price-level-popular {
    background: #ff9307;
    border-radius: 10px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-plan-card .price-head .price-level-popular h6 {
    font-weight: 700;
    margin: 0 0 5px;
    font-size: 24px;
    color: #fff
}

.pricing-section .price-plan-card .price-head .price-level-popular p {
    color: #fff
}

.pricing-section .price-plan-card .price-head h4 {
    font-weight: 900;
    font-size: 36px;
    color: #201f1d;
    margin: 0 0 15px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:768px) {
    .pricing-section .price-plan-card .price-head h4 {
        font-size: 26px
    }
}

.pricing-section .price-plan-card .price-head span {
    font-weight: 500;
    font-size: 14px;
    color: #737373;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-plan-card .price-details {
    padding: 28px 0 0
}

.pricing-section .price-plan-card .price-details p {
    line-height: 27px;
    color: #737373;
    margin: 0 0 20px
}

.pricing-section .price-plan-card .price-details ul {
    margin: 0 0 20px
}

.pricing-section .price-plan-card .price-details ul li {
    line-height: 18px;
    position: relative;
    margin: 0 0 15px;
    padding: 5px 0 0
}

.pricing-section .price-plan-card .price-details ul .price-check {
    font-weight: 500;
    font-size: 14px;
    color: #787878;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-plan-card .price-details ul .price-check span {
    margin: 0 10px;
    color: #1fbc2f;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-plan-card .price-details ul .price-uncheck {
    font-weight: 500;
    font-size: 14px;
    color: #787878;
    text-decoration: line-through;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:768px) {
    .pricing-section .price-plan-card .price-details ul .price-uncheck {
        font-size: 12px
    }
}

.pricing-section .price-plan-card .price-details ul .price-uncheck span {
    margin: 0 10px;
    color: red;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-plan-card .price-details .viewdetails-btn {
    display: block;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 5px;
    background: #fff;
    border: 2px solid #201f1d;
    color: #201f1d;
    padding: 7px 12px;
    font-weight: 700;
    font-size: 16px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-plan-card .price-details .btn-popular {
    background: #ffa633 !important;
    color: #fff !important;
    border: 2px solid #ffa633 !important
}

.pricing-section .price-plan-card .price-details .viewdetails-btn-popular {
    background: #ffa633;
    border-radius: 5px;
    display: block;
    color: #fff;
    padding: 18px;
    font-weight: 700;
    font-size: 16px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.pricing-section .price-plan-card:hover {
    background: #127384;
    border: 1px solid #dbdbdb
}

.pricing-section .price-plan-card:hover .price-head h4 {
    color: #fff
}

.pricing-section .price-plan-card:hover .price-head span {
    color: #fff
}

.pricing-section .price-plan-card:hover .price-details .price-check {
    color: #fff
}

.pricing-section .price-plan-card:hover .price-details .price-uncheck {
    color: #fff
}

.pricing-section .price-plan-card:hover .price-level-popular {
    background: #201f1d
}

.pricing-section .price-plan-card:hover .viewdetails-btn-popular {
    background: #201f1d
}

.pricing-section .price-plan-card:hover .viewdetails-btn {
    border: 2px solid #fff
}

.pricing-section .price-plan-card:hover .btn-popular {
    background: #201f1d !important;
    border: 2px solid #201f1d !important
}

.pricing-section .price-card-popular {
    border: 1px solid #ff9307;
    margin: -10px 0 0
}

@media(max-width:767.98px) {
    .pricing-section .price-card-popular {
        margin: 24px 0
    }
}

@media(max-width:768px) {
    .pricing-section .price-card-popular {
        padding: 10px
    }
}

.pricing-section-bottom {
    padding-bottom: 80px
}

@media(max-width:991.98px) {
    .pricing-section-bottom {
        padding-bottom: 50px
    }
}

.plan-selected {
    margin: 0 0 50px
}

@media(max-width:991.98px) {
    .plan-selected {
        margin: 0 0 30px
    }
}

.plan-selected h4 {
    font-weight: 400;
    font-size: 16px;
    color: #201f1d
}

.plan-selected .status-toggle {
    display: flex;
    align-items: center
}

.plan-selected .status-toggle .checktoggle {
    background-color: #c4c4c4;
    border: 1px solid #c4c4c4;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
    top: 0;
    border-radius: 12px;
    transform: translate(calc(10% - 5px), -10%)
}

.plan-selected .status-toggle .checktoggle:after {
    height: 15px;
    width: 15px
}

.plan-selected .status-toggle .check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute
}

.plan-selected .status-toggle .check:checked+.checktoggle {
    background-color: #1fbc2f;
    border: 1px solid #1fbc2f
}

.plan-selected .status-toggle .check:checked+.checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 5px), -50%);
    height: 15px;
    width: 15px
}

.plan-selected .status-toggle .checktoggle:after {
    content: " ";
    display: block;
    background-color: rgba(0, 0, 0, .25);
    height: 15px;
    width: 15px;
    transform: translate(5px, -50%);
    -webkit-transform: translate(5px, -50%);
    -ms-transform: translate(5px, -50%);
    position: absolute;
    left: 0;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

.plan-selected .status-toggle .checktoggle .checkbox-bg::after {
    background: rgba(0, 0, 0, .25)
}

.price-selected {
    background: #127384 !important;
    border-color: #127384 !important
}

@media(max-width:991.98px) {
    .price-selected {
        height: 555px
    }
}

.price-selected h2 {
    font-weight: 900;
    font-size: 32px;
    color: #fff;
    margin: 0 0 15px
}

@media(max-width:767.98px) {
    .price-selected h2 {
        font-size: 26px
    }
}

.price-selected p {
    color: #fff;
    margin: 0 0 20px
}

.price-selected .plan-view-details {
    color: #fff;
    font-size: 20px
}

.price-selected .price-body {
    position: absolute;
    right: 0;
    bottom: 0
}

.price-selected:hover {
    background: #201f1d !important;
    border-color: #201f1d !important
}

.gallery-section {
    padding: 80px 0 56px
}

@media(max-width:991.98px) {
    .gallery-section {
        padding: 50px 0 26px
    }
}

.gallery-section .gallery-widget {
    position: relative;
    margin: 0 0 25px;
    -webkit-transition: all .7s;
    -moz-transition: all .7s;
    -o-transition: all .7s;
    transition: all .7s
}

.gallery-section .gallery-widget img {
    border-radius: 4px
}

.fancybox-image {
    border-radius: 10px;
    border: 1px solid #fff
}

.our-team-section {
    background: #fcfcfc;
    position: relative;
    padding: 80px 0 55px
}

@media(max-width:991.98px) {
    .our-team-section {
        padding: 60px 0 35px
    }
}

@media(max-width:767.98px) {
    .our-team-section {
        padding: 40px 0 15px
    }
}

.our-team-section .our-team {
    text-align: center;
    border-bottom: 3px solid #fff;
    background: #fff;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 0 10px 10px;
    margin: 0 0 25px;
    transition: all .2s ease-out 0s
}

.our-team-section .our-team .team-prof {
    padding: 30px
}

.our-team-section .our-team .team-prof .team-designation {
    padding: 0 0 30px;
    display: block
}

.our-team-section .our-team .team-prof .team-post-title {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 10px
}

.our-team-section .our-team .profile-pic {
    position: relative;
    overflow: hidden
}

.our-team-section .our-team .profile-pic img {
    width: 100%;
    height: 100%;
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms
}

.our-team-section .our-team:hover {
    border-bottom: 3px solid #ffa633;
    transition: all .2s ease-out 0s
}

.our-team-section .our-team:hover .profile-pic img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15)
}

.contact-section {
    position: relative;
    padding: 80px 0
}

@media(max-width:991.98px) {
    .contact-section {
        padding: 50px 0
    }
}

.contact-section .contact-info-area {
    margin: 0 0 80px
}

@media(max-width:991.98px) {
    .contact-section .contact-info-area {
        margin: 0 0 30px
    }
}

@media(max-width:575.98px) {
    .contact-section .contact-info-area {
        margin: 0 0 15px
    }
}

.contact-section .contact-info-area .single-contact-info {
    text-align: center;
    background: #fff;
    border: 2px solid #f4f4f4;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 10px;
    padding: 20px;
    -webkit-transition: all .5s;
    transition: all .5s
}

@media(max-width:992px) {
    .contact-section .contact-info-area .single-contact-info {
        padding: 15px
    }
}

@media(max-width:991.98px) {
    .contact-section .contact-info-area .single-contact-info {
        margin: 0 0 24px
    }
}

.contact-section .contact-info-area .single-contact-info i {
    color: #fff;
    -webkit-transition: all .5s;
    transition: all .5s;
    font-size: 26px;
    width: 70px;
    height: 70px;
    margin: 0 auto 15px;
    line-height: 50px;
    background: #ff9307;
    border-radius: 50%
}

@media(max-width:992px) {
    .contact-section .contact-info-area .single-contact-info i {
        width: 35px;
        height: 35px;
        font-size: 16px
    }
}

.contact-section .contact-info-area .single-contact-info h3 {
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 10px;
    -webkit-transition: all .5s;
    transition: all .5s
}

@media(max-width:992px) {
    .contact-section .contact-info-area .single-contact-info h3 {
        font-size: 16px
    }
}

.contact-section .contact-info-area .single-contact-info a {
    font-size: 16px;
    color: #737373;
    -webkit-transition: all .5s;
    transition: all .5s
}

@media(max-width:992px) {
    .contact-section .contact-info-area .single-contact-info a {
        font-size: 13px
    }
}

.contact-section .contact-info-area .single-contact-info:hover {
    background: #127384;
    -webkit-transition: all .5s;
    transition: all .5s
}

.contact-section .contact-info-area .single-contact-info:hover i {
    background: #fff;
    -webkit-transition: all .5s;
    transition: all .5s;
    color: #127384
}

.contact-section .contact-info-area .single-contact-info:hover h3 {
    color: #fff;
    -webkit-transition: all .5s;
    transition: all .5s
}

.contact-section .contact-info-area .single-contact-info:hover a {
    color: #fff;
    -webkit-transition: all .5s;
    transition: all .5s
}

.contact-section .form-info-area {
    background: #fff;
    border: 2px solid #f4f4f4;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 10px;
    padding: 24px
}

.contact-section .form-info-area img {
    border-radius: 10px
}

@media(max-width:991.98px) {
    .contact-section .form-info-area img {
        display: none
    }
}

.contact-section .form-info-area h1 {
    font-weight: 500;
    font-size: 38px;
    color: #28283c;
    margin: 0 0 30px
}

@media(max-width:992px) {
    .contact-section .form-info-area h1 {
        font-size: 28px;
        margin: 0 0 15px
    }
}

.contact-section textarea.form-control {
    height: 120px;
    resize: none
}

.invoice-section {
    position: relative;
    padding: 80px 0
}

@media(max-width:991.98px) {
    .invoice-section {
        padding: 60px 0
    }
}

@media(max-width:767.98px) {
    .invoice-section {
        padding: 50px 0
    }
}

.invoice-section .card-body {
    background: #fff;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 14px;
    padding: 40px;
    max-width: 1076px;
    margin: auto
}

@media(max-width:575.98px) {
    .invoice-section .card-body {
        padding: 20px
    }
}

.invoice-section .invoice-item {
    margin: 0 0 22px
}

.invoice-section .invoice-item .invoice-info {
    text-align: right
}

@media(max-width:575.98px) {
    .invoice-section .invoice-item .invoice-info {
        margin: 10px 0 0;
        text-align: left
    }
}

.invoice-section .invoice-item .invoice-info h1 {
    font-weight: 700;
    font-size: 32px;
    color: #2f2f2f;
    margin: 0 0 10px
}

@media(max-width:1199.98px) {
    .invoice-section .invoice-item .invoice-info h1 {
        font-size: 22px !important
    }
}

.invoice-section .invoice-item .invoice-info h6 {
    font-weight: 500;
    font-size: 14px;
    color: #737373
}

.invoice-section .invoice-item-bill {
    position: relative;
    background: #201f1d;
    border-radius: 10px;
    padding: 30px;
    margin: 0 0 37px
}

@media(max-width:768px) {
    .invoice-section .invoice-item-bill {
        padding: 15px
    }
}

.invoice-section .invoice-item-bill:before {
    content: "";
    background: url(../img/bg/invoice.png) no-repeat;
    position: absolute;
    top: -45px;
    left: -45px;
    width: 179px;
    height: 180px;
    background-size: cover
}

.invoice-section .invoice-item-bill ul {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding-left: 0;
    list-style: none;
    margin: 0
}

.invoice-section .invoice-item-bill ul li {
    -ms-flex: 33.33%;
    flex: 33.33%;
    max-width: 33.33%;
    border-left: 1px solid #fcfbfb;
    display: flex;
    justify-content: center
}

@media(max-width:575.98px) {
    .invoice-section .invoice-item-bill ul li {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
        border-left: 0;
        padding-left: 0;
        justify-content: start;
        margin: 0 0 15px
    }

    .invoice-section .invoice-item-bill ul li:last-child {
        margin: 0
    }
}

.invoice-section .invoice-item-bill ul li:first-child {
    border-left: 0
}

.invoice-section .invoice-item-bill ul li .invoice-info h6 {
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin: 0 0 15px
}

.invoice-section .invoice-item-bill ul li .invoice-info p {
    font-size: 14px;
    color: #fff;
    margin: 0 0 15px
}

.invoice-section .payment-details {
    margin-top: 40px;
    max-width: 950px
}

@media(max-width:575.98px) {
    .invoice-section .payment-details .invoice-terms {
        margin: 0 0 20px
    }
}

.invoice-section .payment-details .invoice-terms h6 {
    font-weight: 500;
    font-size: 18px;
    color: #2f2f2f;
    margin-bottom: 18px
}

.invoice-section .payment-details .invoice-terms .invocie-note {
    border: 2px dashed #787878;
    border-radius: 4px;
    max-width: 266px;
    padding: 20px
}

.invoice-section .payment-details .invoice-terms .invocie-note h6 {
    font-weight: 500;
    font-size: 18px;
    color: #201f1d;
    margin: 0 0 20px
}

.invoice-section .payment-details .invoice-terms .invocie-note p {
    font-weight: 500;
    font-size: 14px;
    color: #2f2f2f
}

.invoice-section .invoice-total {
    background: #f1f1f1;
    border: 2px dashed #fcfcfc;
    margin: 33px 0;
    padding: 10px;
    text-align: right
}

.invoice-section .invoice-total h4 {
    font-weight: 500;
    font-size: 20px;
    color: #201f1d
}

@media(max-width:767.98px) {
    .invoice-section .invoice-total h4 {
        font-size: 18px
    }
}

.invoice-section .invoice-total h4 span {
    padding-left: 88px
}

@media(max-width:575.98px) {
    .invoice-section .invoice-total h4 span {
        padding: 0
    }
}

.invoice-section .invoice-total-box .invoice-total-inner p {
    font-weight: 500;
    font-size: 14px;
    color: #828282;
    margin-bottom: 25px
}

.invoice-section .invoice-total-box .invoice-total-inner p span {
    float: right;
    font-weight: 700;
    font-size: 15px;
    color: #2f2f2f
}

.invoice-section .invoice-note-footer .invocie-note {
    margin: 0 0 20px
}

.invoice-section .invoice-note-footer .invocie-note h6 {
    font-weight: 500;
    font-size: 18px;
    color: #201f1d;
    margin: 0 0 13px
}

.invoice-section .invoice-note-footer .invocie-note p {
    font-weight: 500;
    font-size: 14px;
    color: #2f2f2f
}

.invoice-section .invoice-note-footer .invoice-sign {
    float: right
}

@media(max-width:575.98px) {
    .invoice-section .invoice-note-footer .invoice-sign {
        float: left
    }
}

.invoice-section .invoice-note-footer .invoice-sign img {
    margin: 0 0 19px
}

@media(max-width:991.98px) {
    .invoice-section .invoice-note-footer .invoice-sign img {
        margin: 0
    }
}

.invoice-section .invoice-note-footer .invoice-sign span {
    font-weight: 500;
    font-size: 18px;
    color: #201f1d;
    text-align: center
}

.blog-section {
    position: relative;
    padding: 80px 0
}

@media(max-width:991.98px) {
    .blog-section {
        padding: 60px 0
    }
}

@media(max-width:575.98px) {
    .blog-section {
        padding: 40px 0
    }
}

.blog-section .grid-blog {
    background: #fff;
    margin: 0 0 25px;
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    overflow: hidden
}

.blog-section .grid-blog .grid-blog .blog-image {
    overflow: hidden;
    border-radius: 10px 10px 0 0
}

.blog-section .pagination {
    margin: 25px 0 0
}

@media(max-width:991.98px) {
    .blog-section .pagination {
        margin: 15px 0 25px
    }
}

.blog-section .blog-image {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px 10px 0 0
}

.blog-section .blog-image>a {
    display: block;
    position: relative;
    width: 100%;
    height: auto
}

.blog-section .blog-image img {
    display: block;
    position: relative;
    border-radius: 10px 10px 0 0;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms;
    width: 100%
}

.blog-section .blog-image img:hover {
    -webkit-transform: scale(1.35) rotate(15deg);
    -moz-transform: scale(1.35) rotate(15deg);
    transform: scale(1.35) rotate(15deg)
}

.blog-section .blog-content {
    padding: 20px
}

.blog-section .blog-content .blog-category {
    margin: 0 0 15px
}

.blog-section .blog-content .blog-category a {
    background: #127384;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    display: inline-block;
    margin: 0 0 5px;
    padding: 5px 14px
}

.blog-section .blog-content .blog-category a:hover {
    background: #141432;
    color: #fff
}

.blog-section .blog-content .blog-title {
    margin: 0 0 20px
}

.blog-section .blog-content .blog-title a {
    font-weight: 700;
    font-size: 24px;
    color: #201f1d
}

.blog-section .blog-content .blog-title a:hover {
    color: #127384
}

@media(max-width:991.98px) {
    .blog-section .blog-content .blog-title a {
        font-size: 20px
    }
}

@media(max-width:767.98px) {
    .blog-section .blog-content .blog-title a {
        font-size: 18px
    }
}

.blog-section .blog-content .blog-description {
    margin: 0 0 25px
}

.blog-section .blog-content .meta-item {
    background: #f2f7f6;
    border-radius: 5px;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 15px;
    padding: 13px
}

.blog-section .blog-content .meta-item li .post-author .post-author-img {
    width: 25px;
    margin-right: 7px;
    line-height: 0
}

.blog-section .blog-content .meta-item li .post-author .post-author-img img {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    border: 1.5px solid #fff
}

.blog-section .blog-content .meta-item li .post-author a span {
    font-size: 14px;
    color: #828282
}

.blog-section .blog-content .meta-item .date-icon i {
    color: #2f2f2f;
    font-weight: 400;
    margin: 0 7px
}

.blog-section .blog-content .meta-item .date-icon span {
    font-size: 14px
}

.blog-section .blog-content .blog-list-date {
    align-items: center;
    margin: 0 0 30px
}

.blog-section .blog-content .blog-list-date .meta-item-list {
    align-items: center;
    flex-wrap: wrap
}

.blog-section .blog-content .blog-list-date .meta-item-list li .post-author .post-author-img {
    width: 25px;
    margin-right: 7px
}

.blog-section .blog-content .blog-list-date .meta-item-list li .post-author .post-author-img img {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    border: 1.5px solid #fff
}

.blog-section .blog-content .blog-list-date .meta-item-list li .post-author a span {
    font-size: 14px;
    color: #737373
}

.blog-section .blog-content .blog-list-date .meta-item-list .date-icon i {
    margin: 0 7px;
    font-weight: 400
}

.blog-section .blog-content .blog-list-date .meta-item-list .date-icon span {
    font-size: 14px
}

.blog-section .blog-content .viewlink {
    display: inline-flex;
    align-items: center;
    padding: 12px 18px;
    font-weight: 700;
    font-size: 16px
}

.blog-section .blog-content .viewlink:active {
    background: #ffa633;
    border-color: #ffa633
}

.blog-section .blog-content .viewlink a {
    color: #fff
}

.blog-section .blog-content .viewlink a:hover {
    color: #ffa633
}

.blog-section .blog-content .viewlink a i {
    line-height: 0
}

.blog-section .rightsidebar .card {
    background: #fff;
    border-radius: 10px;
    margin: 0 0 25px;
    padding: 25px
}

.blog-section .rightsidebar .card.tags-widget {
    padding-bottom: 17px
}

.blog-section .rightsidebar .card h4 {
    border-bottom: 1px solid #f4f4f4;
    margin: 0 0 25px;
    padding: 0 0 20px;
    font-size: 20px;
    font-weight: 600;
    color: #201f1d
}

.blog-section .rightsidebar .card h4 i {
    margin: 0 10px 0 0;
    color: #ffa633
}

.blog-section .rightsidebar .card h4 img {
    margin: 0 8px 0 0
}

.blog-section .rightsidebar .card .blogcategories-list {
    padding: 0 0 0 17px;
    color: #666
}

.blog-section .rightsidebar .card .blogcategories-list li {
    list-style: disc;
    display: list-item;
    margin-bottom: 19px
}

.blog-section .rightsidebar .card .blogcategories-list li:hover {
    color: #ffa633
}

.blog-section .rightsidebar .card .blogcategories-list li a {
    color: #737373
}

.blog-section .rightsidebar .card .blogcategories-list li a:hover {
    padding-left: 10px;
    color: #ffa633
}

.blog-section .rightsidebar .card .blogcategories-list li:last-child {
    margin-bottom: 0
}

.blog-section .rightsidebar .card .tags li {
    background-color: #f1f1f1;
    color: #828282;
    padding: 9px 19px;
    position: relative;
    margin: 0 5px 8px 0;
    font-size: 14px;
    border: 1px solid #e3e3e3;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.blog-section .rightsidebar .card .tags li:hover {
    background-color: #ffa633;
    color: #fff;
    border: 1px solid #ffa633;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.blog-section .rightsidebar .card .article {
    position: relative;
    overflow: hidden;
    margin-bottom: 24px
}

.blog-section .rightsidebar .card .article:hover .article-blog img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15)
}

.blog-section .rightsidebar .card .article:hover .article-content h5 a {
    color: #ffa633
}

.blog-section .rightsidebar .card .article:hover .article-content .article-date {
    color: #ffa633
}

.blog-section .rightsidebar .card .article .article-blog {
    position: relative;
    overflow: hidden;
    border-radius: 10px
}

.blog-section .rightsidebar .card .article .article-blog img {
    transform: translateZ(0);
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 0ms
}

.blog-section .rightsidebar .card .article .article-blog:before {
    background: linear-gradient(0deg, #000000 16.31%, rgba(0, 0, 0, 0) 100%);
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-transition: .7s;
    -moz-transition: .7s;
    -o-transition: .7s;
    transition: .7s
}

.blog-section .rightsidebar .card .article .article-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding: 20px;
    -webkit-transition: .7s;
    -moz-transition: .7s;
    -o-transition: .7s;
    transition: .7s
}

.blog-section .rightsidebar .card .article .article-content h5 {
    margin-bottom: 10px
}

.blog-section .rightsidebar .card .article .article-content h5 a {
    font-weight: 600;
    font-size: 24px;
    color: #fff
}

.blog-section .rightsidebar .card .article .article-content .article-date {
    font-size: 14px;
    color: #fff
}

.blog-section .rightsidebar .card .article .article-content .article-date i {
    color: #ffa633;
    margin-right: 10px
}

.blog-section .rightsidebar .card .article:last-child {
    margin-bottom: 0
}



@media(max-width:991.98px) {
    .blogbanner {
        min-height: 350px
    }
}

.blogbanner:after {
    content: "";
    background: linear-gradient(0deg, #000000 16.31%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

.blog-description p {
    margin: 0 0 20px
}

.blogbanner-content {
    position: absolute;
    bottom: 40px;
    text-align: center;
    width: 100%;
    color: #fff;
    padding: 0 15px
}

@media(max-width:767.98px) {
    .blogbanner-content {
        bottom: 90px
    }
}

.blogbanner-content h1 {
    color: #fff;
    font-size: 32px;
    margin: 0 0 15px
}

@media(max-width:991.98px) {
    .blogbanner-content h1 {
        font-size: 26px
    }
}

@media(max-width:575.98px) {
    .blogbanner-content h1 {
        font-size: 24px
    }
}

.blogbanner-content .post-author-img {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    margin-right: 10px
}

.blogbanner-content .post-author-img img {
    border-radius: 50px;
    width: 32px;
    height: 32px
}

.blogbanner-content .post-author a {
    font-size: 14px;
    color: #fff
}

.blogbanner-content .post-author a:hover {
    color: #fff
}

.blogbanner-content .post-author img {
    margin-right: 5px
}

.blogbanner-content .entry-meta li {
    margin-right: 20px
}

.blogbanner-content .date-icon i {
    margin-right: 5px
}

.blogbanner-content span.blog-hint {
    background: #127384;
    padding: 7px 12px;
    border-radius: 4px;
    margin: 0 0 15px;
    display: inline-block;
    font-size: 14px
}

.bloggrid-sidebar .blog.grid-blog .post-author a {
    margin: 0
}

.blogdetail-content {
    padding: 70px 0;
    background: #f9fafc
}

.blogdetail-content p {
    color: #fff;
    line-height: 27px
}

.blogdetail-content .card-header {
    padding: 0 0 25px;
    border-bottom: 1px solid #dee2e7;
    margin: 0 0 25px;
    border-radius: 0;
    background: 0 0
}

.blogdetail-content .card-header h4 {
    font-size: 22px;
    margin: 0 0 0 12px;
    font-weight: 500;
    color: #fff;
    font-size: 20px
}

.blogdetail-content .card-body {
    padding: 0
}

.blog-quotes {
    background: #fff;
    padding: 30px;
    text-align: center;
    margin: 25px 0;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 20px
}

.blog-quotes i {
    font-size: 52px;
    color: #fff
}

.blog-quotes img {
    margin: 0 0 10px
}

.blog-quotes h6 {
    color: #787878;
    font-weight: 700;
    font-size: 16px;
    margin: 10px 0 0
}

.blog-quotes p {
    font-size: 14px;
    color: #2f2f2f
}

.bloginner-img {
    margin: 25px 0
}

.bloginner-img img {
    width: 100%;
    border-radius: 20px
}

.requiremnts-info h5 {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    margin: 0 0 20px
}

.requiremnts-info li {
    list-style: disc;
    margin: 0 0 15px;
    line-height: 27px;
    color: #fff
}

.requiremnts-info ul {
    margin: 0 0 0 17px
}

.tag-list {
    justify-content: flex-end
}

@media(max-width:575.98px) {
    .tag-list {
        justify-content: start
    }
}

@media(max-width:575.98px) {
    .tag-list .tags {
        display: block !important
    }
}

.tag-list .tags li {
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    padding: 7px 15px;
    font-size: 14px;
    margin-right: 15px;
    border-radius: 5px;
    color: #828282;
    cursor: pointer
}

@media(max-width:575.98px) {
    .tag-list .tags li {
        margin: 10px 0 0;
        width: 100%
    }
}

.tag-list .tags li:hover {
    background: #ffa633;
    color: #fff
}

.share-postsection {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding: 30px 0;
    margin: 25px 0
}

@media(max-width:575.98px) {
    .share-postsection .sharelink {
        margin: 0 0 20px
    }
}

.share-postsection .sharelink .share-img {
    color: #fff;
    background: #127384;
    font-size: 20px;
    border-radius: 50px;
    margin-right: 10px;
    width: 46px;
    height: 46px
}

.share-postsection .sharelink .share-img:hover {
    color: #fff;
    background: #ffa633
}

.share-postsection .sharelink a {
    color: #2f2f2f;
    font-size: 18px
}

.share-postsection .sharelink a.share-text:hover {
    color: #127384
}

.blogdetails-pagination ul {
    justify-content: space-between
}

@media(max-width:767.98px) {
    .blogdetails-pagination ul {
        display: block
    }
}

.blogdetails-pagination ul li:last-child {
    text-align: right
}

@media(max-width:767.98px) {
    .blogdetails-pagination ul li:last-child {
        text-align: left
    }
}

.blogdetails-pagination ul li .prev-link {
    color: #127384;
    font-size: 14px;
    margin: 0 0 20px;
    display: block
}

.blogdetails-pagination ul li .prev-link:hover {
    color: #828282
}

.blogdetails-pagination ul li .prev-link i {
    margin-right: 7px
}

.blogdetails-pagination ul li .next-link {
    color: #828282;
    font-size: 14px;
    margin: 0 0 20px;
    display: block
}

.blogdetails-pagination ul li .next-link:hover {
    color: #127384
}

.blogdetails-pagination ul li .next-link i {
    margin-left: 7px
}

.blogdetails-pagination ul li h3 {
    font-size: 20px;
    font-weight: 500;
    color: #141432;
    margin: 0 0 25px
}

.blogdetails-pagination ul li h3:hover {
    color: #141432
}

.extra-service {
    padding-bottom: 24px !important
}

.listing-review {
    padding-bottom: 9px !important
}

.review-sec {
    background: #fff;
    padding: 24px;
    margin: 24px 0;
    border: 0;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 10px
}

@media(max-width:767.98px) {
    .review-sec {
        padding: 20px
    }
}

.review-sec .review-header {
    position: relative;
    border-bottom: 1px solid #dee2e7;
    padding: 0 0 20px;
    margin: 0 0 25px;
    border-radius: 0;
    background: 0 0
}

@media(max-width:767.98px) {
    .review-sec .review-header {
        padding: 0 0 20px
    }
}

.review-sec h4 {
    font-weight: 500;
    font-size: 20px;
    color: #201f1d
}

@media(max-width:991.98px) {
    .review-sec h4 {
        font-size: 18px
    }
}

.review-sec h4::before {
    content: "";
    background: #ffa633;
    border-radius: 8px;
    width: 20px;
    height: 4px;
    left: 0;
    position: absolute;
    bottom: -3px
}

.review-sec .review-details h6 {
    color: #fff;
    font-size: 20px;
    margin: 0 0 30px
}

.review-sec .review-details .submit-section {
    text-align: right
}

.review-sec .review-details .submit-section .submit-review {
    padding: 15px 20px
}

.review-sec .reviewbox-list-rating i {
    color: #ff9307;
    font-size: 12px;
    line-height: 0
}

.review-sec .reviewbox-list-rating span {
    font-weight: 500;
    font-size: 14px;
    color: #74788d;
    margin-left: 7px
}

.review-sec .review-card {
    border: 1px solid #f4f4f4;
    padding: 15px;
    margin-bottom: 24px;
    border-radius: 10px
}

.review-sec .review-card .review-header-group {
    margin-bottom: 10px
}

@media(max-width:767.98px) {
    .review-sec .review-card .review-header-group {
        display: block
    }
}

.review-sec .review-card .review-header-group .review-widget-header .review-widget-img {
    display: flex
}

.review-sec .review-card .review-header-group .review-widget-header .review-widget-img img {
    width: 60px;
    height: 60px;
    border-radius: 10px
}

.review-sec .review-card .review-header-group .review-widget-header .review-design {
    margin-left: 15px
}

.review-sec .review-card .review-header-group .review-widget-header .review-design .h6 {
    font-weight: 500;
    font-size: 20px;
    color: #201f1d
}

.review-sec .review-card .review-header-group .review-widget-header .review-design p {
    font-weight: 500;
    font-size: 14px;
    margin: 5px 0 0
}

@media(max-width:767.98px) {
    .review-sec .review-card .review-header-group .reviewbox-list-rating p {
        display: block;
        margin-top: 10px
    }
}

.review-sec .search-btn .search-button {
    background-color: #ffa633;
    border: 1px solid #ffa633;
    box-shadow: inset 0 0 0 #fff;
    color: #fff;
    padding: 12px 20px
}

@media(max-width:991.98px) {
    .review-sec .search-btn .search-button {
        width: 250px
    }
}

@media(max-width:991.98px) {
    .review-sec .search-btn .search-button {
        padding: 12px 8px
    }
}

@media(max-width:1199.98px) {
    .review-sec .search-btn .search-button {
        padding: 12px
    }
}

.review-sec .search-btn .search-button:hover {
    background-color: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff;
    color: #201f1d
}

.review-sec .column-group-last {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%
}

.review-sec .column-group-last:last-child {
    padding-right: 0
}

@media(max-width:575.98px) {
    .review-sec .column-group-last .search-btn {
        margin: 0
    }
}

.review-sec .column-group-main {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    margin-bottom: 0
}

.review-sec .column-group-main:last-child {
    padding-right: 0
}

@media(max-width:991.98px) {
    .review-sec .sidebar-form {
        display: block
    }
}

.review-sec .input-block label {
    margin-bottom: 7px
}

.testimonials-section {
    position: relative;
    padding: 80px 0
}

@media(max-width:991.98px) {
    .testimonials-section {
        padding: 50px 0
    }
}

@media(max-width:767.98px) {
    .testimonials-section {
        padding: 40px 0
    }
}

.testimonials-section .testimonial-group {
    margin: 0 0 40px
}

@media(max-width:767.98px) {
    .testimonials-section .testimonial-group {
        margin: 0 0 20px
    }
}

.testimonials-section .owl-stage-outer {
    margin: 0 0 25px
}

@media(max-width:991.98px) {
    .testimonials-section .owl-stage-outer {
        margin: 0
    }
}

.testimonials-section .card-body {
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease;
    -webkit-box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 8px
}

.testimonials-section .card-body:hover {
    background-color: #127384
}

.testimonials-section .card-body:hover .review-img {
    border: 3px solid #fff
}

.testimonials-section .card-body:hover .review-details h6 {
    color: #fff
}

.testimonials-section .card-body:hover .list-rating p span {
    color: #fff
}

.testimonials-section .card-body:hover p {
    color: #fff
}

.testimonials-section .review-box {
    align-items: center;
    margin: 0 0 20px
}

@media(max-width:991.98px) {
    .testimonials-section .review-box {
        margin: 0 0 15px
    }
}

.testimonials-section .review-box .review-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid #127384;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

@media(max-width:991.98px) {
    .testimonials-section .review-box .review-img {
        width: 40px;
        height: 40px
    }
}

.testimonials-section .review-box .review-img img {
    border-radius: 50px
}

.testimonials-section .review-box .review-details {
    margin-left: 18px
}

@media(max-width:991.98px) {
    .testimonials-section .review-box .review-details {
        margin-left: 12px
    }
}

.testimonials-section .review-box .review-details h6 {
    font-weight: 500;
    font-size: 18px;
    color: #2f2f2f;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease;
    margin: 0 0 7px
}

@media(max-width:991.98px) {
    .testimonials-section .review-box .review-details h6 {
        font-size: 16px
    }
}

.testimonials-section .review-box .review-details .list-rating {
    display: inline-flex
}

.testimonials-section .review-box .review-details .list-rating .list-rating-star i {
    font-size: 12px;
    line-height: 0;
    color: #ffa633
}

.testimonials-section .review-box .review-details .list-rating p {
    color: #737373;
    line-height: 0;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.testimonials-section .review-box .review-details .list-rating p span {
    font-size: 14px;
    margin: 0 5px;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.testimonials-section p {
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.testimonials-section .quotes-head::before {
    content: "";
    display: block;
    width: 28px;
    height: 28px;
    /* background: url(../img/quote.svg) no-repeat center center; */
    margin: 0 0 20px
}

@media(max-width:991.98px) {
    .testimonials-section .quotes-head::before {
        width: 18px;
        height: 18px;
        margin: 0 0 20px
    }
}

.about-img {
    position: relative;
    margin: 0 0 24px
}

.about-img img {
    border-radius: 10px;
    padding: 0 0 0 10px
}

.about-img .about-exp {
    position: absolute;
    background: #ffa633;
    border-radius: 10px;
    width: 370px;
    height: 100%
}

@media(max-width:767.98px) {
    .about-img .about-exp {
        width: 230px
    }
}

.about-img .about-exp span {
    font-weight: 500;
    font-size: 28px;
    color: #fff;
    display: inline-block;
    margin: 250px -122px;
    transform: rotate(-90deg)
}

@media(max-width:767.98px) {
    .about-img .about-exp span {
        font-size: 18px;
        margin: 275px -90px
    }
}

@media(max-width:575.98px) {
    .about-img .about-exp span {
        font-size: 16px;
        margin: 115px -75px
    }
}

.abt-img {
    padding: 30px 0 30px 60px;
    position: relative;
    z-index: 1
}

.about-content {
    margin: 0 0 0 50px
}

@media(max-width:991.98px) {
    .about-content {
        margin: 0
    }
}

.about-content h6 {
    font-size: 18px;
    color: #127384;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 12px
}

.about-content h2 {
    font-size: 38px;
    font-weight: 500;
    margin: 0 0 30px
}

@media(max-width:991.98px) {
    .about-content h2 {
        font-size: 28px
    }
}

@media(max-width:767.98px) {
    .about-content h2 {
        font-size: 24px
    }
}

.about-content p {
    margin: 0 0 14px
}

.about-content ul li {
    position: relative;
    margin: 0 0 15px;
    padding: 0 0 0 25px;
    color: #828282
}

.about-content ul li::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "\f058";
    font-family: fontawesome;
    color: #127384
}

.about-content ul li:last-child {
    margin: 0
}

.product-details {
    position: relative;
    z-index: 9;
    padding: 80px 0 56px
}

@media(max-width:991.98px) {
    .product-details {
        padding: 50px 0 26px
    }
}

.product-detail-head {
    padding: 24px 0;
    background: #fff;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25)
}

@media(max-width:991.98px) {
    .product-detail-head {
        padding: 24px 24px 9px
    }
}

@media(max-width:767.98px) {
    .product-detail-head .detail-page-head {
        display: block
    }
}

.product-detail-head .detail-page-head .list-rating {
    margin-bottom: 15px
}

.product-detail-head .detail-page-head .list-rating i {
    color: #ffa633
}

@media(max-width:767.98px) {
    .product-detail-head .detail-page-head .camaro-info {
        margin-bottom: 15px
    }
}

@media(max-width:575.98px) {
    .product-detail-head .detail-page-head .camaro-location {
        align-items: start;
        flex-direction: column
    }
}

@media(max-width:575.98px) {
    .product-detail-head .detail-page-head .camaro-location .camaro-location-inner {
        display: flex;
        align-items: baseline
    }
}

.product-detail-head .detail-page-head .year {
    display: inline-block;
    margin: 0 13px 0 0;
    padding: 10px 15px;
    background: #127384;
    color: #fff
}

.product-detail-head .detail-page-head .average-list-rating {
    color: #737373;
    font-size: 15px;
    font-weight: 500;
    margin-left: 7px
}

.product-detail-head .detail-page-head h3 {
    font-weight: 500;
    margin-bottom: 10px
}

.product-detail-head .search-btn .search-button {
    background-color: #ffa633;
    border: 1px solid #ffa633;
    box-shadow: inset 0 0 0 #fff;
    color: #fff;
    padding: 12px 20px
}

@media(max-width:991.98px) {
    .product-detail-head .search-btn .search-button {
        width: 250px
    }
}

@media(max-width:991.98px) {
    .product-detail-head .search-btn .search-button {
        padding: 12px 8px
    }
}

@media(max-width:1199.98px) {
    .product-detail-head .search-btn .search-button {
        padding: 12px
    }
}

.product-detail-head .search-btn .search-button:hover {
    background-color: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff;
    color: #201f1d
}

.product-detail-head .column-group-last {
    -ms-flex: 10%;
    flex: 10%;
    max-width: 10%
}

@media(max-width:575.98px) {
    .product-detail-head .column-group-last {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%
    }
}

@media(max-width:991.98px) {
    .product-detail-head .column-group-last {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%
    }
}

.product-detail-head .column-group-last:last-child {
    padding-right: 0
}

.product-detail-head .column-group-last .search-btn {
    margin-top: 38px
}

@media(max-width:575.98px) {
    .product-detail-head .column-group-last .search-btn {
        margin: 0
    }
}

.product-detail-head .column-group-main {
    -ms-flex: 30%;
    flex: 30%;
    max-width: 30%
}

@media(max-width:991.98px) {
    .product-detail-head .column-group-main {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
        margin-bottom: 15px
    }
}

@media(max-width:575.98px) {
    .product-detail-head .column-group-main {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
        margin-bottom: 0
    }
}

.product-detail-head .column-group-main:last-child {
    padding-right: 0
}

.product-detail-head .details-btn {
    gap: 15px
}

@media(max-width:991.98px) {
    .product-detail-head .details-btn {
        margin: 0;
        display: block
    }
}

.product-detail-head .details-btn a {
    background: #f2f7f6;
    color: #737373;
    border-radius: 40px;
    padding: 15px 45px
}

@media(max-width:991.98px) {
    .product-detail-head .details-btn a {
        margin: 0 0 15px
    }
}

.product-detail-head .details-btn a:hover {
    background: #127384;
    color: #fff
}

.product-detail-head .details-btn a:hover img {
    filter: contrast(50)
}

.product-detail-head .details-btn a i {
    margin-right: 8px
}

.product-detail-head .details-btn a img {
    margin-right: 5px
}

.slick-track {
    display: flex
}

.slick-slide {
    width: 25% !important;
    margin: 0 10px;
    overflow: hidden
}

.slick-slide.slick-cloned {
    width: 25% !important;
    margin: 0 10px;
    overflow: hidden
}

.slick-slide.slick-active {
    width: 25% !important;
    margin: 0 10px;
    overflow: hidden
}

.slick-slide img {
    width: 100%;
    border-radius: 10px
}

.detail-bigimg {
    position: relative;
    margin-bottom: 15px;
    z-index: 999
}

.detail-bigimg button {
    width: 50px;
    height: 50px;
    font-size: 0;
    background: #fff;
    border-radius: 50%;
    border: none;
    z-index: 9999
}

@media(max-width:991.98px) {
    .detail-bigimg button {
        width: 35px;
        height: 35px
    }
}

.detail-bigimg button.slick-prev {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%)
}

.detail-bigimg button.slick-prev:hover {
    background: #ffa633 !important;
    color: #fff;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.detail-bigimg button.slick-prev:before {
    content: "\f060";
    display: inline-block;
    font-family: "font awesome 5 free";
    font-size: 15px;
    font-weight: 900
}

@media(max-width:991.98px) {
    .detail-bigimg button.slick-prev:before {
        font-size: 13px
    }
}

.detail-bigimg button.slick-next {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%)
}

.detail-bigimg button.slick-next:hover {
    background: #ffa633 !important;
    color: #fff;
    -webkit-transition: 500ms all ease;
    -o-transition: 500ms all ease;
    transition: 500ms all ease;
    -moz-transition: 500ms all ease;
    -ms-transition: 500ms all ease
}

.detail-bigimg button.slick-next:before {
    content: "\f061";
    display: inline-block;
    font-family: "font awesome 5 free";
    font-size: 15px;
    font-weight: 900
}

@media(max-width:991.98px) {
    .detail-bigimg button.slick-next:before {
        font-size: 13px
    }
}

.detail-product {
    background: #fff;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25);
    border-radius: 5px;
    overflow: hidden;
    padding: 20px 10px
}

.extra-service span {
    color: #2f2f2f;
    font-weight: 500
}

.specification-card {
    background: #fff;
    padding: 24px 24px 0;
    box-shadow: 3px 5px 13px rgba(222, 226, 231, .44);
    margin: 24px 0;
    border: 0
}

.specification-card .card-body {
    padding: 0
}

.specification-card .feature-img {
    width: 60px;
    height: 60px;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    margin: 0 12px 0 0;
    flex-shrink: 0
}

.specification-card .feature-img.access-feature {
    margin-bottom: 0
}

.specification-card .featues-info h6 {
    color: #737373;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px
}

.specification-card .featues-info span {
    color: #201f1d;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 5px
}

.specification-card .featureslist {
    margin: 0 0 24px 0
}

.specification-card .access-feature.featureslist:last-child {
    margin: 0
}

.listing-feature {
    padding: 24px 24px 9px
}

.listing-feature ul li {
    margin: 0 0 15px;
    color: #737373
}

.listing-feature ul li span {
    padding: 8px;
    background: #127384;
    border-radius: 50%;
    margin-right: 8px
}

.listing-feature ul li span i {
    font-size: 10px;
    color: #fff
}

.description-list p {
    margin-bottom: 24px
}

.description-list p:last-child {
    margin-bottom: 0
}

@media(max-width:575.98px) {
    .review-header-group {
        flex-direction: column;
        align-items: start
    }
}

.review-list-rating {
    color: #2f2f2f;
    margin-top: 15px
}

@media(max-width:767.98px) {
    .review-list-rating {
        flex-direction: column;
        align-items: start
    }
}

.review-list-rating li {
    margin-right: 20px;
    text-transform: capitalize
}

@media(max-width:767.98px) {
    .review-list-rating li {
        margin-bottom: 10px
    }

    .review-list-rating li:last-child {
        margin-bottom: 0
    }
}

@media(max-width:1199.98px) {
    .review-list-rating li {
        margin-right: 10px
    }
}

.review-list-rating li p {
    margin-left: 8px
}

.review-list-rating li p i {
    font-size: 12px;
    color: #ff9307
}

.review-sec .leave-reply-form .review-list-rating {
    margin-top: 0;
    margin-bottom: 25px
}

.review-sec .leave-reply-form .review-list-rating li p i {
    color: #828282
}

.short-video img {
    border-radius: 10px
}

.details-slider-heading {
    margin-bottom: 25px
}

@media(max-width:767.98px) {
    .details-slider-heading h3 {
        margin-top: 30px
    }
}

.details-car-grid {
    padding: 50px 0 0
}

.car-details-slider.owl-carousel .owl-nav {
    display: flex;
    -webkit-display: flex;
    position: absolute;
    top: -80px;
    right: 0
}

@media(max-width:767.98px) {
    .car-details-slider.owl-carousel .owl-nav {
        left: 0;
        top: -100px
    }
}

.car-details-slider.owl-carousel .owl-nav .owl-prev,
.car-details-slider.owl-carousel .owl-nav .owl-next {
    width: 55px;
    height: 55px;
    border: 1px solid #f4f4f4;
    box-shadow: 0 4px 24px rgba(255, 255, 255, .2);
    background: #fff;
    border-radius: 50%;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

@media(max-width:991.98px) {

    .car-details-slider.owl-carousel .owl-nav .owl-prev,
    .car-details-slider.owl-carousel .owl-nav .owl-next {
        width: 35px;
        height: 35px;
        font-size: 12px
    }
}

.car-details-slider.owl-carousel .owl-nav .owl-prev:hover,
.car-details-slider.owl-carousel .owl-nav .owl-next:hover {
    background: #ffa633;
    color: #fff;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

.car-details-slider.owl-carousel .owl-nav .owl-next {
    margin-left: 15px
}

.owner-detail {
    display: flex;
    align-items: center;
    margin-bottom: 20px
}

.owner-detail .owner-img img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 15px
}

@media(max-width:991.98px) {
    .owner-detail .owner-img img {
        width: 40px;
        height: 40px
    }
}

.owner-detail .reviewbox-list-rating h5 {
    color: #2f2f2f;
    font-size: 18px
}

@media(max-width:991.98px) {
    .owner-detail .reviewbox-list-rating h5 {
        font-size: 16px;
        margin-bottom: 5px
    }
}

.booking-list {
    margin-bottom: 20px
}

.booking-list li {
    display: flex;
    color: #737373;
    margin: 0 0 13px;
    padding: 0 0 13px;
    border-bottom: 1px solid #dbdbdb
}

.booking-list li span {
    color: #2f2f2f;
    font-size: 16px
}

.booking-list li:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.booking-list li h6 {
    color: #1fbc2f
}

.message-btn {
    display: flex;
    justify-content: center
}

.message-btn a {
    width: 100%;
    background: #201f1d;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    padding: 12px 20px
}

@media(max-width:991.98px) {
    .message-btn a {
        font-size: 15px;
        padding: 10px 18px
    }
}

.message-btn a:hover {
    background-color: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff;
    color: #201f1d
}

.share-car ul {
    display: flex
}

.share-car ul li {
    margin-right: 8px
}

.share-car ul li:last-child {
    margin-right: 0
}

.share-car ul li .fa-pinterest-p {
    background: #ff2d20
}

.modal.custom-modal.check-availability-modal {
    z-index: 99999
}

.modal.custom-modal.check-availability-modal .modal-dialog {
    max-width: 660px
}

.modal.custom-modal.check-availability-modal .modal-content {
    padding: 30px
}

@media(max-width:767.98px) {
    .modal.custom-modal.check-availability-modal .modal-content {
        padding: 15px
    }
}

.modal.custom-modal.check-availability-modal .modal-content .modal-header {
    margin: 0 0 20px;
    padding: 0 0 20px
}

.modal.custom-modal.check-availability-modal .modal-content .modal-header .close span {
    background: #fff;
    border: 3px solid red;
    border-radius: 10px 10px 10px 0;
    color: red
}

.modal.custom-modal.check-availability-modal .modal-content .modal-body {
    margin: 0 0 20px;
    padding: 0
}

.modal .available-for-ride {
    border: 1px solid #1fbc2f;
    color: #1fbc2f;
    border-radius: 5px;
    padding: 15px;
    background: rgba(31, 188, 47, .09);
    margin-bottom: 20px
}

.modal .available-for-ride p {
    color: #1fbc2f;
    font-size: 18px
}

.modal .available-for-ride p i {
    margin-right: 8px;
    font-size: 14px
}

.modal .booking-info {
    border: 1px solid #dbdbdb;
    background: #fcfcfc;
    border-radius: 10px;
    margin: 0 0 20px;
    padding: 20px
}

@media(max-width:767.98px) {
    .modal .booking-info {
        padding: 15px
    }
}

.modal .booking-info h5 {
    margin-bottom: 15px
}

.modal .booking-info p {
    margin-bottom: 15px
}

.modal .booking-info span {
    color: #828282
}

.modal .booking-info h6 {
    color: #828282
}

.modal .booking-info h6 span {
    color: red;
    font-size: 24px
}

.modal .booking-info.seat-select {
    background: #fff
}

.modal .booking-info.seat-select h6 {
    color: #2f2f2f;
    font-size: 15px;
    margin-bottom: 15px
}

.modal .booking-info.seat-select label {
    color: #828282;
    font-size: 14px;
    font-weight: 600;
    padding-left: 25px
}

.modal .booking-info.seat-select label span {
    color: red
}

.modal .booking-info.seat-select label input:checked~.checkmark {
    background: #201f1d
}

.modal .booking-info.seat-select label input:checked~.checkmark::after {
    color: #fff
}

.modal .booking-info.pay-amount {
    background: #fff
}

.modal .booking-info.pay-amount h6 {
    margin-bottom: 15px
}

.modal .booking-info.pay-amount .radio-btn {
    margin-bottom: 10px
}

.modal .booking-info.pay-amount label {
    padding-left: 0
}

.modal .booking-info.pay-amount label input {
    width: 15px;
    height: 15px;
    background: #fff;
    margin-right: 10px
}

.modal .booking-info.pay-amount label input:checked {
    background: #2f2f2f
}

.modal .booking-info.service-tax {
    background: #fff
}

.modal .booking-info.service-tax ul li {
    color: #888;
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid #dbdbdb
}

.modal .booking-info.service-tax ul li span {
    color: #888
}

.modal .booking-info.service-tax ul li:last-child {
    margin: 0;
    padding: 0;
    border-bottom: none
}

.modal .grand-total {
    background: #f2f7f6;
    padding: 20px;
    border-radius: 10px
}

.modal .grand-total h5 {
    font-size: 20px;
    color: #2f2f2f
}

.modal .grand-total span {
    font-size: 20px;
    color: #2f2f2f
}

.modal .modal-footer {
    margin: 0;
    padding: 0;
    border: 0
}

.modal .modal-footer a {
    margin: 0;
    padding: 12px 20px;
    background: #201f1d;
    color: #fff
}

.modal .modal-footer a i {
    margin-left: 8px
}

.booking-section {
    padding: 80px 0
}

@media(max-width:991.98px) {
    .booking-section {
        padding: 50px 0
    }
}

.booking-section .booking-tab {
    justify-content: center;
    margin-bottom: 30px
}

@media(max-width:575.98px) {
    .booking-section .booking-tab {
        flex-direction: column
    }
}

.booking-section .booking-tab li {
    margin-right: 15px
}

@media(max-width:575.98px) {
    .booking-section .booking-tab li {
        margin: 0 0 20px;
        padding: 0
    }
}

.booking-section .booking-tab li a {
    padding: 20px;
    background: #f2f7f6;
    min-width: 260px;
    border: 1px solid #fff
}

.booking-section .booking-tab li a span {
    color: #dbdbdb;
    margin-bottom: 8px;
    display: inline-block
}

.booking-section .booking-tab li a h5 {
    color: #dbdbdb;
    font-size: 20px
}

.booking-section .booking-tab li a.active {
    background: #fff;
    border: 1px solid #f4f4f4
}

.booking-section .booking-tab li a.active h5 {
    color: #2f2f2f
}

.booking-section .booking-tab li a.active span {
    color: #127384;
    font-weight: 600
}

.booking-section .booking-tab li:last-child {
    margin: 0
}

@media(max-width:575.98px) {
    .booking-section .booking-tab li:last-child {
        margin: 0
    }
}

.booking-details {
    max-width: 900px;
    background: #fff;
    border-radius: 10px;
    margin: 0 auto 40px;
    padding: 30px
}

.booking-details .booking-title h3 {
    color: #000;
    font-weight: 600;
    margin-bottom: 20px
}

.booking-details .booking-info {
    border: 1px solid #dbdbdb;
    background: #f2f7f6;
    border-radius: 10px;
    margin: 0 0 20px;
    padding: 20px
}

.booking-details .booking-info .pickup-address {
    padding-left: 0
}

@media(max-width:767.98px) {
    .booking-details .booking-info .pickup-address {
        margin-bottom: 20px
    }
}

@media(max-width:767.98px) {
    .booking-details .booking-info .drop-address {
        margin-bottom: 20px;
        padding-left: 0
    }
}

@media(max-width:767.98px) {
    .booking-details .booking-info .booking-amount {
        padding-left: 0
    }
}

.booking-details .booking-info h5 {
    color: #2f2f2f;
    font-size: 18px;
    margin-bottom: 10px
}

.booking-details .booking-info p {
    color: #828282;
    margin-bottom: 5px
}

.booking-details .booking-info span {
    color: #828282
}

.booking-details .booking-info h6 {
    color: #828282
}

.booking-details .booking-info h6 span {
    color: red;
    font-size: 24px
}

.booking-details .booking-info h6 span i {
    color: #201f1d;
    font-size: 18px
}

.booking-details .booking-form .booking-title h5 {
    color: #201f1d;
    font-size: 20px;
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid #dbdbdb
}

.booking-details .booking-form .payment-btn {
    justify-content: end
}

.booking-details .booking-form .payment-btn button {
    background: #201f1d;
    color: #fff;
    border: 1px solid #201f1d
}

.booking-details .booking-form .payment-btn button:hover {
    background: #fff;
    color: #201f1d;
    border: 1px solid #201f1d
}

.booking-details.payment-details {
    max-width: 800px
}

.back-detail-page {
    text-align: center
}

.back-detail-page a {
    color: red
}

.payment-method {
    border: 1px solid #dbdbdb;
    padding: 20px;
    border-radius: 10px
}

.payment-method h4 {
    color: #201f1d;
    font-size: 20px;
    margin-bottom: 20px
}

@media(max-width:575.98px) {
    .payment-method h4 {
        font-size: 16px
    }
}

.payment-method ul {
    margin-bottom: 20px
}

@media(max-width:767.98px) {
    .payment-method ul {
        flex-wrap: wrap
    }
}

@media(max-width:767.98px) {
    .payment-method ul li {
        flex-basis: 48%;
        margin-bottom: 20px
    }

    .payment-method ul li:last-child {
        margin-bottom: 0
    }

    .payment-method ul li:nth-child(3) {
        margin-bottom: 0
    }
}

@media(max-width:767.98px) and (max-width:575.98px) {
    .payment-method ul li {
        flex-basis: 100%
    }

    .payment-method ul li:nth-child(3) {
        margin-bottom: 20px
    }
}

.payment-method ul li a {
    background: #f2f7f6;
    width: 150px;
    height: 100px;
    border-radius: 10px
}

@media(max-width:767.98px) {
    .payment-method ul li a {
        width: 100%
    }
}

.payment-method .payment-checkbox label {
    color: #787878;
    font-weight: 400
}

.payment-method .payment-checkbox label span {
    color: red
}

.payment-method .payment-checkbox label input:checked~.checkmark {
    background: #201f1d
}

.payment-method .payment-checkbox label input:checked~.checkmark::after {
    color: #fff
}

.modal.custom-modal.check-availability-modal.payment-success-modal .modal-dialog {
    max-width: 400px
}

.modal.custom-modal.check-availability-modal.payment-success-modal .modal-dialog .modal-body {
    text-align: center;
    margin: 0
}

.modal .payment-success .check {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #1fbc2f;
    margin: auto;
    margin-bottom: 25px
}

.modal .payment-success .check i {
    color: #fff
}

.modal .payment-success h5 {
    color: #1fbc2f;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px
}

.modal .payment-success p {
    color: #737373;
    margin-bottom: 25px
}

.modal .payment-success p span {
    color: #201f1d;
    font-weight: 600
}

.modal .payment-success .order-confirm a {
    border: 2px solid #201f1d;
    color: #201f1d;
    font-weight: 700;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 5px
}

.order-confirmation {
    padding: 80px 0
}

@media(max-width:991.98px) {
    .order-confirmation {
        padding: 50px 0
    }
}

@media(max-width:767.98px) {
    .order-confirmation {
        padding: 40px 0
    }
}

.order-confirmation .section-title {
    max-width: 900px;
    margin: 0 auto 20px;
    padding: 0
}

@media(max-width:767.98px) {
    .order-confirmation .section-title {
        display: block
    }
}

.order-confirmation .section-title h5 {
    color: #2f2f2f
}

@media(max-width:767.98px) {
    .order-confirmation .section-title h5 {
        margin: 15px 0 0
    }
}

.order-confirmation .section-title h5 span {
    font-weight: 700;
    font-size: 20px
}

.order-confirmation .order-confirm-box .confirmation-title {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid #dbdbdb
}

.order-confirmation .order-confirm-box .confirmation-title h4 {
    color: #201f1d;
    font-size: 18px
}

.order-confirmation .order-confirm-box .confirmation-title a {
    color: #2f2f2f;
    font-weight: 600
}

.order-confirmation .order-confirm-box .confirmation-title a:hover {
    color: #ffa633
}

.order-confirmation .order-confirm-box .order-car {
    margin-bottom: 20px
}

.order-confirmation .order-confirm-box .order-car img {
    width: 90px;
    border-radius: 10px;
    margin-right: 10px
}

.order-confirmation .order-confirm-box .order-car h5 {
    color: #1fbc2f;
    font-size: 18px
}

.order-confirmation .order-confirm-box .order-car h5 span {
    color: red;
    font-size: 16px;
    display: block;
    margin-top: 8px;
    font-weight: 600
}

.order-confirmation .order-confirm-box .visa-card {
    margin-bottom: 30px
}

.order-confirmation .order-confirm-box .visa-card a {
    display: block;
    margin-bottom: 10px
}

.order-confirmation .order-confirm-box .visa-card h6 {
    color: #737373
}

@media(max-width:991.98px) {
    .order-confirmation .order-confirm-box .address-info {
        margin-bottom: 30px
    }
}

.order-confirmation .order-confirm-box .address-info li {
    color: #737373;
    margin-bottom: 10px
}

.order-confirmation .order-confirm-box .address-info li:last-child {
    margin-bottom: 0
}

.order-confirmation .place-order-btn {
    text-align: right;
    max-width: 900px;
    margin: auto
}

.order-confirmation .place-order-btn a {
    align-items: center;
    padding: 12px 20px
}

.accordion {
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 18px
}

.accordion .filter-title {
    margin-bottom: 20px
}

.accordion .filter-title a {
    font-size: 18px;
    color: #201f1d;
    display: inline-block
}

@media(max-width:991.98px) {
    .accordion .filter-title a {
        font-size: 16px
    }
}

.accordion .view-all {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 12px;
    color: #ffa633
}

.accordion-last {
    border: 0
}

.filter-range .irs--flat .irs-grid {
    display: none
}

.filter-range .irs--flat .irs-min,
.filter-range .irs--flat .irs-max,
.filter-range .irs--flat .irs-grid-text {
    visibility: hidden !important
}

.filter-range .irs--flat .irs-line {
    background: #fff
}

.filter-range .irs--flat .irs-bar {
    background: #ff9307;
    height: 8px
}

.filter-range .irs--flat .irs-single {
    background: #ff9307;
    width: 50px;
    text-align: center;
    margin-left: 10px
}

.filter-range .irs--flat .irs-single:before {
    border-top-color: #ff9307
}

.filter-range .irs--flat .irs-handle.state_hover>i:first-child {
    background: #ff9307
}

.filter-range .irs--flat .irs-handle:hover i {
    background: #ff9307
}

.filter-range .irs--flat .irs-handle>i {
    background: #ff9307;
    width: 15px;
    height: 15px;
    border-radius: 50%
}

.product-search {
    margin: 0 0 20px;
    border-bottom: 1px solid #f4f4f4
}

.form-custom {
    position: relative
}

.form-custom .form-control {
    padding: 10px;
    margin: 0 0 20px;
    font-weight: 500;
    font-size: 14px;
    color: #828282;
    background: #fcfbfb;
    border: 1px solid #f4f4f4
}

.form-custom .form-control:focus {
    background: #fff;
    outline: none
}

.form-custom .form-control::placeholder {
    color: #828282
}

.form-custom span img {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translate(-5%, -50%)
}

.open-filter .custom_check {
    color: #201f1d
}

.open-filter .custom_check i {
    color: red
}

.open-filter i {
    transition: .2s
}

.accordion .collapsed i {
    color: #201f1d;
    transition: .2s;
    transform: rotate(-180deg)
}

.accordion .float-end {
    color: #201f1d;
    font-size: 14px;
    margin-top: 3px
}

@media(max-width:768px) {
    .list-btn {
        display: none
    }
}

@media(max-width:320px) {
    .list-btn .filter-list {
        display: block !important
    }
}

.list-btn .filter-list .short-filter {
    border: 1px solid #888;
    border-radius: 8px;
    color: #201f1d;
    font-size: 14px;
    height: 39px;
    padding: 10px;
    background: #fff
}

.list-btn .filter-list .short-filter .sort {
    background: 0 0;
    border: none;
    outline: 0;
    font-size: 12px;
    font-weight: 600;
    color: #3f4254
}

.list-btn .filter-list li {
    padding: 0 5px 0 0
}

@media(max-width:320px) {
    .list-btn .filter-list li {
        margin: 0 0 10px
    }
}

.list-btn .filter-list li a.active {
    border: 1px solid #ffa633 !important;
    color: #ffa633 !important
}

.list-btn .filter-list li:last-child {
    padding: 0
}

.list-btn .filter-list li ul li {
    padding: 0;
    margin: 0 0 10px
}

.list-btn .filter-list li ul li .download-item {
    color: #201f1d;
    font-size: 14px;
    margin: auto
}

.list-btn .filter-list li ul li .download-item:hover {
    color: #ffa633
}

.list-btn .filter-list li ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0
}

.list-btn .btn-filters {
    border: 1px solid #888;
    border-radius: 8px;
    color: #201f1d;
    min-width: 39px;
    height: 39px;
    background: #fff;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

@media(max-width:320px) {
    .list-btn .btn-filters {
        max-width: 140px
    }
}

.list-btn .btn-filters span {
    margin-bottom: 0;
    line-height: 0;
    font-size: 18px
}

.list-btn .btn-filters:hover {
    box-shadow: 0 40px 0 0 #ffa633 inset;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease;
    color: #fff
}

.list-btn .btn-filters:hover i {
    color: #fff
}

.list-btn .dropdown-menu {
    background: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 16px 32px rgba(190, 190, 190, .2);
    border-radius: 12px;
    padding: 10px;
    margin-top: 10px !important;
    min-width: 100px
}

.list-btn .dropdown-menu .dropdown-item {
    font-weight: 500;
    font-size: 14px;
    color: #878a99;
    display: flex;
    justify-content: start;
    align-items: center
}

.custom_check {
    color: #828282;
    display: inline-block;
    position: relative;
    font-size: 14px;
    margin-bottom: 15px;
    padding-left: 30px;
    cursor: pointer;
    font-weight: 400;
    user-select: none
}

.custom_check input:checked~.checkmark {
    background-color: #201f1d;
    border-color: #201f1d
}

.custom_check input:checked~.checkmark:after {
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px
}

.custom_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer
}

.custom_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    background: #fcfbfb;
    border-radius: 6px;
    transition: all .3s ease-in-out
}

.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "font awesome 5 free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 4px;
    top: 2px;
    color: #fff;
    font-size: 9px
}

.input-block .custom_check {
    margin-bottom: 15px
}

.car-listing .sidebar-form {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 24px rgba(255, 255, 255, .2)
}

@media(max-width:1199.98px) {
    .car-listing .sidebar-form {
        margin-bottom: 24px
    }
}

.car-listing .sidebar-form .sidebar-heading h3 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px
}

@media(max-width:991.98px) {
    .car-listing .sidebar-form .sidebar-heading h3 {
        font-size: 16px
    }
}

.car-listing .sidebar-form .custom_check i {
    color: #ff9307
}

.car-listing .sidebar-form .reset-filter {
    display: block;
    text-align: center;
    margin-top: 15px;
    color: red
}

.car-listing .sidebar-form .reset-filter:hover {
    color: #ffa633
}

.car-listing .blog-pagination {
    margin-top: 25px
}

@media(max-width:479px) {
    .car-listing .blog-pagination {
        margin-top: 10px
    }
}

.car-listing .pagination {
    flex-wrap: wrap
}

@media(max-width:479px) {

    .car-listing .pagination .previtem,
    .car-listing .pagination .nextlink {
        margin-bottom: 10px
    }
}

.car-listing .pagination .previtem a,
.car-listing .pagination .nextlink a {
    height: 38px;
    border-radius: 10px;
    padding: 0 20px;
    color: #2f2f2f
}

.car-listing .pagination .previtem a:focus,
.car-listing .pagination .nextlink a:focus {
    outline: 0;
    box-shadow: none
}

@media(max-width:479px) {
    .car-listing .pagination li .page-group ul {
        flex-wrap: wrap
    }
}

@media(max-width:479px) {
    .car-listing .pagination li .page-group ul li {
        margin-bottom: 10px
    }
}

.car-listing .filter-btn {
    padding: 10px 15px
}

.car-listing .filter-btn span {
    line-height: 1
}

.car-listing .filter-btn:active {
    background: #ffa633;
    border-color: #ffa633
}

@media(max-width:991.98px) {
    .sorting-div .count-search {
        margin-bottom: 20px
    }
}

@media(max-width:575.98px) {
    .sorting-div .product-filter-group {
        display: block
    }
}

@media(max-width:991.98px) {
    .sorting-div .product-filter-group {
        justify-content: start
    }
}

@media(max-width:575.98px) {
    .sorting-div .product-filter-group .sortbyset {
        flex-wrap: wrap
    }
}

.sorting-div .product-filter-group .sortbyset .sortbytitle {
    margin-right: 10px
}

@media(max-width:575.98px) {
    .sorting-div .product-filter-group .sortbyset .sortbytitle {
        margin: 0 0 10px
    }
}

.sorting-div .product-filter-group .sortbyset .sorting-select {
    margin-right: 10px
}

.sorting-div .product-filter-group .sortbyset .sorting-select.select-one {
    width: 70px
}

@media(max-width:575.98px) {
    .sorting-div .product-filter-group .sortbyset .sorting-select.select-one {
        margin-bottom: 15px;
        width: 100%
    }
}

.sorting-div .product-filter-group .sortbyset .sorting-select.select-two {
    width: 150px
}

@media(max-width:575.98px) {
    .sorting-div .product-filter-group .sortbyset .sorting-select.select-two {
        margin-bottom: 15px;
        width: 100%
    }
}

.sorting-div .product-filter-group .sortbyset .sorting-select.select-three {
    width: 300px
}

@media(max-width:767.98px) {
    .sorting-div .product-filter-group .sortbyset .sorting-select.select-three {
        width: 300px
    }
}

@media(max-width:575.98px) {
    .sorting-div .product-filter-group .sortbyset .sorting-select.select-three {
        margin-bottom: 15px;
        width: 100%
    }
}

.sortby-sec {
    background: #fff;
    padding: 30px 0;
    box-shadow: 0 4px 24px rgba(225, 225, 225, .25)
}

.grid-listview ul li {
    margin-right: 10px
}

.grid-listview ul li a {
    width: 40px;
    height: 40px;
    background: #f4f4f4;
    border-radius: 5px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.grid-listview ul li a.active {
    background: #127384
}

.grid-listview ul li a.active i {
    color: #fff
}

.grid-listview ul li a:hover {
    background: #127384;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.grid-listview ul li a:hover i {
    color: #fff;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

.grid-listview ul li a i {
    color: #737373;
    font-size: 16px
}

.grid-listview ul li:last-child {
    margin: 0
}

.listview-car .card {
    margin-bottom: 24px;
    padding: 20px
}

.listview-car .card .blog-img {
    margin-right: 15px;
    flex-shrink: .5;
    overflow: hidden;
    border-radius: 10px
}

@media(max-width:767.98px) {
    .listview-car .card .blog-img {
        margin-right: 0;
        margin-bottom: 15px;
        width: 100%
    }
}

.listview-car .card .blog-img img {
    border-radius: 10px;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease
}

@media(max-width:767.98px) {
    .listview-car .card .blog-img img {
        width: 100%
    }
}

.listview-car .card .blog-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease
}

@media(max-width:767.98px) {
    .listview-car .card .blog-widget {
        flex-direction: column;
        align-items: center
    }
}

.listview-car .card .card-body {
    margin: 0;
    padding: 0
}

.listview-car .card .card-body .blog-list-head {
    margin-bottom: 15px
}

@media(max-width:767.98px) {
    .listview-car .card .card-body .blog-list-head {
        margin-bottom: 20px;
        flex-direction: column;
        align-items: inherit !important
    }
}

.listview-car .card .card-body .blog-list-head .blog-list-title h3 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600
}

.listview-car .card .card-body .blog-list-head .blog-list-title h3 a {
    color: #201f1d
}

.listview-car .card .card-body .blog-list-head .blog-list-title h3 a:hover {
    color: #127384
}

@media(max-width:991.98px) {
    .listview-car .card .card-body .blog-list-head .blog-list-title h3 a {
        font-size: 18px
    }
}

.listview-car .card .card-body .blog-list-head .blog-list-title h6 {
    font-size: 15px;
    color: #737373;
    font-weight: 400
}

@media(max-width:767.98px) {
    .listview-car .card .card-body .blog-list-head .blog-list-title h6 {
        margin-bottom: 10px;
        font-size: 15px
    }
}

.listview-car .card .card-body .blog-list-head .blog-list-title h5 {
    margin-bottom: 3px
}

.listview-car .card .card-body .blog-list-head .blog-list-title h5 a {
    font-size: 16px;
    color: #201f1d
}

.listview-car .card .card-body .blog-list-head .blog-list-title .title-bottom {
    display: flex
}

@media(max-width:767.98px) {
    .listview-car .card .card-body .blog-list-head .blog-list-title .title-bottom {
        margin-bottom: 10px
    }
}

.listview-car .card .card-body .blog-list-head .blog-list-title .title-bottom img {
    margin-right: 10px;
    border-radius: 50%;
    width: 46px;
    height: 46px
}

.listview-car .card .card-body .blog-list-head .blog-list-title .title-bottom h6 i {
    line-height: 0
}

.listview-car .card .card-body .blog-list-head .blog-list-rate h6 {
    font-weight: 700;
    font-size: 24px;
    color: red
}

@media(max-width:767.98px) {
    .listview-car .card .card-body .blog-list-head .blog-list-rate h6 {
        font-size: 22px
    }
}

@media(max-width:767.98px) {
    .listview-car .card .card-body .blog-list-head .blog-list-rate h6 {
        justify-content: start;
        -webkit-justify-content: start;
        -ms-flex-pack: start
    }
}

.listview-car .card .card-body .blog-list-head .blog-list-rate h6 span {
    font-size: 14px;
    font-weight: 400;
    color: #828282;
    margin-left: 7px
}

.listview-car .card .card-body .blog-list-head .blog-list-rate .list-rating {
    margin-bottom: 8px
}

.listview-car .card .card-body .blog-list-head .blog-list-rate .list-rating i {
    color: #ff9307;
    font-size: 14px
}

.listview-car .card .card-body .blog-list-head.list-head-bottom {
    margin: 0;
    align-items: center
}

.listview-car .card .card-body .listing-details-group {
    margin: 0 0 15px;
    padding: 0 0 15px;
    border-bottom: 1px solid #f4f4f4
}

.listview-car .card .card-body .listing-details-group ul {
    margin: 0;
    padding: 10px;
    background: #f2f7f6;
    border-radius: 5px
}

@media(max-width:1199.98px) {
    .listview-car .card .card-body .listing-details-group ul {
        margin: 0;
        padding: 10px 10px 0;
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap
    }
}

@media(max-width:1199.98px) {
    .listview-car .card .card-body .listing-details-group ul li {
        -ms-flex: 33.33%;
        flex: 33.33%;
        max-width: 33.33%;
        display: flex;
        display: -webkit-flex;
        margin-bottom: 15px
    }
}

@media(max-width:767.98px) {
    .listview-car .card .card-body .listing-details-group ul li {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
        display: flex;
        display: -webkit-flex
    }
}

.listview-car .card .card-body .listing-details-group ul li span {
    line-height: 0
}

.listview-car .card .card-body .listing-details-group ul li span img {
    margin-right: 10px;
    line-height: 0;
    width: 16px
}

.listview-car .card .card-body .listing-button .btn-order {
    background: #201f1d;
    color: #fff;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

@media(max-width:767.98px) {
    .listview-car .card .card-body .listing-button .btn-order {
        width: 100%
    }
}

.listview-car .card .card-body .listing-button .btn-order:hover {
    background-color: #127384 !important;
    color: #fff;
    border-radius: 5px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

#checkBoxes1 {
    height: 180px;
    overflow-y: scroll;
    -webkit-overflow-y: scroll;
    -moz-overflow-y: scroll;
    -ms-overflow-y: scroll;
    -o-overflow-y: scroll;
    margin: 0 0 15px
}

#checkBoxes2 {
    height: 180px;
    overflow-y: scroll;
    -webkit-overflow-y: scroll;
    -moz-overflow-y: scroll;
    -ms-overflow-y: scroll;
    -o-overflow-y: scroll;
    margin: 0 0 15px
}

#checkBoxes3 {
    height: 180px;
    overflow-y: scroll;
    -webkit-overflow-y: scroll;
    -moz-overflow-y: scroll;
    -ms-overflow-y: scroll;
    -o-overflow-y: scroll;
    margin: 0 0 15px
}

#checkBoxes4 {
    height: 180px;
    overflow-y: scroll;
    -webkit-overflow-y: scroll;
    -moz-overflow-y: scroll;
    -ms-overflow-y: scroll;
    -o-overflow-y: scroll;
    margin: 0 0 15px
}

#checkBoxes5 {
    height: 180px;
    overflow-y: scroll;
    -webkit-overflow-y: scroll;
    -moz-overflow-y: scroll;
    -ms-overflow-y: scroll;
    -o-overflow-y: scroll;
    margin: 0 0 15px
}

#checkBoxes1::-webkit-scrollbar {
    width: 6px
}

#checkBoxes2::-webkit-scrollbar {
    width: 6px
}

#checkBoxes3::-webkit-scrollbar {
    width: 6px
}

#checkBoxes4::-webkit-scrollbar {
    width: 6px
}

#checkBoxes5::-webkit-scrollbar {
    width: 6px
}

#checkBoxes1::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes2::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes3::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes4::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes5::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes1::-webkit-scrollbar-thumb {
    background-color: #ffa633;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes2::-webkit-scrollbar-thumb {
    background-color: #ffa633;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes3::-webkit-scrollbar-thumb {
    background-color: #ffa633;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes4::-webkit-scrollbar-thumb {
    background-color: #ffa633;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

#checkBoxes5::-webkit-scrollbar-thumb {
    background-color: #ffa633;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

.status-lists {
    margin-bottom: 40px
}

.status-lists li {
    background: rgba(31, 188, 47, .1);
    padding: 10px;
    color: #201f1d;
    font-size: 16px;
    margin-bottom: 13px;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.status-lists li.bg-danger-light {
    background: rgba(239, 59, 45, .1)
}

@media(max-width:767.98px) {
    .status-lists li {
        font-size: 15px
    }
}

.status-lists li span {
    margin-right: 5px
}

.status-lists li:last-child {
    margin-bottom: 0
}

.status-lists li p a {
    text-decoration: underline;
    color: #201f1d
}

.status-lists li p a:hover {
    color: #ffa633
}

.status-lists li .close-link {
    color: #111
}

.status-lists li .close-link:hover {
    color: red
}

.status-lists li .view-detail {
    text-decoration: underline;
    color: #201f1d
}

.status-lists li .view-detail:hover {
    color: #ffa633
}

@media(max-width:575.98px) {
    .status-lists li.approve-item {
        flex-direction: column
    }
}

.widget-box {
    border: 1px solid #f4f4f4;
    background: #fcfcfc;
    box-shadow: 0 4px 24px 0 rgba(225, 225, 225, .25);
    margin: 0 0 24px;
    padding: 24px
}

.widget-box .widget-header {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 15px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between
}

.widget-box .widget-header .widget-content {
    margin-bottom: 15px
}

.widget-box .widget-header .widget-content h6 {
    color: #737373;
    margin-bottom: 5px
}

.widget-box .widget-header .widget-content h3 {
    font-size: 28px;
    color: #111
}

@media(max-width:991.98px) {
    .widget-box .widget-header .widget-content h3 {
        font-size: 24px
    }
}

@media(max-width:767.98px) {
    .widget-box .widget-header .widget-content h3 {
        font-size: 22px
    }
}

.widget-box .widget-header .widget-icon {
    margin-bottom: 15px
}

.widget-box .widget-header .widget-icon span {
    width: 65px;
    height: 65px;
    border-radius: 60px 0 60px 60px;
    background: #127384;
    padding: 15px
}

.widget-box .view-link {
    color: #127384
}

.widget-box .view-link:hover {
    color: #ffa633
}

.bg-danger {
    background-color: red !important
}

.bg-success {
    background-color: #1fbc2f !important
}

.card.user-card {
    border-radius: 5px 5px 0 0;
    border: 1px solid #dbdbdb;
    background: #fff;
    box-shadow: none
}

.card.user-card .card-header {
    border-color: #dbdbdb;
    padding: 15px 15px 7px
}

.card.user-card .card-header h5 {
    color: #111;
    font-size: 20px;
    margin-bottom: 8px
}

@media(max-width:767.98px) {
    .card.user-card .card-header h5 {
        font-size: 18px
    }
}

.booking-select {
    margin-bottom: 8px
}

.booking-select .select2-container--default {
    min-width: 134px !important
}

.booking-select .select2-container--default .select2-selection--single {
    border-color: #dbdbdb;
    background: #f5f5f5
}

.booking-select .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #111;
    line-height: 45px
}

.booking-select .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 45px;
    top: 0
}

.booking-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #111
}

.booking-select .view-link {
    color: #127384;
    margin-left: 20px
}

.booking-select .view-link:hover {
    color: #ffa633
}

.status-box {
    background: #f2f7f6;
    padding: 10px
}

.status-box p {
    font-size: 14px;
    color: #111
}

.status-box p span {
    color: #737373
}

.dashboard-table {
    border: 1px solid #dbdbdb;
    border-radius: 5px
}

.dashboard-table .table.dataTable {
    border-collapse: collapse !important;
    margin: 0 !important
}

.dashboard-table .table.dataTable thead th {
    background-color: #f2f7f6;
    color: #111
}

.dashboard-table .table.dataTable tbody tr:last-child td {
    border-bottom: 0
}

.dashboard-table .table.dataTable tbody tr td {
    padding: 10px 15px
}

.dashboard-table .table.dataTable tbody tr td .reason-link {
    color: #ffa633
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-toggle {
    width: 30px;
    height: 30px;
    font-size: 16px;
    color: #737373;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    background: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-toggle i {
    position: relative;
    left: 0
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-toggle:hover {
    color: #737373;
    border: 1px solid #dbdbdb;
    background: #f1f1f1
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-menu {
    width: 160px;
    background: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: none;
    border-radius: 5px;
    padding: 15px;
    margin-top: 5px !important
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-menu .dropdown-item {
    border-bottom: 0;
    border-radius: 0;
    padding: 10px;
    font-size: 14px;
    color: #737373;
    font-weight: 400;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-menu .dropdown-item:hover {
    color: #111;
    background-color: #f5f5f5;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-menu .dropdown-item:first-child {
    border-radius: 5px 5px 0 0
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
    border-radius: 0 0 5px 5px
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-menu .dropdown-item i {
    font-size: 15px;
    margin-right: 6px;
    line-height: inherit
}

.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-menu .dropdown-item.active,
.dashboard-table .table.dataTable tbody tr td .dropdown-action .dropdown-menu .dropdown-item:active {
    color: #ffa633;
    background-color: #fff
}

.dashboard-table .table td {
    color: #737373
}

.dashboard-table .table td .table-avatar {
    font-weight: 400
}

.dashboard-table .table td .table-avatar a {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
    color: #111
}

.dashboard-table .table td .table-avatar a:hover {
    color: #ffa633
}

.dashboard-table .table td .table-avatar .avatar {
    margin: 0 8px 0 0;
    margin-right: 8px
}

.dashboard-table .table td .table-avatar .avatar img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px
}

.dashboard-table .table td .table-avatar .avatar.avatar-lg {
    width: 60px;
    height: 60px
}

.dashboard-table .table td .table-avatar .avatar.avatar-md {
    width: 45px;
    height: 45px
}

.dashboard-table .table td h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 7px;
    color: #111
}

.dashboard-table .table td p {
    font-size: 14px
}

.dashboard-table .table td .badge {
    border-radius: 5px;
    padding: 7px 5px;
    font-size: 14px;
    font-weight: 500;
    min-width: 80px;
    text-align: center
}

.dashboard-table .table td h5 {
    font-size: 15px;
    font-weight: 600
}

.dashboard-table .table tbody tr {
    border: 0
}

.dashboard-table .table tbody tr td {
    border-bottom: 1px solid #dbdbdb
}

.dashboard-table .table thead {
    background-color: transparent;
    border-bottom: 0
}

.dashboard-table .table thead tr th {
    font-weight: 500;
    padding: 15px;
    font-size: 16px
}

.table-search {
    justify-content: flex-end;
    -webkit-justify-content: end;
    -ms-flex-pack: end
}

@media(max-width:767.98px) {
    .table-search {
        justify-content: start;
        -webkit-justify-content: start;
        -ms-flex-pack: start
    }
}

@media(max-width:575.98px) {
    .table-search {
        flex-direction: column;
        margin-top: 15px
    }
}

.table-search .btn-add {
    border-radius: 5px;
    margin: 0 0 10px;
    padding: 7px 20px;
    color: #fff;
    background-color: #201f1d;
    border: 1px solid #201f1d;
    font-weight: 500;
    font-size: 16px;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    line-height: inherit
}

.table-search .btn-add i {
    margin-right: 5px
}

.table-search .btn-add:hover {
    background-color: #fff;
    color: #201f1d;
    border-radius: 5px;
    box-shadow: inset 0 70px 0 0 #fff;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s
}

#tablefilter {
    margin: 0 15px 10px 0
}

@media(max-width:575.98px) {
    #tablefilter {
        margin-right: 0
    }
}

#tablefilter div.dataTables_filter label {
    position: relative
}

#tablefilter div.dataTables_filter label::before {
    position: absolute;
    font-family: "font awesome 5 free";
    font-weight: 600;
    font-size: 15px;
    content: "\f002";
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #737373
}

#tablefilter div.dataTables_filter .form-control {
    height: 39px;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    min-width: 276px;
    background-color: #f5f5f5;
    padding: 10px 35px 10px 15px
}

@media(max-width:991.98px) {
    #tablefilter div.dataTables_filter .form-control {
        min-width: 220px
    }
}

.table-footer {
    margin-top: 20px
}

.table-footer .page-link {
    width: 47px;
    height: 37px;
    padding: 0;
    border: 0;
    background: 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    color: #201f1d;
    border: 1px solid #dbdbdb;
    color: #2f2f2f;
    border-radius: 10px;
    padding: 10px
}

.table-footer .page-item.active .page-link {
    border-radius: 10px;
    background-color: #127384;
    color: #fff;
    border-color: #127384
}

.table-footer .page-item.active .page-link:focus {
    box-shadow: none
}

.table-footer .pagination {
    margin-top: 0;
    justify-content: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end
}

@media(max-width:767.98px) {
    .table-footer .pagination {
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center
    }
}

.table-footer .pagination li.previous .page-link,
.table-footer .pagination li.next .page-link {
    border-radius: 10px;
    width: auto
}

.table-footer .pagination li.previous {
    margin-right: 16px
}

.table-footer .pagination li.previous i {
    margin-right: 4px
}

@media(max-width:767.98px) {
    .table-footer .pagination li.previous {
        margin-right: 10px
    }
}

.table-footer .pagination li.next {
    margin-left: 24px
}

.table-footer .pagination li.next i {
    margin-left: 4px
}

@media(max-width:767.98px) {
    .table-footer .pagination li.next {
        margin-left: 18px
    }
}

.table-footer .pagination li+li {
    margin-left: 8px
}

.table-footer .dataTables_length label {
    color: #111;
    font-weight: 400 !important;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center
}

.table-footer .dataTables_length .form-control {
    width: auto;
    height: auto;
    border: 2px solid #dbdbdb;
    color: #737373;
    font-size: 16px;
    padding: 5px
}

@media(max-width:767.98px) {
    .table-footer .dataTables_length {
        margin-bottom: 15px;
        text-align: center
    }
}

.dashboard-table-info {
    border: 0;
    border-radius: 0
}

.text-darker {
    color: #111 !important
}

.badge-light-secondary {
    background: rgba(18, 115, 132, .1);
    color: #127384
}

.badge-light-warning {
    background: rgba(255, 147, 7, .1);
    color: #ff9307
}

.badge-light-success {
    background: rgba(31, 188, 47, .1);
    color: #1fbc2f
}

.badge-light-danger {
    background: rgba(255, 0, 0, .1);
    color: red
}

.book-card .card-header {
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 20px;
    padding-bottom: 5px
}

.book-card .card-header h4 {
    font-size: 24px;
    font-weight: 600;
    color: #111;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    margin-bottom: 10px
}

@media(max-width:991.98px) {
    .book-card .card-header h4 {
        font-size: 22px
    }
}

@media(max-width:767.98px) {
    .book-card .card-header h4 {
        font-size: 20px
    }
}

.book-card .card-header h4 span {
    background-color: #737373;
    width: 26px;
    height: 26px;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    border-radius: 50%;
    font-size: 14px;
    color: #fff;
    margin-left: 10px
}

.book-card .card-body {
    padding: 0 24px 24px
}

.sorting-info {
    background: #fff;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 4px 24px 0 rgba(225, 225, 225, .25);
    margin: 0 0 24px;
    padding: 15px
}

@media(max-width:991.98px) {
    .sorting-info .sortby-filter-group {
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center
    }
}

@media(max-width:991.98px) {
    .booking-lists ul {
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center
    }
}

@media(max-width:991.98px) {
    .booking-lists ul li {
        margin-bottom: 15px
    }
}

@media(max-width:767.98px) {
    .booking-lists ul li {
        width: 100%
    }
}

.booking-lists ul li+li {
    margin-left: 15px
}

@media(max-width:767.98px) {
    .booking-lists ul li+li {
        margin-left: 0
    }
}

.booking-lists ul li a {
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    color: #111;
    border: 1px solid #dbdbdb
}

@media(max-width:1199.98px) {
    .booking-lists ul li a {
        padding: 10px 12px
    }
}

@media(max-width:991.98px) {
    .booking-lists ul li a {
        display: block
    }
}

@media(max-width:767.98px) {
    .booking-lists ul li a {
        width: 100%
    }
}

.booking-lists ul li a.active {
    background: #127384;
    color: #fff
}

.booking-lists ul li a:hover {
    background: #127384;
    color: #fff
}

.filter-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center
}

@media(max-width:991.98px) {
    .filter-group {
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center
    }
}

@media(max-width:767.98px) {
    .filter-group {
        flex-direction: column
    }
}

@media(max-width:767.98px) {
    .sort {
        width: 100%
    }
}

.sort.sort-week {
    margin-right: 15px
}

@media(max-width:1199.98px) {
    .sort.sort-week {
        margin-right: 10px
    }
}

@media(max-width:767.98px) {
    .sort.sort-week {
        margin: 0 0 10px
    }
}

.sort .dropdown .dropdown-toggle {
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    background: #f5f5f5;
    padding: 12px;
    min-width: 120px;
    color: #111;
    font-size: 14px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.sort .dropdown .dropdown-toggle i {
    margin-left: 10px
}

.sort .dropdown .dropdown-toggle::after {
    content: none
}

@media(max-width:767.98px) {
    .sort .dropdown .dropdown-toggle {
        width: 100%
    }
}

.sort .dropdown .dropdown-menu {
    min-width: 170px;
    background: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: none;
    border-radius: 5px;
    padding: 15px;
    margin-top: 5px !important
}

.sort .dropdown .dropdown-menu .dropdown-item {
    border-bottom: 1px solid 0;
    border-radius: 0;
    padding: 10px;
    font-size: 14px;
    color: #737373;
    font-weight: 400;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.sort .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #f5f5f5;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.sort .dropdown .dropdown-menu .dropdown-item:first-child {
    border-radius: 5px 5px 0 0
}

.sort .dropdown .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
    border-radius: 0 0 5px 5px
}

.sort .dropdown .dropdown-menu .dropdown-item i {
    font-size: 15px;
    margin-right: 6px
}

.sort .dropdown .dropdown-menu .dropdown-item.active,
.sort .dropdown .dropdown-menu .dropdown-item:active {
    color: #ffa633;
    background-color: #fff
}

@media(max-width:767.98px) {
    .sort .dropdown .dropdown-menu {
        width: 100%
    }
}

.reason-item p {
    color: #2f2f2f;
    font-size: 14px
}

.cancel-ride textarea.form-control {
    height: auto !important;
    resize: none
}

.booking-img-wrap {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center
}

@media(max-width:991.98px) {
    .booking-img-wrap {
        margin-bottom: 15px
    }
}

.booking-img-wrap .book-img {
    margin-right: 10px
}

.booking-img-wrap .book-img img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    object-fit: cover
}

.booking-img-wrap .book-info h6 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px
}

.booking-img-wrap .book-info p {
    font-size: 14px
}

.booking-header {
    border-radius: 5px;
    background: #f2f7f6;
    margin: 0 0 20px;
    padding: 15px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

@media(max-width:991.98px) {
    .booking-header {
        flex-direction: column;
        align-items: start;
        -webkit-align-items: start
    }
}

.booking-header .book-amount {
    text-align: right
}

@media(max-width:991.98px) {
    .booking-header .book-amount {
        text-align: left
    }
}

.booking-header .book-amount p {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000
}

.booking-header .book-amount h6 {
    color: red
}

.booking-header .book-amount h6 a {
    color: #737373
}

.booking-group {
    border-radius: 10px;
    background-color: #f2f7f6;
    padding: 15px;
    margin-bottom: 20px
}

.modal-item {
    margin-bottom: 40px
}

@media(max-width:767.98px) {
    .modal-item {
        margin-bottom: 20px
    }
}

.booking-view {
    margin-bottom: 15px
}

.booking-view h6 {
    font-size: 14px;
    color: #201f1d;
    margin-bottom: 10px
}

.booking-view p {
    font-size: 14px;
    color: #737373
}

.booking-wrapper {
    border-radius: 5px;
    background-color: #fff;
    margin: 0 0 20px;
    padding: 15px 15px 0
}

.booking-wrapper:last-child {
    margin-bottom: 0
}

.booking-wrapper .booking-title h6 {
    border-bottom: 1px solid #f4f4f4;
    margin-bottom: 15px;
    padding-bottom: 15px
}

.booking-wrapper .booking-title h6 a {
    color: #111
}

.booking-wrapper .booking-title .title-icon {
    width: 26px;
    height: 26px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    border-radius: 5px;
    background-color: #ffa633;
    color: #fff;
    margin-right: 10px
}

.booking-wrapper .badge {
    border-radius: 5px;
    padding: 7px 5px;
    font-size: 14px;
    font-weight: 500;
    min-width: 80px;
    text-align: center
}

.booking-wrapper .selectbox-cont .custom_check {
    color: #737373
}

.booking-wrapper .selectbox-cont .custom_check .amt {
    color: red;
    font-weight: 500
}

.modal-btn-item .btn {
    margin-right: 15px
}

.modal-btn-item .btn:last-child {
    margin-right: 0
}

.cancel-reason h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px
}

.cancel-reason p {
    font-size: 14px;
    margin-bottom: 20px
}

.cancel-box {
    font-size: 14px;
    background: #fce3e3;
    color: red;
    padding: 10px
}

.refund-wrap h6 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #737373;
    font-weight: 400
}

.card-status-wrap {
    border-bottom: 1px solid #dbdbdb;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 24px;
    padding-bottom: 14px
}

.card-status-wrap .card-status {
    margin-bottom: 10px
}

.card-status-wrap .card-status h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #111
}

.card-status-wrap .card-status p {
    color: #737373;
    font-size: 16px
}

.card-status-wrap .status-img {
    margin-bottom: 10px
}

.refund-process h5 {
    color: #737373;
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 400
}

.refund-process ul li {
    position: relative;
    padding: 0 0 40px 19px
}

.refund-process ul li:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    border-left: 1px dashed #dbdbdb
}

.refund-process ul li::after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffa633;
    outline: 4px solid #f2f7f6
}

.refund-process ul li:last-child {
    padding-bottom: 15px
}

.refund-process ul li:last-child::before {
    height: 0
}

.refund-process ul li:last-child::before {
    background-color: #fff
}

.refund-process ul li h6 {
    font-size: 16px;
    margin-bottom: 5px;
    color: #111;
    font-weight: 400
}

.refund-process ul li p {
    color: #737373;
    font-size: 14px
}

.listview-car .blog-img {
    position: relative
}

.listview-car .blog-img .fav-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    color: #fff;
    border: 1px solid #fff
}

.listview-car .blog-img .fav-icon.selected {
    background-color: #ffa633;
    border: 1px solid #ffa633 !important;
    color: #fff
}

.review-rating i {
    color: #ff9307;
    font-size: 14px
}

.review-rating span {
    margin-left: 5px
}

.review-wrap {
    border-radius: 10px;
    border: 1px solid #f4f4f4;
    background: #fff;
    margin: 0 0 20px;
    padding: 15px 15px 0
}

.review-wrap .booking-img-wrap {
    margin-bottom: 15px
}

.review-wrap .booking-img-wrap .book-info h6 {
    font-size: 14px;
    margin-bottom: 0
}

.review-wrap .booking-img-wrap .book-img {
    margin-right: 10px
}

.review-wrap .booking-img-wrap .book-img img {
    width: 45px;
    height: 45px
}

.review-wrap .booking-view h6 {
    font-weight: 400;
    margin-bottom: 0
}

.rating-wrap {
    border-radius: 10px;
    border: 1px solid #f4f4f4;
    background: #fff;
    margin: 0 0 20px;
    padding: 15px 15px 0
}

.rating-wrap .rating a {
    color: #737373;
    font-size: 14px
}

.rating-wrap .rating a.selected {
    color: #ffa633
}

.new-modal .rating-wrap .modal-form-group textarea.form-control {
    height: auto
}

.wishlist-wrap .listview-car .card .card-body .listing-details-group ul {
    justify-content: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start
}

.wishlist-wrap .listview-car .card .card-body .listing-details-group ul li {
    margin-right: 26px
}

.loc-wrap {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center
}

.loc-wrap .loc-item {
    margin-right: 24px;
    width: 100%
}

@media(max-width:575.98px) {
    .loc-wrap .loc-item {
        margin-right: 0
    }
}

.loc-wrap .btn {
    width: 147px;
    font-size: 14px;
    padding: 11px 10px;
    border-radius: 5px
}

.loc-wrap .btn:hover {
    background-color: #201f1d;
    color: #fff
}

@media(max-width:575.98px) {
    .loc-wrap {
        flex-direction: column
    }
}

.rent-radio {
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(225, 225, 225, .25);
    padding: 15px
}

.rent-radio .custom_radio {
    margin: 0;
    color: #111;
    text-align: center;
    padding: 0;
    display: block
}

.rent-radio .custom_radio .checkmark {
    position: relative;
    display: block;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 8px;
    background-color: #dbdbdb;
    border: 1px solid #f5f5f5
}

.rent-radio .custom_radio input:checked~.checkmark {
    background-color: #127384
}

.rent-radio .custom_radio input:checked~.checkmark::after {
    background-color: #fff;
    width: 6px;
    height: 6px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.rent-radio.active {
    background: rgba(18, 115, 132, .08);
    border-color: #127384
}

.rent-radio .rent-option {
    color: #111 !important
}

.badge-item .badge {
    border-radius: 5px;
    padding: 7px 5px;
    font-size: 14px;
    font-weight: 500;
    min-width: 80px;
    text-align: center
}

.avatar {
    position: relative;
    display: inline-block;
    width: 3rem;
    height: 3rem
}

.avatar>img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar .avatar-title {
    width: 100%;
    height: 100%;
    color: #fff
}

.avatar.avatar-online::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #1fbc2f
}

.avatar.avatar-offline::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: red
}

.avatar.avatar-away::before {
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #ff9307
}

.avatar .border {
    border-width: 3px !important
}

.avatar .rounded {
    border-radius: 6px !important
}

.avatar .avatar-title {
    font-size: 18px
}

.avatar .avatar-xs {
    width: 1.65rem;
    height: 1.65rem
}

.avatar .avatar-xs .border {
    border-width: 2px !important
}

.avatar .avatar-xs .rounded {
    border-radius: 4px !important
}

.avatar .avatar-xs .avatar-title {
    font-size: 12px
}

.avatar .avatar-xs.avatar-away::before,
.avatar .avatar-xs.avatar-offline::before,
.avatar .avatar-xs.avatar-online::before {
    border-width: 1px
}

.chat-page .footer {
    display: none
}

.chat-window {
    position: relative
}

.chat-window .chat-cont-left {
    border: 1px solid #dbdbdb;
    border-right: 0;
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
    z-index: 4;
    position: relative;
    left: 0
}

@media(max-width:1199.98px) {
    .chat-window .chat-cont-left {
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%
    }
}

@media(max-width:991.98px) {
    .chat-window .chat-cont-left {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
        width: 100%;
        border-right: 1px solid #dbdbdb
    }
}

@media(max-width:479px) {

    .chat-window .chat-cont-left a.notify-block .media-body>div:first-child .user-name,
    .chat-window .chat-cont-left a.notify-block .media-body>div:first-child .user-last-chat {
        max-width: 160px
    }
}

.chat-window .chat-cont-left .chat-header {
    background-color: #fff;
    border-bottom: 1px solid #f5f5f5;
    color: #111;
    height: 72px;
    padding: 0 15px
}

.chat-window .chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize
}

.chat-window .chat-cont-left .chat-header .chat-compose {
    color: #737373
}

.chat-window .chat-cont-left .chat-search {
    background-color: #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding: 11px 15px;
    width: 100%
}

.chat-window .chat-cont-left .chat-search .input-group {
    width: 100%
}

.chat-window .chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    padding-left: 36px;
    border-radius: 50px
}

.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #201f1d;
    box-shadow: none
}

.chat-window .chat-cont-left .chat-search .input-group .form-control::placeholder {
    font-size: 14px
}

.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
    color: #737373;
    pointer-events: none;
    z-index: 4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px
}

.chat-window .chat-cont-left .chat-users-list {
    background-color: #fff
}

.chat-window .chat-cont-left .chat-users-list a.notify-block {
    padding: 10px 15px;
    -webkit-transition: all .2s ease 0s;
    -ms-transition: all .2s ease 0s;
    transition: all .2s ease 0s
}

.chat-window .chat-cont-left .chat-users-list a.notify-block:last-child {
    border-bottom: 0
}

.chat-window .chat-cont-left .chat-users-list a.notify-block .media-img-wrap {
    margin-right: 15px;
    position: relative
}

.chat-window .chat-cont-left .chat-users-list a.notify-block .media-img-wrap .avatar {
    height: 45px;
    width: 45px
}

.chat-window .chat-cont-left .chat-users-list a.notify-block .media-img-wrap .avatar img {
    width: 100%;
    height: 100%
}

.chat-window .chat-cont-left .chat-users-list a.notify-block .media-img-wrap .status {
    height: 10px;
    width: 10px;
    border: 2px solid #fff;
    position: absolute;
    right: 4px;
    bottom: 7px
}

.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-name {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #111;
    text-transform: capitalize
}

@media(max-width:1399.98px) {
    .chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-name {
        max-width: 180px
    }
}

@media(max-width:1199.98px) {
    .chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-name {
        max-width: 150px
    }
}

@media(max-width:767.98px) {
    .chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-name {
        max-width: 250px
    }
}

@media(max-width:479px) {
    .chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-name {
        max-width: 160px
    }
}

.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #737373;
    font-size: 14px;
    line-height: 24px
}

@media(max-width:1399.98px) {
    .chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-last-chat {
        max-width: 180px
    }
}

@media(max-width:1199.98px) {
    .chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-last-chat {
        max-width: 150px
    }
}

@media(max-width:767.98px) {
    .chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-last-chat {
        max-width: 250px
    }
}

@media(max-width:479px) {
    .chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:first-child .user-last-chat {
        max-width: 160px
    }
}

.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:last-child {
    text-align: right
}

.chat-window .chat-cont-left .chat-users-list a.notify-block .media-body>div:last-child .last-chat-time {
    color: #737373;
    font-size: 13px
}

.chat-window .chat-cont-left .chat-users-list a.notify-block:hover {
    background-color: #f5f5f5
}

.chat-window .chat-cont-left .chat-users-list a.notify-block.active {
    background-color: #f5f5f5
}

.chat-window .chat-scroll {
    min-height: 300px;
    max-height: calc(100vh - 224px);
    overflow-y: auto;
    overflow-x: hidden
}

.chat-window .chat-scroll::-webkit-scrollbar {
    width: 6px
}

.chat-window .chat-scroll::-webkit-scrollbar-track {
    background-color: #ddd;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

.chat-window .chat-scroll::-webkit-scrollbar-thumb {
    background-color: #ffa633;
    border-radius: 2px;
    -webkit-background-color: #ffa633;
    -moz-background-color: #ffa633;
    -ms-background-color: #ffa633;
    -o-background-color: #ffa633
}

.chat-window .chat-cont-right {
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
    border: 1px solid #dbdbdb
}

@media(max-width:1199.98px) {
    .chat-window .chat-cont-right {
        -ms-flex: 0 0 60%;
        flex: 0 0 60%;
        max-width: 60%
    }
}

@media(max-width:991.98px) {
    .chat-window .chat-cont-right {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 0;
        right: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
        width: 100%
    }
}

.chat-window .chat-cont-right .chat-header {
    background-color: #fff;
    border-bottom: 1px solid #dbdbdb;
    height: 72px;
    padding: 0 15px
}

@media(max-width:991.98px) {
    .chat-window .chat-cont-right .chat-header {
        justify-content: start;
        -webkit-justify-content: start;
        -ms-flex-pack: start
    }
}

.chat-window .chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px
}

@media(max-width:991.98px) {
    .chat-window .chat-cont-right .chat-header .back-user-list {
        display: block
    }
}

.chat-window .chat-cont-right .chat-header .notify-block .media-img-wrap {
    position: relative;
    margin-right: 15px
}

.chat-window .chat-cont-right .chat-header .notify-block .media-img-wrap .avatar {
    height: 50px;
    width: 50px
}

.chat-window .chat-cont-right .chat-header .notify-block .media-img-wrap .status {
    border: 2px solid #fff;
    height: 10px;
    width: 10px;
    position: absolute;
    right: 3px;
    bottom: 0
}

@media(max-width:767.98px) {
    .chat-window .chat-cont-right .chat-header .notify-block .media-body {
        display: none
    }
}

.chat-window .chat-cont-right .chat-header .notify-block .media-body .user-name {
    color: #111;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize
}

.chat-window .chat-cont-right .chat-header .notify-block .media-body .user-status {
    color: #737373;
    font-size: 14px
}

@media(max-width:991.98px) {
    .chat-window .chat-cont-right .chat-header .chat-options {
        margin-left: auto
    }
}

.chat-window .chat-cont-right .chat-header .chat-options>a {
    border-radius: 50%;
    height: 30px;
    color: #737373;
    margin-left: 10px;
    width: 30px
}

.chat-window .chat-cont-right .chat-body {
    background-color: #fcfcfc
}

.chat-window .chat-cont-right .chat-body ul.list-unstyled {
    width: 100%;
    margin: 0 auto;
    padding: 15px
}

.chat-window .chat-cont-right .chat-body .notify-block .avatar {
    height: 30px;
    width: 30px
}

.chat-window .chat-cont-right .chat-body .notify-block .media-body {
    margin-left: 20px
}

.chat-window .chat-cont-right .chat-body .notify-block .media-body .msg-box>div {
    display: inline-block;
    position: relative;
    border-radius: .25rem;
    padding: 10px 15px
}

.chat-window .chat-cont-right .chat-body .notify-block .media-body .msg-box>div p {
    color: #737373;
    margin-bottom: 0
}

.chat-window .chat-cont-right .chat-body .notify-block .media-body .msg-box+.msg-box {
    margin-top: 5px
}

.chat-window .chat-cont-right .chat-body .notify-block.received {
    margin-bottom: 20px
}

.chat-window .chat-cont-right .chat-body .notify-block.received .media-body {
    position: relative
}

.chat-window .chat-cont-right .chat-body .notify-block.received .media-body .msg-box>div {
    background-color: #f2f7f6
}

.chat-window .chat-cont-right .chat-body .notify-block.received .media-body .msg-box>div .chat-time {
    color: rgba(50, 65, 72, .4)
}

.chat-window .chat-cont-right .chat-body .notify-block.received .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-right: 6px solid #f2f7f6;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 8px;
    right: auto;
    left: -6px
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box:first-child:before {
    border-bottom: 6px solid transparent;
    border-left: 6px solid #f1f1f1;
    border-top: 6px solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    top: 8px;
    right: -6px;
    left: auto
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box>div .chat-time {
    color: rgba(50, 65, 72, .4)
}

.chat-window .chat-cont-right .chat-body .notify-block:last-child {
    margin-bottom: 0
}

.chat-window .chat-cont-right .chat-body .notify-block.sent {
    margin-bottom: 20px
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body {
    margin-left: 0
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box>div {
    background-color: #f1f1f1
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box>div p {
    color: #737373
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box>div .chat-msg-info {
    flex-direction: row-reverse
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box>div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box>div .chat-msg-attachments {
    flex-direction: row-reverse
}

.chat-window .chat-cont-right .chat-body .notify-block.sent .media-body .msg-box>div:hover .chat-msg-actions {
    opacity: 1
}

.chat-window .chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
    color: #111
}

.chat-window .chat-cont-right .chat-body .chat-date:before {
    background-color: rgba(145, 158, 171, .3);
    content: "";
    height: 1px;
    margin-right: 28px;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 50%
}

.chat-window .chat-cont-right .chat-body .chat-date:after {
    background-color: rgba(145, 158, 171, .3);
    content: "";
    height: 1px;
    margin-left: 28px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%
}

.chat-window .chat-cont-right .chat-footer {
    background-color: #fff;
    position: relative;
    padding: 10px 15px
}

.chat-window .chat-cont-right .chat-footer .input-group {
    width: 100%
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 8px !important
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: none
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control::placeholder {
    font-size: 14px
}

.chat-window .chat-cont-right .chat-footer .input-group .btn {
    background-color: transparent;
    border: none;
    color: #737373
}

.chat-window .chat-cont-right .chat-footer .input-group .btn.msg-send-btn {
    background-color: #ff9f43;
    border-color: #ffa633;
    color: #fff;
    margin-left: 10px;
    width: 40px;
    height: 40px;
    border-radius: 8px !important
}

.chat-window .chat-cont-right .msg-typing {
    width: auto;
    height: 24px;
    padding-top: 8px
}

.chat-window .chat-cont-right .msg-typing span {
    height: 8px;
    width: 8px;
    float: left;
    margin: 0 1px;
    background-color: #737373;
    display: block;
    opacity: .4;
    border-radius: 50%
}

.chat-window .chat-cont-right .msg-typing span:nth-of-type(1) {
    animation: 1s blink infinite .33333s
}

.chat-window .chat-cont-right .msg-typing span:nth-of-type(2) {
    animation: 1s blink infinite .66666s
}

.chat-window .chat-cont-right .msg-typing span:nth-of-type(3) {
    animation: 1s blink infinite .99999s
}

.chat-window .chat-msg-info {
    clear: both;
    list-style: none;
    margin: 5px 0 0;
    padding: 0
}

.chat-window .chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative
}

.chat-window .chat-msg-info li:not(:last-child):after {
    content: "";
    height: 4px;
    width: 4px;
    background: #dbdbdb;
    border-radius: 50%;
    transform: translate(50%, -50%);
    -webkit-transform: translate(50%, -50%);
    -ms-transform: translate(50%, -50%);
    position: absolute;
    top: 50%;
    right: 8px
}

.chat-window .chat-msg-info li a {
    color: rgba(50, 65, 72, .4)
}

.chat-window .chat-msg-info li a:hover {
    color: #737373
}

@media(max-width:991.98px) {
    .chat-window.chat-slide .chat-cont-right {
        right: 0;
        opacity: 1;
        visibility: visible;
        z-index: 999
    }
}

.chat-seen i {
    color: #1fbc2f;
    font-size: 16px
}

.chat-msg-attachments {
    width: 100%;
    margin: 0 -1px;
    padding: 4px 0
}

.chat-msg-attachments>div {
    margin: 0 1px
}

.chat-attachment {
    position: relative;
    max-width: 200px;
    overflow: hidden;
    border-radius: .25rem
}

.chat-attachment img {
    max-width: 100%
}

.chat-attachment:before {
    background: #000;
    content: "";
    opacity: .4;
    -webkit-transition: all .4s;
    -ms-transition: all .4s;
    transition: all .4s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.chat-attachment:hover:before {
    opacity: .6
}

.chat-attachment:hover .chat-attach-caption {
    opacity: 0
}

.chat-attachment:hover .chat-attach-download {
    opacity: 1
}

.chat-attach-caption {
    color: #fff;
    padding: 7px 15px;
    font-size: 13px;
    opacity: 1;
    -webkit-transition: all .4s;
    -ms-transition: all .4s;
    transition: all .4s;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0
}

.chat-attach-download {
    opacity: 0;
    color: #fff;
    width: 32px;
    line-height: 32px;
    background: rgba(255, 255, 255, .2);
    text-align: center;
    transform: translate(-50%, -50%);
    -webkit-transition: all .4s;
    -ms-transition: all .4s;
    transition: all .4s;
    position: absolute;
    top: 50%;
    left: 50%
}

.chat-attach-download:hover {
    color: #737373;
    background: #fff
}

.chat-attachment-list {
    margin: -5px
}

.chat-attachment-list li {
    width: 33.33%;
    padding: 5px
}

.chat-attachment-item {
    border: 5px solid rgba(230, 239, 251, .5);
    height: 100%;
    min-height: 60px;
    text-align: center
}

.chat-msg-actions {
    opacity: 0;
    z-index: 2;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -webkit-transition: all .4s;
    -ms-transition: all .4s;
    transition: all .4s;
    position: absolute;
    top: 50%;
    left: -30px
}

.chat-msg-actions>a {
    font-size: 24px;
    padding: 0 10px
}

.chat-msg-actions>a:hover {
    color: #737373
}

@keyframes blink {
    50% {
        opacity: 1
    }
}

.btn-file {
    font-size: 20px;
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    padding: 0 .75rem
}

.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    height: 100%;
    margin: 0;
    opacity: 0;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0
}

.incoming-btns {
    margin-top: 20px
}

.call-wrapper {
    position: relative;
    height: calc(100vh - 145px)
}

@media(max-width:991.98px) {
    .call-wrapper {
        height: calc(100vh - 140px)
    }
}

@media(max-width:767.98px) {
    .call-wrapper {
        height: calc(100vh - 115px)
    }
}

.call-page .footer {
    display: none
}

.dropdown-action .dropdown-toggle::after {
    display: none
}

.call-modal .modal-body {
    padding: 40px
}

.call-modal .modal-content {
    border: 0;
    border-radius: 10px
}

input.form-control.rounded-pill:focus {
    z-index: 3
}

@media(max-width:575.98px) {
    .chat-custom {
        flex-wrap: wrap
    }
}

.call-box .call-wrapper {
    height: auto;
    text-align: center
}

.call-box .call-wrapper .call-user {
    margin-bottom: 30px
}

.call-box .call-wrapper .call-user h4 {
    font-size: 24px
}

.call-box .call-wrapper .call-user span {
    display: block;
    font-weight: 500;
    text-align: center
}

.call-box .call-wrapper .call-avatar {
    margin-bottom: 30px;
    cursor: pointer;
    animation: ripple 2s infinite
}

.call-box .call-wrapper .call-items .call-item {
    background-color: rgba(255, 255, 255, .2);
    border: 1px solid transparent;
    border-radius: 100%;
    color: #fff;
    line-height: 0;
    margin: 0 5px;
    padding: 15px
}

.call-box .call-wrapper .call-items .call-item:hover {
    opacity: .9
}

.call-box .call-wrapper .call-items .call-item:first-child,
.call-box .call-wrapper .call-items .call-item:last-child {
    margin-top: -30px
}

.call-box .call-wrapper .call-items .call-item.call-end {
    background: #f06060;
    border: 1px solid #f06060;
    color: #fff;
    line-height: 0;
    border-radius: 100%;
    margin: 30px 20px 0;
    padding: 20px
}

.call-box .call-wrapper .call-items .call-item.call-start {
    background: #1fbc2f;
    border: 1px solid #1fbc2f;
    color: #fff;
    line-height: 0;
    border-radius: 100%;
    margin: 30px 20px 0;
    padding: 20px
}

.call-box.incoming-box .call-wrapper .call-items .call-item.call-start,
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
    margin: 0 10px
}

.call-box .call-avatar {
    height: 140px;
    max-width: 140px;
    min-width: 140px;
    position: relative;
    width: 100%;
    border-radius: 100%
}

.call-box .btn {
    background: transparent;
    -webkit-transition: all .3s ease 0s;
    -ms-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.back-user-list {
    color: #000
}

@-webkit-keyframes ripple {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, .1)
    }

    100% {
        -webkit-box-shadow: 0 0 0 30px transparent
    }
}

@keyframes ripple {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, .1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, .1)
    }

    100% {
        -moz-box-shadow: 0 0 0 30px transparent;
        box-shadow: 0 0 0 30px transparent
    }
}

.chat-cont-right .chat-body .notify-block.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%)
}

.dashboard-section {
    background: #fff;
    overflow: hidden;
    box-shadow: 0 4px 24px 0 rgba(225, 225, 225, .25);
    padding: 20px 0 0
}

@media(max-width:767.98px) {
    .dashboard-section {
        padding: 20px 0 10px
    }
}

@media(max-width:991.98px) {
    .dashboard-section .dashboard-menu {
        text-align: center
    }
}

@media(max-width:991.98px) {
    .dashboard-section .dashboard-menu ul {
        margin-right: -20px
    }
}

@media(max-width:767.98px) {
    .dashboard-section .dashboard-menu ul {
        margin-right: -10px
    }
}

.dashboard-section .dashboard-menu ul li {
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 20px
}

@media(max-width:1440px) {
    .dashboard-section .dashboard-menu ul li {
        margin-right: 15px
    }
}

@media(max-width:991.98px) {
    .dashboard-section .dashboard-menu ul li {
        margin-right: 20px
    }
}

@media(max-width:767.98px) {
    .dashboard-section .dashboard-menu ul li {
        margin-right: 10px;
        margin-bottom: 10px
    }
}

.dashboard-section .dashboard-menu ul li:last-child {
    margin-right: 0
}

@media(max-width:991.98px) {
    .dashboard-section .dashboard-menu ul li:last-child {
        margin-right: 20px
    }
}

@media(max-width:767.98px) {
    .dashboard-section .dashboard-menu ul li:last-child {
        margin-right: 10px
    }
}

.dashboard-section .dashboard-menu ul li a {
    font-size: 14px;
    color: #737373;
    background: #f2f7f6;
    border: 1px solid #f2f7f6;
    border-radius: 5px;
    padding: 22px;
    display: inline-block;
    text-align: center;
    min-width: 135px;
    line-height: normal
}

@media(max-width:767.98px) {
    .dashboard-section .dashboard-menu ul li a {
        padding: 20px
    }
}

.dashboard-section .dashboard-menu ul li a img {
    margin-bottom: 10px
}

.dashboard-section .dashboard-menu ul li a span {
    display: block
}

.dashboard-section .dashboard-menu ul li a.active {
    color: #fff;
    background: #ffa633;
    border-color: #ffa633
}

.dashboard-section .dashboard-menu ul li a.active img {
    filter: invert(1) brightness(100)
}

.dashboard-section .dashboard-menu ul li a.active:hover {
    color: #fff;
    background: #ffa633;
    border-color: #ffa633
}

.dashboard-section .dashboard-menu ul li a:hover {
    color: #ffa633;
    background: #fff;
    border-color: #ffa633
}

@media(max-width:767.98px) {
    .wallet-card {
        margin-bottom: 20px
    }
}

.wallet-card .card-body {
    padding: 24px
}

@media(max-width:767.98px) {
    .wallet-card .card-body {
        padding: 15px
    }
}

.balance-info {
    margin-bottom: 24px;
    padding-bottom: 14px;
    border-bottom: 1px solid #f4f4f4
}

@media(max-width:767.98px) {
    .balance-info {
        margin-bottom: 20px;
        padding-bottom: 10px
    }
}

.balance-grid {
    background: #111;
    padding: 15px;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 24px
}

@media(max-width:767.98px) {
    .balance-grid {
        margin-bottom: 10px
    }
}

.balance-grid .balance-content h6 {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 0
}

.balance-grid .balance-content h4 {
    font-size: 28px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0
}

.balance-grid .refersh-icon a {
    font-size: 16px;
    color: #fff
}

.balance-grid .refersh-icon a:hover {
    color: #ffa633
}

.balance-inner {
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%
}

.balance-inner h6 {
    font-size: 18px;
    font-weight: 500;
    color: #111;
    margin-bottom: 5px
}

.balance-inner p {
    font-size: 14px;
    color: #127384;
    margin-bottom: 0
}

.credit-info {
    background: #ebf5f1
}

.debit-info {
    background: #fdf0e6
}

.transaction-info {
    background: #f6f4fb
}

.wallet-form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 15px
}

.wallet-form-group label {
    margin-right: 15px;
    margin-bottom: 0;
    color: #111;
    font-size: 14px
}

.wallet-form-group .form-control {
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background: #f5f5f5;
    font-size: 14px;
    height: 45px;
    padding: 15px;
    max-width: 300px
}

@media(max-width:767.98px) {
    .wallet-form-group .form-control {
        height: 40px
    }
}

.wallet-form-group .form-control::placeholder {
    color: #737373;
    font-size: 14px
}

.wallet-btn .btn {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background: #201f1d;
    border: 1px solid #201f1d;
    border-radius: 5px;
    padding: 8px 15px;
    min-width: 136px
}

@media(max-width:767.98px) {
    .wallet-btn .btn {
        font-size: 14px;
        min-width: 120px
    }
}

.wallet-btn .btn:hover {
    color: #201f1d;
    background: #fff;
    border: 1px solid #201f1d
}

@media(max-width:767.98px) {
    .your-card {
        margin-bottom: 20px
    }
}

.your-card .card-body {
    padding: 24px
}

@media(max-width:767.98px) {
    .your-card .card-body {
        padding: 15px
    }
}

.your-card .card-body .wallet-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin: 0 0 5px;
    padding: 0 0 15px;
    border-bottom: 1px solid #f4f4f4
}

.your-card .card-body .wallet-header h4 {
    font-size: 20px;
    font-weight: 500;
    color: #111;
    margin-bottom: 0
}

@media(max-width:767.98px) {
    .your-card .card-body .wallet-header h4 {
        font-size: 18px
    }
}

.your-card .card-body .wallet-header a {
    font-size: 16px;
    color: #737373;
    background: #f1f1f1;
    border-radius: 50px;
    width: 28px;
    height: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.your-card .card-body .wallet-header a:hover {
    background: #ffa633;
    color: #fff
}

.wallet-table .table-responsive .table {
    margin: 0
}

.wallet-table .table-responsive .table tbody tr {
    border-bottom: 0
}

.wallet-table .table-responsive .table tbody tr td {
    font-size: 14px;
    color: #737373;
    padding: 15px;
    line-height: normal;
    white-space: nowrap;
    vertical-align: middle;
    border-color: #f4f4f4;
    border-bottom: 1px solid #f4f4f4
}

.wallet-table .table-responsive .table tbody tr td:last-child {
    padding-right: 0
}

@media(max-width:767.98px) {
    .wallet-table .table-responsive .table tbody tr td:last-child {
        padding-right: 10px
    }
}

.wallet-table .table-responsive .table tbody tr td .custom_radio {
    margin-bottom: 0
}

.wallet-table .table-responsive .table tbody tr td .custom_radio .checkmark {
    width: 12px;
    height: 12px;
    border: 0;
    background-color: #dbdbdb
}

.wallet-table .table-responsive .table tbody tr td .custom_radio .checkmark::after {
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    background-color: #1fbc2f
}

.wallet-table .table-responsive .table tbody tr td h6 {
    font-size: 18px;
    font-weight: 500;
    color: #111;
    margin-bottom: 5px
}

@media(max-width:767.98px) {
    .wallet-table .table-responsive .table tbody tr td h6 {
        font-size: 16px
    }
}

.wallet-table .table-responsive .table tbody tr td p {
    margin-bottom: 0
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-toggle {
    width: 30px;
    height: 30px;
    font-size: 16px;
    color: #737373;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    background: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-toggle i {
    position: relative;
    left: 0
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-toggle:hover {
    color: #737373;
    border: 1px solid #dbdbdb;
    background: #f1f1f1
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-menu {
    width: 160px;
    background: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: none;
    border-radius: 5px;
    padding: 0;
    margin-top: 5px !important
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #f4f4f4;
    border-radius: 0;
    padding: 10px 15px;
    font-size: 14px;
    color: #737373;
    font-weight: 400;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-menu .dropdown-item:hover {
    color: #ffa633;
    padding-left: 25px;
    -webkit-transition: all .4s ease;
    -ms-transition: all .4s ease;
    transition: all .4s ease
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-menu .dropdown-item:first-child {
    border-radius: 5px 5px 0 0
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
    border-radius: 0 0 5px 5px
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-menu .dropdown-item i {
    font-size: 15px;
    margin-right: 6px
}

.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-menu .dropdown-item.active,
.wallet-table .table-responsive .table tbody tr td .dropdown-action .dropdown-menu .dropdown-item:active {
    color: #ffa633;
    background-color: #fff
}

.new-modal .modal-header .invoice-btns {
    position: absolute;
    top: -50px;
    right: 0
}

.new-modal .modal-header .invoice-btns .btn {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: inset 0 0 0 #201f1d
}

.new-modal .modal-header .invoice-btns .btn:hover {
    color: #fff;
    background: #201f1d;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #201f1d
}

@media(max-width:767.98px) {
    .new-modal .modal-header .invoice-btns .btn {
        padding: 8px 15px
    }
}

.new-modal .modal-header .invoice-btns .btn i {
    margin-right: 4px
}

.new-modal .total-payment {
    background: #f5f5f5;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 40px;
    text-align: center
}

@media(max-width:767.98px) {
    .new-modal .total-payment {
        margin-bottom: 15px
    }
}

.new-modal .total-payment p {
    font-size: 16px;
    font-weight: 500;
    color: #737373;
    margin-bottom: 0
}

.new-modal .total-payment h6 {
    font-size: 18px;
    font-weight: 500;
    color: #201f1d;
    margin-bottom: 0
}

.new-modal .choose-payment-info h5 {
    font-size: 16px;
    font-weight: 400;
    color: #111;
    margin-bottom: 20px
}

.new-modal .choose-payment-info .choose-payment {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    padding-bottom: 10px
}

@media(max-width:767.98px) {
    .new-modal .choose-payment-info .choose-payment {
        display: block
    }
}

.new-modal .choose-payment-info .choose-payment .custom_radio {
    padding: 10px 15px 0;
    margin-right: 10px;
    margin-bottom: 20px
}

.new-modal .choose-payment-info .choose-payment .custom_radio:last-child {
    margin-right: 0
}

@media(max-width:767.98px) {
    .new-modal .choose-payment-info .choose-payment .custom_radio {
        margin-right: 20px
    }
}

.new-modal .choose-payment-info .choose-payment .custom_radio .checkmark {
    width: 110px;
    height: 70px;
    border-radius: 5px;
    background: #f2f7f6;
    border: 3px solid #f2f7f6;
    top: 58%;
    left: 0;
    transform: translateY(-50%)
}

.new-modal .choose-payment-info .choose-payment .custom_radio .checkmark::after {
    width: 110px;
    height: 70px;
    border-radius: 5px;
    top: 0;
    left: 0;
    background: #fff;
    border: 3px solid #ffa633
}

.new-modal .choose-payment-info .choose-payment .custom_radio img {
    position: relative;
    z-index: 1;
    width: 80px
}

.new-modal .choose-payment-info .add-payment-table-info {
    margin-bottom: 30px
}

@media(max-width:767.98px) {
    .new-modal .choose-payment-info .add-payment-table-info {
        margin-bottom: 10px
    }
}

.new-modal .choose-payment-info .add-payment-table {
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    margin-bottom: 10px
}

.new-modal .choose-payment-info .add-payment-table .table-responsive .table tbody tr td {
    background: 0 0;
    border-color: transparent;
    border-bottom: 0
}

.new-modal .choose-payment-info .add-payment-table .table-responsive .table tbody tr td .custom_radio {
    margin-bottom: 0
}

.new-modal .choose-payment-info .add-payment-table .table-responsive .table tbody tr td .custom_radio .checkmark {
    width: 16px;
    height: 16px;
    border: 2px solid #dbdbdb;
    background-color: #fff
}

.new-modal .choose-payment-info .add-payment-table .table-responsive .table tbody tr td .custom_radio .checkmark::after {
    top: 3px;
    left: 3px;
    width: 7px;
    height: 7px;
    background-color: #ffa633
}

.new-modal .choose-payment-info .add-payment-table .table-responsive .table tbody tr td .custom_radio input:checked~.checkmark {
    border: 2px solid #ffa633
}

.new-modal .modal-btn .btn {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px
}

@media(max-width:767.98px) {
    .new-modal .modal-btn .btn {
        font-size: 14px;
        padding: 8px 15px
    }
}

.new-modal .modal-btn .btn-secondary {
    border-radius: 5px;
    border: 1px solid #201f1d;
    background: #201f1d;
    box-shadow: inset 0 0 0 #fff
}

.new-modal .modal-btn .btn-secondary:hover {
    color: #201f1d;
    background: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff
}

.new-modal .modal-btn .btn-outline-cancel {
    padding: 0;
    border: 0;
    color: red;
    width: 100%;
    margin-top: 10px
}

.new-modal .modal-btn .btn-outline-cancel:hover {
    color: #201f1d
}

.new-modal .modal-btn-group {
    margin-bottom: 20px
}

@media(max-width:767.98px) {
    .new-modal .modal-btn-group {
        margin-bottom: 15px
    }
}

.new-modal .modal-btn-sm {
    text-align: right
}

.new-modal .modal-btn-sm .btn {
    padding: 7px 20px
}

.new-modal .modal-btn-sm .btn-secondary {
    margin-right: 10px
}

.new-modal .order-success-info {
    text-align: center
}

.new-modal .order-success-info .order-success-icon {
    width: 56px;
    height: 56px;
    background: #1fbc2f;
    border-radius: 50%;
    margin: 0 auto 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center
}

.new-modal .order-success-info h4 {
    font-size: 24px;
    font-weight: 600;
    color: #111;
    margin-bottom: 5px
}

@media(max-width:767.98px) {
    .new-modal .order-success-info h4 {
        font-size: 20px
    }
}

.new-modal .order-success-info p {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    max-width: 310px;
    margin: 0 auto 24px
}

.new-modal .order-success-info p span {
    font-weight: 500;
    color: #111
}

.new-modal .modal-form-group {
    margin-bottom: 15px
}

.new-modal .modal-form-group label {
    font-size: 14px;
    font-weight: 500;
    color: #111;
    margin-bottom: 5px
}

.new-modal .modal-form-group .form-control {
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background: #f5f5f5;
    font-size: 14px;
    height: 45px;
    padding: 15px
}

@media(max-width:767.98px) {
    .new-modal .modal-form-group .form-control {
        height: 40px
    }
}

.new-modal .modal-form-group .form-control::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #737373
}

.new-modal .modal-form-group .form-icon {
    position: relative
}

.new-modal .modal-form-group .form-icon .cus-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px
}

.new-modal .modal-form-group .custom_check {
    font-size: 14px;
    font-weight: 400;
    color: #828282;
    margin-bottom: 0
}

.new-modal .modal-form-group p {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    margin-bottom: 0
}

.new-modal .pass-group {
    position: relative
}

.new-modal .pass-group .toggle-password,
.new-modal .pass-group .toggle-password-two,
.new-modal .pass-group .toggle-password-three,
.new-modal .pass-group .toggle-password-four,
.new-modal .pass-group .toggle-password-five,
.new-modal .pass-group .toggle-password-six {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #828282;
    cursor: pointer
}

.new-modal .otp-number {
    text-align: center;
    padding: 0 0 20px
}

@media(max-width:767.98px) {
    .new-modal .otp-number {
        padding: 0 0 15px
    }
}

.new-modal .otp-number p {
    font-size: 14px;
    color: #737373;
    margin-bottom: 0
}

.new-modal .otp-number p span {
    font-weight: 500
}

.new-modal .otp-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 20px
}

@media(max-width:767.98px) {
    .new-modal .otp-box {
        margin-bottom: 15px
    }
}

.new-modal .otp-box input {
    width: 98px;
    height: 74px;
    background: #f5f5f5;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    text-align: center;
    margin-right: 15px;
    font-weight: 500;
    font-size: 20px;
    color: #111;
    outline: none
}

.new-modal .otp-box input:last-child {
    margin-right: 0
}

@media(max-width:575.98px) {
    .new-modal .otp-box input {
        width: 60px;
        height: 60px;
        margin-right: 10px
    }
}

.new-modal .otp-resend {
    text-align: center;
    margin-bottom: 20px
}

@media(max-width:767.98px) {
    .new-modal .otp-resend {
        margin-bottom: 15px
    }
}

.new-modal .otp-resend a {
    color: red
}

.new-modal .otp-resend a:hover {
    color: #201f1d
}

.new-modal .otp-resend p {
    font-size: 14px;
    color: #737373;
    margin-bottom: 0
}

.new-modal .verified-box {
    background: rgba(31, 188, 47, .15);
    padding: 10px
}

.new-modal .verified-box p {
    font-size: 14px;
    color: #1fbc2f;
    margin-bottom: 0
}

.new-modal .deactive-content {
    margin-bottom: 15px
}

.new-modal .deactive-content p {
    font-size: 14px;
    color: #737373;
    margin-bottom: 0
}

.new-modal .invoice-details .invoice-items {
    margin-bottom: 30px
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .invoice-items {
        margin-bottom: 20px
    }
}

.new-modal .invoice-details .invoice-items .invoice-info-text {
    text-align: right
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .invoice-items .invoice-info-text {
        padding-top: 10px
    }
}

.new-modal .invoice-details .invoice-items .invoice-info-text h4 {
    font-size: 24px;
    font-weight: 600;
    color: #201f1d;
    text-transform: uppercase;
    margin-bottom: 10px
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .invoice-items .invoice-info-text h4 {
        font-size: 20px;
        margin-bottom: 0
    }
}

.new-modal .invoice-details .invoice-items .invoice-info-text p {
    font-size: 14px;
    color: #737373;
    margin-bottom: 0
}

.new-modal .invoice-details .invoice-item-bills {
    position: relative;
    background: #201f1d;
    border-radius: 10px;
    margin: 0 0 30px;
    padding: 30px
}

@media(max-width:991.98px) {
    .new-modal .invoice-details .invoice-item-bills {
        margin: 0 0 20px;
        padding: 20px 20px 0
    }
}

.new-modal .invoice-details .invoice-item-bills:before {
    content: "";
    background: url(../img/bg/invoice.png) no-repeat;
    position: absolute;
    top: -45px;
    left: -45px;
    width: 179px;
    height: 180px;
    background-size: cover
}

.new-modal .invoice-details .invoice-item-bills .invoice-bill-info {
    border-right: 1px solid #f5f5f5
}

@media(max-width:991.98px) {
    .new-modal .invoice-details .invoice-item-bills .invoice-bill-info {
        border-right: 0;
        margin-bottom: 20px
    }
}

.new-modal .invoice-details .invoice-item-bills .invoice-bill-info h6 {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin: 0 0 15px
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .invoice-item-bills .invoice-bill-info h6 {
        margin: 0 0 10px
    }
}

.new-modal .invoice-details .invoice-item-bills .invoice-bill-info p {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    margin: 0 0 10px
}

.new-modal .invoice-details .invoice-item-bills .invoice-bill-info p:last-child {
    margin: 0
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .invoice-item-bills .invoice-bill-info p {
        margin: 0 0 5px
    }
}

.new-modal .invoice-details .invoice-table-wrap {
    margin: 0 0 30px
}

@media(max-width:991.98px) {
    .new-modal .invoice-details .invoice-table-wrap {
        margin: 0 0 20px
    }
}

.new-modal .invoice-details .invoice-table-wrap .invoice-table thead th {
    font-size: 15px;
    font-weight: 600;
    color: #201f1d;
    background: #f4f4f4;
    padding: 15px
}

.new-modal .invoice-details .invoice-table-wrap .invoice-table tbody td {
    font-size: 14px;
    font-weight: 400;
    color: #2f2f2f;
    padding: 15px
}

.new-modal .invoice-details .invoice-table-wrap .invoice-table tbody td h6 {
    font-size: 14px;
    font-weight: 500;
    color: #111;
    margin-bottom: 0
}

.new-modal .invoice-details .payment-details-info {
    margin: 0 0 30px
}

@media(max-width:991.98px) {
    .new-modal .invoice-details .payment-details-info {
        margin: 0 0 20px
    }
}

@media(max-width:991.98px) {
    .new-modal .invoice-details .payment-details-info .invoice-terms {
        margin: 0 0 20px
    }
}

.new-modal .invoice-details .payment-details-info .invoice-terms h6 {
    font-weight: 500;
    font-size: 18px;
    color: #2f2f2f;
    margin-bottom: 18px
}

.new-modal .invoice-details .payment-details-info .invoice-terms .invocie-note {
    border: 2px dashed #787878;
    border-radius: 4px;
    max-width: 266px;
    padding: 20px
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .payment-details-info .invoice-terms .invocie-note {
        padding: 15px
    }
}

.new-modal .invoice-details .payment-details-info .invoice-terms .invocie-note h6 {
    font-weight: 500;
    font-size: 18px;
    color: #201f1d;
    margin: 0 0 20px
}

.new-modal .invoice-details .payment-details-info .invoice-terms .invocie-note p {
    font-size: 14px;
    color: #2f2f2f;
    margin-bottom: 0
}

.new-modal .invoice-details .payment-details-info .invoice-total-box .invoice-total-inner p {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    margin-bottom: 15px
}

.new-modal .invoice-details .payment-details-info .invoice-total-box .invoice-total-inner p:last-child {
    margin-bottom: 0
}

.new-modal .invoice-details .payment-details-info .invoice-total-box .invoice-total-inner p b {
    font-weight: 400;
    max-width: 196px;
    display: inline-block
}

.new-modal .invoice-details .payment-details-info .invoice-total-box .invoice-total-inner p span {
    float: right;
    font-weight: 500;
    font-size: 15px
}

.new-modal .invoice-details .invoice-total {
    background: #f1f1f1;
    border: 2px dashed #fcfcfc;
    margin: 0 0 30px;
    padding: 10px;
    text-align: right
}

@media(max-width:991.98px) {
    .new-modal .invoice-details .invoice-total {
        margin: 0 0 20px;
        padding: 10px
    }
}

.new-modal .invoice-details .invoice-total h4 {
    font-weight: 500;
    font-size: 20px;
    color: #201f1d
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .invoice-total h4 {
        font-size: 18px
    }
}

.new-modal .invoice-details .invoice-total h4 span {
    padding-left: 88px
}

@media(max-width:575.98px) {
    .new-modal .invoice-details .invoice-total h4 span {
        padding: 0 0 0 10px
    }
}

.new-modal .invoice-details .invoice-note-footer .invocie-note {
    margin: 0 0 30px
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .invoice-note-footer .invocie-note {
        margin: 0 0 20px
    }
}

.new-modal .invoice-details .invoice-note-footer .invocie-note h6 {
    font-weight: 500;
    font-size: 18px;
    color: #201f1d;
    margin: 0 0 12px
}

@media(max-width:767.98px) {
    .new-modal .invoice-details .invoice-note-footer .invocie-note h6 {
        margin: 0 0 8px
    }
}

.new-modal .invoice-details .invoice-note-footer .invocie-note p {
    font-size: 14px;
    color: #2f2f2f;
    margin-bottom: 0
}

.new-modal .invoice-details .invoice-note-footer .invoice-sign {
    float: right
}

@media(max-width:575.98px) {
    .new-modal .invoice-details .invoice-note-footer .invoice-sign {
        float: left
    }
}

.new-modal .invoice-details .invoice-note-footer .invoice-sign img {
    margin: 0 0 19px
}

@media(max-width:991.98px) {
    .new-modal .invoice-details .invoice-note-footer .invoice-sign img {
        margin: 0
    }
}

.new-modal .invoice-details .invoice-note-footer .invoice-sign span {
    font-size: 18px;
    font-weight: 500;
    color: #201f1d;
    text-align: center
}

.order-success-modal .modal-content {
    max-width: 400px;
    margin: auto
}

.verification-success-modal .modal-content {
    max-width: 400px;
    margin: auto
}

.verification-success-modal .order-success-info p {
    max-width: inherit
}

.delete-action {
    text-align: center
}

.delete-action .delete-header h4 {
    font-size: 24px;
    font-weight: 600;
    color: #111;
    margin-bottom: 5px
}

@media(max-width:767.98px) {
    .delete-action .delete-header h4 {
        font-size: 20px
    }
}

.delete-action .delete-header p {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    max-width: 310px;
    margin: 0 auto 24px
}

.delete-action .delete-header p span {
    font-weight: 500;
    color: #111
}

.settings-widget {
    padding: 0 0 24px
}

@media(max-width:991.98px) {
    .settings-widget {
        padding: 0 0 20px
    }
}

.settings-widget .settings-menu ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.settings-widget .settings-menu ul li {
    margin: 0 0 15px
}

.settings-widget .settings-menu ul li:last-child {
    margin-bottom: 0
}

.settings-widget .settings-menu ul li a {
    font-size: 16px;
    font-weight: 400;
    color: #737373;
    border-radius: 5px;
    background: #f2f7f6;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.settings-widget .settings-menu ul li a:hover {
    color: #fff;
    background: #ffa633
}

.settings-widget .settings-menu ul li a.active {
    color: #fff;
    background: #ffa633
}

.settings-widget .settings-menu ul li a i {
    margin-right: 8px
}

.settings-info {
    position: relative;
    padding-left: 20px
}

@media(max-width:991.98px) {
    .settings-info {
        padding-left: 0
    }
}

.settings-info:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    border-radius: 5px;
    background: #dbdbdb;
    z-index: 1
}

@media(max-width:991.98px) {
    .settings-info:before {
        display: none
    }
}

.settings-info .settings-sub-heading {
    margin: 0 0 20px;
    padding: 0 0 20px;
    border-bottom: 1px solid #dbdbdb
}

@media(max-width:767.98px) {
    .settings-info .settings-sub-heading {
        margin: 0 0 15px;
        padding: 0 0 15px
    }
}

.settings-info .settings-sub-heading h4 {
    font-size: 24px;
    font-weight: 600;
    color: #111;
    margin-bottom: 0
}

@media(max-width:767.98px) {
    .settings-info .settings-sub-heading h4 {
        font-size: 22px
    }
}

.settings-info .profile-info-grid {
    margin: 0 0 24px;
    border: 1px solid #dbdbdb;
    border-radius: 5px
}

@media(max-width:767.98px) {
    .settings-info .profile-info-grid {
        margin: 0 0 20px
    }
}

.settings-info .profile-info-grid .profile-info-header {
    margin: 0 0 20px;
    padding: 15px;
    background: #f5f5f5;
    border-radius: 5px 5px 0 0
}

.settings-info .profile-info-grid .profile-info-header h5 {
    font-size: 20px;
    font-weight: 500;
    color: #111;
    margin-bottom: 0
}

.settings-info .profile-info-grid .profile-info-header p {
    font-size: 16px;
    color: #737373;
    margin-bottom: 0
}

.settings-info .profile-info-grid .profile-inner {
    padding: 0 15px
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 0 15px
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-img {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
    margin-right: 15px;
    position: relative;
    overflow: hidden;
    z-index: 1
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-img:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 1
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-img img {
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 5px
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-img .profile-edit-info {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    z-index: 1
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-img .profile-edit-info a {
    font-size: 16px;
    color: #dbdbdb
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-img .profile-edit-info a:hover {
    color: #ffa633
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-content h6 {
    font-size: 18px;
    font-weight: 500;
    color: #111;
    margin-bottom: 5px
}

.settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-content p {
    font-size: 15px;
    color: #737373;
    margin-bottom: 0
}

@media(max-width:767.98px) {
    .settings-info .profile-info-grid .profile-inner .profile-info-pic .profile-info-content p {
        font-size: 14px
    }
}

.settings-info .profile-info-grid .profile-inner .profile-form-group {
    margin-bottom: 15px
}

.settings-info .profile-info-grid .profile-inner .profile-form-group label {
    font-size: 14px;
    font-weight: 500;
    color: #111;
    margin-bottom: 5px
}

.settings-info .profile-info-grid .profile-inner .profile-form-group .form-control {
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background: #f5f5f5;
    font-size: 14px;
    height: 45px;
    padding: 15px
}

@media(max-width:767.98px) {
    .settings-info .profile-info-grid .profile-inner .profile-form-group .form-control {
        height: 40px
    }
}

.settings-info .profile-info-grid .profile-inner .profile-form-group .form-control::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #737373
}

.settings-info .profile-info-grid .profile-inner .profile-form-group textarea.form-control {
    border-radius: 5px;
    border: 1px solid #f4f4f4;
    background: #f5f5f5;
    font-size: 14px;
    height: 130px;
    padding: 15px;
    resize: none
}

@media(max-width:767.98px) {
    .settings-info .profile-info-grid .profile-inner .profile-form-group textarea.form-control {
        height: 100px
    }
}

.settings-info .profile-info-grid .profile-inner .profile-form-group textarea.form-control::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #737373
}

.settings-info .profile-submit-btn {
    text-align: right
}

.settings-info .profile-submit-btn .btn {
    font-size: 16px;
    font-weight: 500;
    padding: 8px 20px
}

@media(max-width:767.98px) {
    .settings-info .profile-submit-btn .btn {
        font-size: 14px;
        padding: 8px 15px
    }
}

.settings-info .profile-submit-btn .btn-secondary {
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #201f1d;
    background: #201f1d;
    box-shadow: inset 0 0 0 #fff
}

.settings-info .profile-submit-btn .btn-secondary:hover {
    color: #201f1d;
    background: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff
}

@media(max-width:767.98px) {
    .settings-profile-content {
        padding: 40px 0 20px
    }
}

.security-grid {
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    margin: 0 0 24px;
    padding: 15px;
    position: relative
}

@media(max-width:767.98px) {
    .security-grid {
        margin: 0 0 15px;
        padding: 15px
    }
}

.security-grid .security-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 15px
}

.security-grid .security-heading h5 {
    font-size: 15px;
    font-weight: 500;
    color: #201f1d;
    margin-bottom: 0
}

.security-grid .security-heading .status-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.security-grid .security-heading .status-toggle .checktoggle {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    display: block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
    width: 30px;
    height: 15px;
    top: 0;
    border-radius: 12px;
    transform: translate(calc(10% - 5px), -10%)
}

.security-grid .security-heading .status-toggle .checktoggle:after {
    height: 15px;
    width: 15px
}

.security-grid .security-heading .status-toggle .check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute
}

.security-grid .security-heading .status-toggle .check:checked+.checktoggle {
    background-color: #1fbc2f;
    border: 1px solid #1fbc2f
}

.security-grid .security-heading .status-toggle .check:checked+.checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 3px), -50%);
    height: 10px;
    width: 10px
}

.security-grid .security-heading .status-toggle .checktoggle:after {
    content: " ";
    display: block;
    background-color: rgba(0, 0, 0, .25);
    height: 10px;
    width: 10px;
    transform: translate(3px, -50%);
    -webkit-transform: translate(3px, -50%);
    -ms-transform: translate(3px, -50%);
    position: absolute;
    left: 0;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

.security-grid .security-content p {
    font-size: 14px;
    color: #737373;
    margin-bottom: 15px
}

.security-grid .security-content .badge-light-success {
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    border-radius: 5px
}

.security-grid .security-btn-info {
    position: absolute;
    bottom: 15px
}

@media(max-width:991.98px) {
    .security-grid .security-btn-info {
        position: relative;
        bottom: 0
    }
}

.security-grid .security-btn .btn {
    font-size: 16px;
    font-weight: 500;
    padding: 3px 20px;
    border: 0
}

@media(max-width:767.98px) {
    .security-grid .security-btn .btn {
        font-size: 14px;
        padding: 3px 15px
    }
}

.security-grid .security-btn .btn-secondary {
    margin-right: 15px;
    border-radius: 5px;
    border: 1px solid #201f1d;
    background: #201f1d;
    box-shadow: inset 0 0 0 #fff
}

.security-grid .security-btn .btn-secondary:hover {
    color: #201f1d;
    background: #fff;
    border: 1px solid #201f1d;
    box-shadow: inset 0 70px 0 0 #fff
}

.security-grid .security-btn .btn-danger {
    border-radius: 5px;
    color: #fff;
    border: 1px solid red;
    background: red;
    box-shadow: inset 0 0 0 #fff
}

.security-grid .security-btn .btn-danger:hover {
    color: red;
    background: #fff;
    border: 1px solid red;
    box-shadow: inset 0 70px 0 0 #fff
}

.security-grid .security-btn .btn-remove {
    color: red;
    padding: 0
}

.security-grid .security-btn .btn-remove:hover {
    color: #201f1d
}

.preferences-grid {
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(225, 225, 225, .25);
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    margin: 0 0 24px;
    padding: 24px
}

@media(max-width:767.98px) {
    .preferences-grid {
        margin: 0 0 15px;
        padding: 15px
    }
}

.preferences-grid .preferences-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between
}

.preferences-grid .preferences-heading h5 {
    font-size: 18px;
    font-weight: 500;
    color: #201f1d;
    margin-bottom: 0
}

@media(max-width:767.98px) {
    .preferences-grid .preferences-heading h5 {
        font-size: 16px
    }
}

.preferences-grid .preferences-heading .status-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.preferences-grid .preferences-heading .status-toggle .checktoggle {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    display: block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
    width: 30px;
    height: 15px;
    top: 0;
    border-radius: 12px;
    transform: translate(calc(10% - 5px), -10%)
}

.preferences-grid .preferences-heading .status-toggle .checktoggle:after {
    height: 15px;
    width: 15px
}

.preferences-grid .preferences-heading .status-toggle .check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute
}

.preferences-grid .preferences-heading .status-toggle .check:checked+.checktoggle {
    background-color: #1fbc2f;
    border: 1px solid #1fbc2f
}

.preferences-grid .preferences-heading .status-toggle .check:checked+.checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 3px), -50%);
    height: 10px;
    width: 10px
}

.preferences-grid .preferences-heading .status-toggle .checktoggle:after {
    content: " ";
    display: block;
    background-color: rgba(0, 0, 0, .25);
    height: 10px;
    width: 10px;
    transform: translate(3px, -50%);
    -webkit-transform: translate(3px, -50%);
    -ms-transform: translate(3px, -50%);
    position: absolute;
    left: 0;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

.notification-grid {
    background: #fff;
    box-shadow: 0 4px 24px 0 rgba(225, 225, 225, .25);
    border-radius: 5px;
    margin: 0 0 24px;
    padding: 24px
}

@media(max-width:767.98px) {
    .notification-grid {
        margin: 0 0 15px;
        padding: 15px
    }
}

.notification-grid .notification-checkbox {
    padding-bottom: 25px
}

@media(max-width:767.98px) {
    .notification-grid .notification-checkbox {
        padding-bottom: 5px
    }
}

.notification-grid .notification-checkbox h5 {
    font-size: 18px;
    font-weight: 500;
    color: #111;
    margin-bottom: 15px
}

.notification-grid .notification-checkbox .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.notification-grid .notification-checkbox .nav li {
    margin-right: 20px;
    margin-bottom: 15px
}

.notification-grid .notification-checkbox .nav li:last-child {
    margin-right: 0
}

.notification-grid .notification-checkbox .nav li .custom_check {
    margin-bottom: 0
}

.notification-grid .notification-status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 40px
}

@media(max-width:767.98px) {
    .notification-grid .notification-status {
        margin-bottom: 15px
    }
}

.notification-grid .notification-status:last-child {
    margin-bottom: 0
}

.notification-grid .notification-status .notification-status-content {
    margin-right: 10px
}

.notification-grid .notification-status h5 {
    font-size: 18px;
    font-weight: 500;
    color: #201f1d;
    margin-bottom: 4px
}

@media(max-width:767.98px) {
    .notification-grid .notification-status h5 {
        font-size: 16px
    }
}

.notification-grid .notification-status p {
    font-size: 16px;
    color: #737373;
    margin-bottom: 0
}

@media(max-width:767.98px) {
    .notification-grid .notification-status p {
        font-size: 14px
    }
}

.notification-grid .notification-status .status-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.notification-grid .notification-status .status-toggle .checktoggle {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    display: block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
    width: 30px;
    height: 15px;
    top: 0;
    border-radius: 12px;
    transform: translate(calc(10% - 5px), -10%)
}

.notification-grid .notification-status .status-toggle .checktoggle:after {
    height: 15px;
    width: 15px
}

.notification-grid .notification-status .status-toggle .check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute
}

.notification-grid .notification-status .status-toggle .check:checked+.checktoggle {
    background-color: #1fbc2f;
    border: 1px solid #1fbc2f
}

.notification-grid .notification-status .status-toggle .check:checked+.checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 3px), -50%);
    height: 10px;
    width: 10px
}

.notification-grid .notification-status .status-toggle .checktoggle:after {
    content: " ";
    display: block;
    background-color: rgba(0, 0, 0, .25);
    height: 10px;
    width: 10px;
    transform: translate(3px, -50%);
    -webkit-transform: translate(3px, -50%);
    -ms-transform: translate(3px, -50%);
    position: absolute;
    left: 0;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

.integration-grid {
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    margin: 0 0 24px;
    padding: 15px;
    position: relative
}

@media(max-width:767.98px) {
    .integration-grid {
        margin: 0 0 15px;
        padding: 15px
    }
}

.integration-grid .integration-calendar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 15px
}

.integration-grid .integration-calendar .status-toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.integration-grid .integration-calendar .status-toggle .checktoggle {
    background-color: #dbdbdb;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    display: block;
    font-size: 0;
    margin-bottom: 0;
    position: relative;
    width: 30px;
    height: 15px;
    top: 0;
    border-radius: 12px;
    transform: translate(calc(10% - 5px), -10%)
}

.integration-grid .integration-calendar .status-toggle .checktoggle:after {
    height: 15px;
    width: 15px
}

.integration-grid .integration-calendar .status-toggle .check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute
}

.integration-grid .integration-calendar .status-toggle .check:checked+.checktoggle {
    background-color: #1fbc2f;
    border: 1px solid #1fbc2f
}

.integration-grid .integration-calendar .status-toggle .check:checked+.checktoggle:after {
    background-color: #fff;
    left: 100%;
    transform: translate(calc(-100% - 3px), -50%);
    height: 10px;
    width: 10px
}

.integration-grid .integration-calendar .status-toggle .checktoggle:after {
    content: " ";
    display: block;
    background-color: rgba(0, 0, 0, .25);
    height: 10px;
    width: 10px;
    transform: translate(3px, -50%);
    -webkit-transform: translate(3px, -50%);
    -ms-transform: translate(3px, -50%);
    position: absolute;
    left: 0;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease
}

.integration-grid .integration-content h5 {
    font-size: 16px;
    font-weight: 500;
    color: #111;
    margin-bottom: 10px
}

.integration-grid .integration-content p {
    font-size: 14px;
    color: #737373;
    margin-bottom: 0
}

.car-selection-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }

  
  .popup-content {
    background: white;
    padding: 20px;
    z-index: 10;
    border-radius: 8px;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    width: 700px;
  }
  
  .popup-content-3 {
    background: white;
    padding: 20px;
    z-index: 10;
    border-radius: 8px;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    width: 700px;
  }

  .car-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .toggle-btn {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 10px;
    padding: 0;
    outline: none;
  }
  
  .toggle-btn.active {
    border-bottom: 2px solid #333;
  }
  
  .car-options-select {
    height: auto;
    width: auto;
    border: none; max-width: 100%;
  }
  .car-options {
    margin-top: 2%;
  }
  .input-block-1 {
    display: flex;
    height: 100px;
    flex-direction: column;
    align-items: right;
  }
  
  .input-block-1 img {
    height: 100px;
  }
  .selected {
    background-color: #00ed27;
  }
  .car-option-1 {
    margin-top: 1%;
    display: flex;
    height: auto;
    flex-direction: row;
    border-radius: 2px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .popup-content button {
    margin-top: 1%;
    color: white;
    background-color: #00ed27;
    display: flex;
    height: auto;
    flex-direction: row;
  }


  .suggestion-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
  }
  
  .suggestion-item {
    cursor: pointer;
    margin-top: 2px;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  

  .popup-content-2 {
    background: white;
    padding: 20px;
    z-index: 11;
    border-radius: 8px;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    width: auto;
  }

  .car-selection-popup-2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
  }

  .car-selection-popup-3 {
    /* position: fixed; */
    top: 0;
    left: 0;
    right: 0;
    /* z-index: 11; */
    bottom: 0;
    /* background: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow-y: auto; */
  }


  .fixed-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff; /* Adjust as needed */
    z-index: 999; /* Adjust as needed */
}