.footer {
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5% 15% 1% 15%;
}

.whatsapp-icon {
  position: fixed;
  background-color: green;
  font-size: 40px;
  border-radius: 50%;
  color: white;
  top: 20%; /* Adjust the top position as needed */
  right: 20px; /* Adjust the right position as needed */
  z-index: 1000000; /* Ensure it's above other content */
  cursor: pointer; /* Add cursor pointer for better UX */
}

.carpooloption :hover {
  background-color: #b6f3b0;
  transform: scale(1.02);
}
.navbaar222 {
  background-color: #36454F;
}
@media(min-width:985.98px) {
  .navbaar222 {
    height: 70px;
  }
}

.main-tariff {
  padding: 3rem 4%;
}

.inner-tariff {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.service-slider {
  margin: 2%;
  text-align: center;
  flex: 1 1 250px;
  border-radius: 1%;
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.6);
}

.inner-box2{
  padding: 3rem 0;
}

.service-slider :hover{
  background-color: var(--secondarycolor);
}

.services-text {
  padding-bottom: 5%;
  font-size: 40px;
  font-weight: bolder;
  text-align: center;
}


.H2-text {
  text-align: center;
  font-size: 30px;
  color: var(--primarycolor);
}



.service-img-div{
  border-radius: 0%;
  padding: 1%;
}




.service-img{
  width: 100%;
  border-radius: 0%;
}




.Para-Text {
  text-transform: none;
  font-size: 1.5rem;
  padding: 2rem;
}




.slide-btn {
  font-size: 1.5rem;
  padding: 1rem 2rem;
  font-weight: bolder;
  color: var(--darkcolor);
  background-color: var(--primarycolor); 
  display: inline-block;
  border: none;
  border-radius: 17px;
  margin-top: 1.5rem;
}

.slide-btn :hover{
  font-size: 1.5rem;
  padding: 1rem 2rem;
  font-weight: bolder;
  color: var(--darkcolor);
  display: inline-block;
  border: none;
  border-radius: 17px;
  margin-top: -1.5rem;
  background-color: var(--primarycolor) !important;
  box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.6);
}


